<div class="sign-up-completion">
  <div class="sign-up-completion__card">
    <img class="sign-up-completion__img" src="assets/images/auth/rocket.svg" alt="rocket" />
    <h2 class="sign-up-completion__heading">Wahoo!</h2>
    <h3 class="sign-up-completion__sub-heading">You've partnered with pargo</h3>
    <p class="sign-up-completion__description">
      Explore myPargo and purchase a credit bundle to get your parcels on the go!
    </p>
    <div class="sign-up-completion__button-container">
      <pargo-button
        label="Let's get shipping"
        [loading]="loading"
        (click)="redirectToDashboard()"
      ></pargo-button>
    </div>
  </div>
</div>

import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

export const createDialogConfig = (
  primaryBtnLabel: string,
  heading: string,
  text: string,
  imgSrc: string,
  secondaryBtnLabel?: string,
) => {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    primaryBtnLabel,
    heading,
    text,
    imgSrc,
    secondaryBtnLabel,
  };
  return dialogConfig;
};

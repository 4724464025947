import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CustomIcon } from '../../models/icon';
import { CREDITS_ICONS } from '../../constants/icons';

@Component({
  selector: 'pargo-credits-amounts',
  templateUrl: './credits-amount.html',
  styleUrls: ['./credits-amount.scss'],
})
export class CreditsAmount implements OnChanges {
  @Input() credits: number;
  @Input() transition?: number = 5000;

  changedCredits: 'positive' | 'negative';
  changeTimeout;

  creditsAmountClass = 'credits-amount';

  customIcons: CustomIcon[] = [...CREDITS_ICONS];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.customIcons.forEach(({ path, name }: CustomIcon): void => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { credits }: any = changes;
    const { previousValue: oldCredits, currentValue: newCredits } = credits;

    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }

    if (newCredits > oldCredits) {
      this.changedCredits = 'positive';
      this.handleChangeTimeout();
      this.changeCreditsAmountClass();
    }

    if (newCredits < oldCredits) {
      this.changedCredits = 'negative';
      this.handleChangeTimeout();
      this.changeCreditsAmountClass();
    }
  }

  changeCreditsAmountClass() {
    if (this.changedCredits) {
      this.creditsAmountClass = `credits-amount credits-amount--${this.changedCredits}`;
    } else {
      this.creditsAmountClass = 'credits-amount';
    }
  }

  handleChangeTimeout() {
    this.changeTimeout = setTimeout(() => {
      this.changedCredits = undefined;
      this.changeCreditsAmountClass();
    }, this.transition);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DashboardBoxComponent } from './dashboard-box';

@NgModule({
    declarations: [DashboardBoxComponent],
    imports: [CommonModule],
    providers: [],
    exports: [DashboardBoxComponent]
})
export class DashboardBoxModule {}

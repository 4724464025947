import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, switchMap, concatMap, tap, finalize, delay } from 'rxjs/operators';
import { from } from 'rxjs';
import { CreateService } from '../services/create.service';

import {
  SAVE_ORDER,
  EDIT_ORDER,
  EDIT_PRINT_ORDER,
  SAVE_RETURN_ORDER,
  SAVE_PRINT_ORDER,
  CONFIRM_PRINT_LABEL,
  PRINT_LABEL,
  SUBMITTED_FORM,
  REFRESH_PROFILE,
  CREATE_EFFECT_NAVIGATION,
  UPLOAD_ORDERS,
  UPLOAD_ORDER_SUCCESS,
  UPLOAD_ORDER_ERROR,
  UPLOAD_ORDERS_DONE,
  GET_REGION,
  UPDATE_REGION,
  SAVE_PRINT_RETURN_ORDER,
} from '../actions/create';

import {
  ADD_NOTIFICATION as GLOBAL_ADD_NOTIFICATION,
  GET_ORDERS as GLOBAL_GET_ORDERS,
  SET_CURRENT_ORDER as GLOBAL_SET_CURRENT_ORDER,
  REFRESH_PROFILE as GLOBAL_REFRESH_PROFILE,
  HIGHLIGHT_ORDER as GLOBAL_HIGHLIGHT_ORDER,
  RESET_FILTERS as GLOBAL_RESET_FILTERS,
  ADD_CREDITS as GLOBAL_ADD_CREDITS,
} from '../actions/globals';
import { MAX_API_RETRIES } from '../../shared/constants/shared-constants';
import { downloadWaybill } from '../helpers/functions';
import { THROW_RESPONSE_ERR } from 'src/app/core/actions/globals';

@Injectable()
export class CreateEffects {
  constructor(
    private actions: Actions<any>,
    private createService: CreateService,
    private router: Router,
    private store: Store<any>,
  ) {}

  public validateRegion$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(GET_REGION),
      concatMap(({ from: fromAddress, to: toAddress, processType }) =>
        from(this.createService.getRegion({ from: fromAddress, to: toAddress, processType })),
      ),
      concatMap((result: { success: boolean; data: any }) => {
        if (result && result.success) {
          const { data } = result;
          return [{ type: UPDATE_REGION, region: data.region }];
        }
      }),
    ),
  );

  public uploadOrders$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(UPLOAD_ORDERS),
      map((action) => ({
        orders: action.orders,
        processType: action.processType,
      })),
      concatMap(({ orders, processType }) => {
        return from(orders).pipe(
          map((order, index) => ({ order, index })),
          concatMap(({ order, index }) => {
            return this.createService.create(order, processType).pipe(
              switchMap((result) => {
                const { success }: any = result;

                if (success) {
                  return [
                    {
                      type: UPLOAD_ORDER_SUCCESS,
                      row: index,
                    },
                  ];
                }
              }),
              catchError((error: any) => {
                return [
                  {
                    type: UPLOAD_ORDER_ERROR,
                    row: index,
                  },
                  { type: THROW_RESPONSE_ERR, error },
                ];
              }),
            );
          }),
          finalize(() => {
            this.store.dispatch({
              type: UPLOAD_ORDERS_DONE,
            });
          }),
        );
      }),
    ),
  );

  public saveOrder$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(SAVE_ORDER, SAVE_RETURN_ORDER, SAVE_PRINT_ORDER, SAVE_PRINT_RETURN_ORDER),
      map((action) => {
        return {
          order: action.order,
          redirect: action.redirect,
          deliveryMethod:
            action.type === SAVE_ORDER || action.type === SAVE_PRINT_ORDER
              ? action.deliveryMethod
              : action.returnMethod,
          saveType:
            action.type === SAVE_ORDER || action.type === SAVE_RETURN_ORDER ? 'save' : 'print',
        };
      }),
      concatMap(({ order, redirect, saveType, deliveryMethod }) => {
        return this.createService.create(order, deliveryMethod).pipe(
          switchMap((result) => {
            const { data }: any = result;

            const notification = {
              message: `Order ${data[0].data.trackingCode ?? ''} has successfully been saved`,
              type: 'fade',
              class: 'success',
            };

            const id = data[0].data.id;

            return [
              { type: GLOBAL_ADD_NOTIFICATION, notification },
              saveType === 'save' && { type: GLOBAL_GET_ORDERS, select: true },
              { type: GLOBAL_SET_CURRENT_ORDER, id: undefined },
              { type: REFRESH_PROFILE },
              saveType === 'print' && { type: CONFIRM_PRINT_LABEL, id },
              redirect && { type: CREATE_EFFECT_NAVIGATION, url: '/orders' },
              redirect && { type: GLOBAL_HIGHLIGHT_ORDER, id },
              { type: GLOBAL_RESET_FILTERS },
              { type: SUBMITTED_FORM },
            ].filter((x) => x);
          }),
        );
      }),
    ),
  );

  public editOrder$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(EDIT_ORDER, EDIT_PRINT_ORDER),
      map((action) => ({
        order: action.order,
        redirect: action.redirect,
        deliveryMethod: action.deliveryMethod,
        type: action.type === EDIT_ORDER ? 'edit' : 'print',
      })),
      concatMap(({ order, redirect, type, deliveryMethod }) => {
        return this.createService.edit(order, deliveryMethod).pipe(
          switchMap((result) => {
            const { data }: any = result;

            const notification = {
              message: `Order ${data[0].data.trackingCode} has successfully been updated`,
              type: 'fade',
              class: 'success',
            };
            const id = data[0].data.id;
            return [
              { type: GLOBAL_ADD_NOTIFICATION, notification },
              { type: GLOBAL_GET_ORDERS, select: false },
              { type: GLOBAL_SET_CURRENT_ORDER, id: undefined },
              { type: REFRESH_PROFILE },
              type === 'print' && { type: CONFIRM_PRINT_LABEL, id },
              redirect && { type: CREATE_EFFECT_NAVIGATION, url: '/orders' },
              redirect && { type: GLOBAL_HIGHLIGHT_ORDER, id },
              { type: GLOBAL_RESET_FILTERS },
              { type: SUBMITTED_FORM },
            ].filter((x) => x);
          }),
        );
      }),
    ),
  );

  public confirmPrintLabel$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(CONFIRM_PRINT_LABEL),
      map((action) => action.id),
      concatMap((id) => {
        return this.createService.confirmOrder(id).pipe(
          switchMap((result) => {
            const { meta }: any = result;

            const notification = {
              message: 'Your PDF waybill is being created. This could take some time',
              type: 'fade',
              class: 'success',
            };

            return [
              { type: PRINT_LABEL, id, counter: 0 },
              { type: GLOBAL_ADD_CREDITS, credits: meta.creditAmount },
              {
                type: GLOBAL_ADD_NOTIFICATION,
                notification,
              },
            ];
          }),
        );
      }),
    ),
  );

  public printLabel$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(PRINT_LABEL),
      map((action) => ({
        id: action.id,
        counter: action.counter,
      })),
      delay(5000), // Todo: 5s delay between each API call, update to solution that does delay starting with 2nd time action is triggered
      concatMap(({ id, counter }) => {
        return this.createService.printLabel(id).pipe(
          switchMap((result: any) => {
            if (result.pdfReady) {
              downloadWaybill(result.fileUrl);
              return [{ type: GLOBAL_REFRESH_PROFILE }, { type: GLOBAL_GET_ORDERS, select: false }];
            } else if (counter < MAX_API_RETRIES) {
              return [{ type: PRINT_LABEL, id, counter: ++counter }];
            } else {
              const notification = {
                message:
                  "Please give us a minute whilst Pargo validates the parcel's route. Once the route is validated, you will be able to print the label.",
                type: 'fade',
                class: 'info',
              };

              return [
                { type: GLOBAL_REFRESH_PROFILE },
                { type: GLOBAL_GET_ORDERS, select: false },
                {
                  type: GLOBAL_ADD_NOTIFICATION,
                  notification,
                },
              ];
            }
          }),
        );
      }),
    ),
  );

  public navigate$: any = createEffect(
    () =>
      this.actions.pipe(
        ofType<any>(CREATE_EFFECT_NAVIGATION),
        tap((action) => this.router.navigate([action.url])),
      ),
    { dispatch: false },
  );
}

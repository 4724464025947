import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class FormValidationService {
  directPasswordMatch(...controls: AbstractControl[]): { matchPassword: boolean } | null {
    const formGroup = controls[0].parent;

    if (formGroup) {
      const password = formGroup.controls['password'].value;
      const repeatPassword = formGroup.controls['repeatPassword'].value;

      if (
        password !== null &&
        repeatPassword !== null &&
        String(password) == String(repeatPassword)
      ) {
        return null;
      }
      return { matchPassword: true };
    } else {
      return null;
    }
  }

  alphabeticCharacters(
    control: AbstractControl,
  ): RegExp | { nonAlphabeticCharacters: boolean } | null {
    if (control.value) {
      const alphabeticCharsRegex = /^[a-zA-Z ]+$/gm; //space in regex is intentional as user is allowed to have spaces between alphabetic inputs
      return alphabeticCharsRegex.test(control.value) ? null : { nonAlphabeticCharacters: true };
    }
    return null;
  }

  phoneNumber(control: AbstractControl): { invalidPhoneNumber: boolean } | null {
    if (control.value) {
      let phoneNumber = control.value.replace(/ /g, '');
      if (phoneNumber[0] === '+') {
        phoneNumber = phoneNumber.substring(1);
      }
      const numbersRegex = /^\d{9,12}$/;
      return numbersRegex.test(phoneNumber) ? null : { invalidPhoneNumber: true };
    }
    return null;
  }

  url(control: AbstractControl): { invalidURL: boolean } | null {
    if (control.value) {
      const urlRegex =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      return urlRegex.test(control.value) ? null : { invalidURL: true };
    }
    return null;
  }

  vatNumber(control: AbstractControl): { invalidVatNumber: boolean } | null {
    if (control.value) {
      const vatRegex = /^[4]\d{9}$/;
      return vatRegex.test(control.value) ? null : { invalidVatNumber: true };
    }
    return null;
  }

  postalCode(control: AbstractControl): { invalidPostalCode: boolean } | null {
    if (control.value) {
      const postalCodeRegex = /^\d{4}$/;
      return postalCodeRegex.test(control.value) ? null : { invalidPostalCode: true };
    }
    return null;
  }

  isWhiteSpace(control: AbstractControl): { isWhiteSpace: boolean } | null {
    if (control.value) {
      return control.value.trim().length !== 0 ? null : { isWhiteSpace: true };
    }
    return null;
  }

  email(control: AbstractControl): { invalidEmail: boolean } | null {
    if (control.value) {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(control.value) ? null : { invalidEmail: true };
    }
    return null;
  }

  userNameCharacters(control: AbstractControl): { invalidUserNameCharacters: boolean } | null {
    if (control.value) {
      const nameRegex = /^[\p{L}\-'’ ]+$/u;
      return nameRegex.test(control.value) ? null : { invalidUserNameCharacters: true };
    }
    return null;
  }
}

import { CustomIcon } from '../models/icon';

export const ParcelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Parcel.svg',
  name: 'parcel-pargo',
};

export const LargeParcelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/LargeParcel.svg',
  name: 'large-parcel-pargo',
};

export const MediumParcelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/MediumParcel.svg',
  name: 'medium-parcel-pargo',
};

export const SmallParcelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/SmallParcel.svg',
  name: 'small-parcel-pargo',
};

export const INFORMATION_PILL: CustomIcon[] = [
  ParcelIcon,
  SmallParcelIcon,
  MediumParcelIcon,
  LargeParcelIcon,
];

import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ORDER_ICONS } from '../../../../../../icons';
import { CustomIcon } from '../../../../../../core/models/icons';

@Component({
  selector: 'app-delivery-form-summary',
  templateUrl: './delivery-summary.html',
  styleUrls: ['./delivery-summary.scss'],
})
export class DeliverySummaryComponent {
  @Input() formValues: any;

  @Input() amount: object;

  open: boolean = true;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    ORDER_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  toggle() {
    this.open = !this.open;
  }

  get to() {
    if (
      this.formValues.deliveryMethod &&
      this.formValues.deliveryMethod.deliveryMethod &&
      this.formValues.deliveryMethod[this.formValues.deliveryMethod.deliveryMethod]
    ) {
      const deliveryMethod = this.formValues.deliveryMethod.deliveryMethod;
      const values = this.formValues.deliveryMethod[this.formValues.deliveryMethod.deliveryMethod];
      switch (deliveryMethod) {
        case 'w2p':
          return values.pickupPointName;
        case 'w2d':
          return `${values.addressOne ? values.addressOne : ''} ${
            values.addressTwo ? values.addressTwo : ''
          } ${values.postalCode ? values.postalCode : ''} ${values.suburb ? values.suburb : ''} ${
            values.city ? values.city : ''
          }`;
      }
    }
  }
}

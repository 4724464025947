import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { AmountSummary } from './components/amount-summary/amount-summary';

@NgModule({
    declarations: [AmountSummary],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [AmountSummary]
})
export class AmountSummaryModule {}

import {
  Component,
  OnInit,
  forwardRef,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormBuilder,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'app-order-warehouse',
  templateUrl: './order-warehouse.html',
  styleUrls: ['./order-warehouse.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderWarehouseComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrderWarehouseComponent),
      multi: true,
    },
  ],
})
export class OrderWarehouseComponent implements OnChanges, OnInit, ControlValueAccessor, Validator {
  @Input() warehouses: any;

  @Input() heading?: string;

  @Input() selectedWarehouseReference?: string;

  @Output() updateRegion = new EventEmitter();

  warehouseForm = this.formBuilder.group({
    warehouse: ['', [Validators.required]],
    warehouseName: [''],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.setPrimaryWarehouse();
  }

  ngOnChanges() {
    if (this.warehouses && this.warehouses.length) {
      this.setPrimaryWarehouse();
      this.filterWarehouseData();
    }
  }

  setPrimaryWarehouse() {
    if (this.warehouses && this.warehouses.length) {
      this.warehouses.forEach((warehouse) => {
        if (
          (!this.selectedWarehouseReference && warehouse.primary) ||
          warehouse.reference === this.selectedWarehouseReference
        ) {
          setTimeout(() => {
            this.changeWarehouse(warehouse);
          }, 100);
        }
      });
    }
  }

  filterWarehouseData() {
    this.warehouses = this.warehouses.map(({ phoneNumber, email, contactName, ...rest }) => {
      return rest;
    });
  }

  public onTouched: () => void = () => {};

  changeWarehouse = (warehouse: any) => {
    if (warehouse) {
      this.updateRegion.emit();

      this.warehouseForm.patchValue({
        warehouse: warehouse.reference,
        warehouseName: `${warehouse.suburb} ${warehouse.city}`,
      });
    }
  };

  filterAddress(warehouse) {
    return Object.keys(warehouse).reduce((acc, key) => {
      if (typeof warehouse[key] === 'string' && warehouse[key] !== undefined) {
        acc[key] = warehouse[key];
      }
      return acc;
    }, {});
  }

  get warehouseAddress() {
    if (this.warehouses.length === 1) {
      const warehouse: any = this.filterAddress(this.warehouses[0]);
      return `${warehouse.address1 ? warehouse.address1 : ''} ${
        warehouse.postalcode ? warehouse.postalcode : ''
      } ${warehouse.suburb ? warehouse.suburb : ''} ${warehouse.city ? warehouse.city : ''}`;
    }
    return undefined;
  }

  writeValue(val: any): void {
    if (val && Object.keys(val).length) {
      this.warehouseForm.patchValue({ ...val });
    }
  }

  registerOnChange(fn: any): void {
    this.warehouseForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.warehouseForm.disable();
    } else {
      this.warehouseForm.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.warehouseForm.valid
      ? null
      : {
          valid: false,
          message: 'Warehouse section is invalid',
        };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { Button } from './components/button/button';

@NgModule({
    declarations: [Button],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [Button]
})
export class ButtonModule {}

import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/authentication';
import { BusinessProfile, UserAccountStatus, Warehouse } from '../models/authentication.models';

export interface InitialState {
  authenticated: boolean;
  remember: boolean;
  settings: {
    loginSubmitted: boolean;
    signUpInProgress: boolean;
  };
  createUserAccount: {
    userName: string;
    email: string;
    createUserAccountInProgress: boolean;
    createUserAccountSuccessful: boolean;
    emailAlreadyExists: boolean;
    accountAlreadyCreated: boolean;
    accountConfirmationStatus: UserAccountStatus;
  };
  createBusinessProfile: {
    createBusinessProfileInProgress: boolean;
    profile: BusinessProfile;
  };
  createWarehouse: {
    createWarehouseInProgress: boolean;
    warehouse: Warehouse;
  };
}

export const initialState: InitialState = {
  authenticated: false,
  remember: false,
  settings: {
    loginSubmitted: false,
    signUpInProgress: false,
  },
  createUserAccount: {
    userName: '',
    email: '',
    createUserAccountInProgress: false,
    emailAlreadyExists: false,
    accountAlreadyCreated: false,
    createUserAccountSuccessful: false,
    accountConfirmationStatus: UserAccountStatus.Unknown,
  },
  createBusinessProfile: {
    createBusinessProfileInProgress: false,
    profile: null,
  },
  createWarehouse: {
    createWarehouseInProgress: false,
    warehouse: null,
  },
};

export const authenticationReducerKey = 'authentication';

const authenticationReducer = createReducer(
  initialState,
  on(actions.loginSuccess, (state: InitialState) => {
    return {
      ...state,
      authenticated: true,
      settings: {
        ...state.settings,
        loginSubmitted: false,
      },
    };
  }),
  on(actions.login, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        loginSubmitted: true,
      },
    };
  }),
  on(actions.resetLogin, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        loginSubmitted: false,
      },
    };
  }),
  on(actions.refreshLoginSuccess, (state: InitialState) => {
    return {
      ...state,
      authenticated: true,
    };
  }),
  on(actions.logoutSuccess, (state: InitialState) => {
    return {
      ...state,
      authenticated: false,
      remember: false,
    };
  }),
  on(actions.rememberLogin, (state: InitialState) => {
    return {
      ...state,
      remember: true,
    };
  }),
  on(actions.createUserAccount, (state: InitialState, { userAccountData }) => {
    return {
      ...state,
      createUserAccount: {
        ...state.createUserAccount,
        createUserAccountInProgress: true,
        userName: userAccountData.name,
        email: userAccountData.email,
      },
    };
  }),
  on(actions.createUserAccountStoreData, (state: InitialState, { name, email }) => {
    return {
      ...state,
      createUserAccount: {
        ...state.createUserAccount,
        userName: name,
        email: email,
      },
    };
  }),
  on(actions.createUserAccountEmailExists, (state: InitialState) => {
    return {
      ...state,
      createUserAccount: {
        ...state.createUserAccount,
        emailAlreadyExists: true,
        createUserAccountInProgress: false,
        createUserAccountSuccessful: false,
      },
    };
  }),
  on(actions.createUserAccountError, (state: InitialState) => {
    return {
      ...state,
      createUserAccount: {
        ...state.createUserAccount,
        createUserAccountInProgress: false,
        emailAlreadyExists: false,
        createUserAccountSuccessful: false,
      },
    };
  }),
  on(actions.createUserAccountSuccessful, (state: InitialState) => {
    return {
      ...state,
      createUserAccount: {
        ...state.createUserAccount,
        createUserAccountSuccessful: true,
        createUserAccountInProgress: false,
        emailAlreadyExists: false,
      },
    };
  }),
  on(actions.createUserAccountResetEmailExists, (state: InitialState) => {
    return {
      ...state,
      createUserAccount: {
        ...state.createUserAccount,
        emailAlreadyExists: false,
      },
    };
  }),
  on(actions.createUserAccountAuth, (state: InitialState) => {
    return {
      ...state,
      authenticated: true,
    };
  }),
  on(
    actions.createUserAccountConfirmation,
    (state: InitialState, { accountConfirmationStatus }) => {
      return {
        ...state,
        createUserAccount: {
          ...state.createUserAccount,
          accountConfirmationStatus: accountConfirmationStatus,
        },
      };
    },
  ),
  on(actions.createBusinessProfile, (state: InitialState) => {
    return {
      ...state,
      createBusinessProfile: {
        ...state.createBusinessProfile,
        createBusinessProfileInProgress: true,
      },
    };
  }),
  on(actions.storeBusinessProfile, (state: InitialState, { profileData }) => {
    return {
      ...state,
      createBusinessProfile: {
        ...state.createBusinessProfile,
        profile: profileData,
        createBusinessProfileInProgress: false,
      },
    };
  }),
  on(actions.createWarehouse, (state: InitialState) => {
    return {
      ...state,
      createWarehouse: {
        ...state.createWarehouse,
        createWarehouseInProgress: true,
      },
    };
  }),
  on(actions.storeWarehouse, (state: InitialState, { warehouseData }) => {
    return {
      ...state,
      createWarehouse: {
        ...state.createWarehouse,
        warehouse: warehouseData,
        createWarehouseInProgress: false,
      },
    };
  }),
  on(actions.resetSignUpData, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        signUpInProgress: false,
      },
      createUserAccount: {
        ...state.createUserAccount,
        userName: '',
        email: '',
        createUserAccountInProgress: false,
        emailAlreadyExists: false,
        accountAlreadyCreated: false,
        createUserAccountSuccessful: false,
        accountConfirmationStatus: UserAccountStatus.Unknown,
      },
      createWarehouse: {
        ...state.createWarehouse,
        warehouse: null,
      },
      createBusinessProfile: {
        ...state.createBusinessProfile,
        profile: null,
      },
    };
  }),
  on(actions.signUpInProgress, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        signUpInProgress: true,
      },
    };
  }),
);

export default function reducer(state, action) {
  return authenticationReducer(state, action);
}

<section class="shipment-details" [formGroup]="returnMethodForm">
  <h4 class="shipment-details__heading heading heading__lined">
    <span>{{ (core$ | async).settings.hasCredits ? 'Drop-off point' : 'Return method' }}</span>
  </h4>

  <section class="shipment-details-slider">
    <input type="hidden" name="returnMethod" formControlName="returnMethod" />

    <div class="shipment-details-slider__header" *ngIf="!(core$ | async).settings.hasCredits">
      <ul
        class="shipment-details-slider__header-list"
        *ngIf="processTabs && processTabs.length > 1"
      >
        <li
          class="shipment-details-slider__header-item"
          *ngFor="let process of processTabs; let i = index"
        >
          <div
            [class]="current === i ? 'shipment-details-slider__header-container shipment-details-slider__header-container--active' : 'shipment-details-slider__header-container'"
            (click)="goToSlide(i)"
          >
            <h3
              [class]="current === i ? 'shipment-details-slider__header-heading shipment-details-slider__header-heading--active' : 'shipment-details-slider__header-heading'"
            >
              {{ process.heading }}
            </h3>
          </div>
          <div
            [class]="current === i ? 'shipment-details-slider__header-indicator shipment-details-slider__header-indicator--active' : 'shipment-details-slider__header-indicator'"
          ></div>
        </li>
      </ul>
      <ul
        class="shipment-details-slider__indicator-list"
        *ngIf="processTabs && processTabs.length > 1"
      >
        <li
          class="shipment-details-slider__indicator-item"
          *ngFor="let process of processTabs; let i = index"
        >
          <div
            [class]="process.process === returnMethod ? 'shipment-details-slider__indicator shipment-details-slider__indicator--active' : 'shipment-details-slider__indicator'"
          ></div>
        </li>
      </ul>
    </div>
    <div class="shipment-details__content">
      <app-pickup-point-selection
        [formControl]="returnMethodForm.controls['p2w_return']"
        *ngIf="current === 0"
        (updateRegion)="handleUpdateRegion($event)"
        [mapToken]="defaultMapReturnToken"
      >
      </app-pickup-point-selection>
      <app-return-from-home
        [formControl]="returnMethodForm.controls['d2w']"
        [returnMethod]="returnMethod"
        (updateRegion)="handleUpdateRegion($event)"
        *ngIf="current === 1 && !(core$ | async).settings.hasCredits"
      >
      </app-return-from-home>
    </div>
  </section>
</section>

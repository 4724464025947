import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { selectDevice } from '@core/selectors/core';
import { Store } from '@ngrx/store';
import { WalkthroughToggleComponent } from '@walkthrough/components/walkthrough-toggle/walkthrough-toggle';
import { take } from 'rxjs/operators';

import { navigationData } from '../../data/data';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.html',
  styleUrls: ['./navigation.scss'],
})
export class NavigationComponent implements OnInit, OnChanges {
  @Input() authenticated: boolean;
  @Input() profile: any;
  @Input() credits: number;
  @Input() hasCredits: boolean;
  @Input() processes: string[];

  @Output() logout = new EventEmitter();

  @ViewChild(WalkthroughToggleComponent) walkthrough: WalkthroughToggleComponent;

  items: any;

  showMobile: boolean = false;

  guards: string[] = [];

  userSubNav = [
    {
      title: 'Profile',
      fn: () => this.router.navigate(['/account']),
    },
    {
      title: 'Logout',
      fn: () => this.logout.emit(),
    },
  ];

  supportSubNav = [
    {
      title: 'Quick Tips',
      fn: this.toggleWalkthrough.bind(this),
    },
    {
      title: 'Help Centre',
      fn: function () {
        window.open('https://helpdesk.pargo.co.za/portal/en/home', '_blank');
      },
    },
  ];

  constructor(private router: Router, private store: Store<any>) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (this.showMobile) {
          this.showMobile = false;
        }
      }
    });
  }

  ngOnInit() {
    this.setNavigation();
  }

  ngOnChanges() {
    this.setNavigation();
  }

  get username() {
    if (this.profile && this.profile.supplier && this.profile.supplier.name) {
      return this.profile.supplier.name;
    }
  }

  getAvatar(): string {
    return (
      this.profile.supplier.profilePictureUrls.small ?? 'assets/images/toolbar-icons/Avatar.svg'
    );
  }

  setNavigation() {
    if (this.authenticated) {
      this.store
        .select(selectDevice)
        .pipe(take(1))
        .subscribe((device) => {
          if (device.mobile) {
            this.items = [...navigationData, ...this.createMobileMenuData()];
          } else {
            this.items = navigationData;
          }
        });
      this.setNavigationGuards();
    } else {
      this.items = undefined;
    }
  }

  setNavigationGuards() {
    if (this.processes && this.authenticated) {
      this.guards = [...this.guards, ...this.processes];
    }

    if (this.hasCredits && this.authenticated) {
      this.guards = [...this.guards, 'payments'];
    }

    const filteredMenu = this.menuGuard();
    this.items = filteredMenu;
  }

  menuGuard() {
    let menu = this.items.filter((item) => {
      if (item.guard) {
        return item.guard && this.guards.includes(item.guard);
      } else {
        return true;
      }
    });

    menu = menu.map((item) => {
      let newItem = item;

      if (newItem && newItem.submenu && newItem.submenu.items) {
        return {
          ...newItem,
          submenu: {
            ...newItem.submenu,
            items: newItem.submenu.items.filter((subItem) => {
              if (subItem.guard) {
                return subItem.guard && this.guards.includes(subItem.guard);
              }
              return subItem;
            }),
          },
        };
      }
      return newItem;
    });

    return menu;
  }

  handleLogout() {
    this.logout.emit();
  }

  toggleMobileMenu() {
    this.showMobile = !this.showMobile;
  }

  toggleWalkthrough() {
    this.walkthrough.toggleWalkthrough();
  }

  createMobileMenuData() {
    const mobileMenuData = [
      {
        title: 'Support',
        fn: () => {
          this.toggleMobileMenu();
          this.toggleWalkthrough();
        },
        submenu: {
          items: [
            {
              title: 'Quick Tips',
              fn: () => {
                this.toggleMobileMenu();
                this.toggleWalkthrough();
              },
            },
            {
              title: 'Help Centre',
              fn: function () {
                window.open('https://helpdesk.pargo.co.za/portal/en/home', '_blank');
              },
            },
          ],
        },
      },
      {
        title: 'Profile',
        fn: () => {
          this.toggleMobileMenu();
          this.router.navigate(['/account']);
        },
        submenu: {
          items: [
            {
              title: 'Profile',
              fn: () => {
                this.toggleMobileMenu();
                this.router.navigate(['/account']);
              },
            },
            {
              title: 'Logout',
              fn: () => this.logout.emit(),
            },
          ],
        },
      },
    ];

    return mobileMenuData;
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-flyout',
  templateUrl: './navigation-flyout.html',
  styleUrls: ['./navigation-flyout.scss'],
})
export class NavigationFlyoutComponent {
  @Input() items: any;
  @Input() all?: any;

  constructor() {}
}

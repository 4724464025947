import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { addNotification } from '../../actions/globals';

import { UPLOAD_ICONS } from '../../../../icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.html',
  styleUrls: ['./upload-files.scss'],
})
export class UploadFilesComponent {
  @Input() extentions: string[];
  @Input() max: number;
  @Input() generate?: any;
  @Input() generateOptions?: any;
  @Input() cb: any;

  @ViewChild('uploadInput', { static: true }) uploadInput: ElementRef;
  files: any;

  uploadForm: UntypedFormGroup = this.formBuilder.group({
    file: [''],
  });

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    private store: Store<any>,
  ) {
    UPLOAD_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  get allowedExtentions() {
    return this.extentions.join(', ');
  }

  matchExtentions(files) {
    return Object.keys(files).map((key) => {
      const file = files[key];
      const { name } = file;
      const extention = name.split('.').pop();

      if (!this.extentions.includes(extention)) {
        return false;
      }
      return true;
    });
  }

  openUpload() {
    const input = this.uploadInput.nativeElement;
    input.click();
  }

  handleFileInput(event) {
    const files = event.target.files;

    if (!this.matchExtentions(files).includes(false)) {
      this.files = files;
    } else {
      const notification = {
        message: 'File extention is not allowed.',
        type: 'fade',
        class: 'error',
      };
      this.store.dispatch(addNotification({ notification }));
    }
  }

  handleForm() {
    if (this.files) {
      this.cb(this.files);
    }
  }

  onDrop(event) {
    event.preventDefault();

    const files = event.dataTransfer.items;
    this.handleFiles(files);
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  handleFiles(files) {
    if (files && files.length > !this.max) {
      const mappedFiles = [...files].map((item) => {
        const file = item.getAsFile();
        return file;
      });

      if (!this.matchExtentions(mappedFiles).includes(false)) {
        this.cb(mappedFiles);
      } else {
        const notification = {
          message: 'File extention is not allowed.',
          type: 'fade',
          class: 'error',
        };
        this.store.dispatch(addNotification({ notification }));
      }
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerHeadingModule } from '../../typography/container-heading/container-heading.module';
import { ContainerModule } from '../../layout/container/container.module';

import { CardCreditsPackagesModule } from '../../molecules/cards/card-credits-packages/card-credits-packages.module';

import { CreditsPackages } from './components/credits-packages/credits-packages';

@NgModule({
    declarations: [CreditsPackages],
    imports: [
        CommonModule,
        MatIconModule,
        ContainerModule,
        ContainerHeadingModule,
        CardCreditsPackagesModule,
    ],
    providers: [],
    exports: [CreditsPackages]
})
export class CreditsPackagesModule {}

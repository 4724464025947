import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { InputComponent } from './components/input/input';

@NgModule({
    declarations: [InputComponent],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [InputComponent]
})
export class InputModule {}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CreateRootComponent } from './create';

import { ReturnPageComponent } from './pages/return/return.component';
import { UploadPageComponent } from './pages/upload/upload';
import { CreatePageComponent } from './pages/create/create.component';

export const createChildrenRoutes: Routes = [
  {
    path: 'order',
    component: CreatePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'order/pick-up-point',
    component: CreatePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'order/home-delivery',
    component: CreatePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'order/return',
    component: ReturnPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'upload',
    component: UploadPageComponent,
    pathMatch: 'full',
  },
  { path: 'order/door-delivery', redirectTo: 'order/home-delivery' },
  { path: '', redirectTo: 'order', pathMatch: 'full' },
];

export const createRoutes: Routes = [
  {
    path: '',
    component: CreateRootComponent,
    children: createChildrenRoutes,
  },
];

@NgModule({
  imports: [RouterModule.forChild(createRoutes)],
  exports: [RouterModule],
})
export class CreateRoutingModule {}

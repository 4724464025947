import uuid from 'uuid/v1';

export const orders = {
  page: '/orders',
  paymentGuard: undefined,
  processGuard: undefined,
  steps: [
    {
      id: uuid(),
      page: '/orders',
      text: `Select multiple orders to use the batch actions: print waybills, create order manifest, confirm orders.`,
      title: 'Batch actions',
      attachedToElement: '.batch-actions',
    },
    {
      id: uuid(),
      page: '/orders',
      text: `Hover or click the button to view all actions related to this order. `,
      title: 'Order actions',
      attachedToElement: '.card-row__actions',
    },
    {
      id: uuid(),
      page: '/orders',
      text: `Click on the order to view the order details.`,
      title: 'Your order',
      attachedToElement: '.card-row',
    },
    {
      id: uuid(),
      page: '/orders',
      text: `Filter your orders by status, service, date or customer details. You can even export your search results to a CSV.`,
      title: 'Filter orders',
      attachedToElement: '.filter-advanced__toggle',
    },
    {
      id: uuid(),
      page: '/orders',
      text: `Search in orders on waybill, name or other order related fields.`,
      title: 'Search for orders',
      attachedToElement: '.filter__query',
    },
  ],
};
export const createOrder = {
  page: '/create/order',
  paymentGuard: undefined,
  steps: [
    {
      id: uuid(),
      page: '/create/order',
      text: `Select the weight of the parcel. Not sure about the weight? Please use the volumetric weight calculator.`,
      title: 'Parcel weight',
      attachedToElement: '.parcel-details',
    },
    {
      id: uuid(),
      page: '/create/order',
      text: `Recipient information`,
      title: 'Provide the correct details of the receiver of the order.',
      attachedToElement: '.summshipment-details-slider',
    },
    {
      id: uuid(),
      page: '/create/order',
      text: `Collection warehouse`,
      title:
        'Select the correct warehouse where the order needs to be collected from by the courier.',
      attachedToElement: '.summshipment-details-slider',
    },
    {
      id: uuid(),
      page: '/create/order',
      title: `Pick a delivery method`,
      text: 'Pick a delivery method. Depending on the delivery type either select a pickup point or home delivery.',
      attachedToElement: '.shipment-details-slider',
    },
    {
      id: uuid(),
      page: '/create/order',
      text: `Summary of the order you are creating. Quickly check if everything is correct.`,
      title: 'Order summary',
      attachedToElement: '.delivery-summary',
    },
    {
      id: uuid(),
      page: '/create/order',
      text: `SAVE DRAFT to keep your order in a pending status while you prepare the order for collection.<br><br>
CONFIRM AND PRINT to print the label and trigger courier collection now. Credits will be deducted when an order is confirmed.`,
      title: 'Save as draft or confirm',
      attachedToElement: '.add-order-form__footer',
    },
  ],
};
export const createReturnOrder = {
  page: '/create/order/return',
  paymentGuard: undefined,
  processGuard: 'p2w_return',
  steps: [
    {
      id: uuid(),
      page: '/create/order/return',
      text: `Provide the details of the customer who is returning the parcel.`,
      title: 'Returning customer',
      attachedToElement: '.receiver-details',
    },
    {
      id: uuid(),
      page: '/create/order/return',
      text: `Select whether the return method is from a Pargo Point or Return from Home (if enabled on your profile).`,
      title: 'Pick a return method',
      attachedToElement: '.shipment-details-slider',
    },
    {
      id: uuid(),
      page: '/create/order/return',
      title: `Dropoff  warehouse`,
      text: 'Select the correct warehouse the order needs to be returned to by the courier.',
      attachedToElement: '.warehouse__information',
    },
  ],
};
export const creditDashboard = {
  page: '/credits/dashboard',
  paymentGuard: true,
  steps: [
    {
      id: uuid(),
      page: '/credits/dashboard',
      text: `Here you can:
<ul class="m-l-10">
<li>Track your spending on bundles</li>
<li>View the status of your invoices</li>
<li>Download invoices</li>
</ul>`,
      title: 'Invoice dashboard',
      attachedToElement: '.dashboard__credits',
    },
  ],
};
export const creditsBuy = {
  page: '/credits/buy',
  paymentGuard: true,
  steps: [
    {
      id: uuid(),
      page: '/credits/buy',
      text: `Alway be on top of your current credit balance.`,
      title: 'Credit balance',
      attachedToElement: '.credits-amount',
    },
    {
      id: uuid(),
      page: '/credits/buy',
      text: `Quickly check how many credits will be deducted per order and shipping method, depending on the parcel weight and region.`,
      title: 'Price list',
      attachedToElement: '.process-pricelist',
    },
    {
      id: uuid(),
      page: '/credits/buy',
      text: `Increase your credits with a credit value pack.`,
      title: 'Buy credits',
      attachedToElement: '.credits-packages__list',
    },
  ],
};
export const homeDashboard = {
  page: '/dashboard/home',
  paymentGuard: undefined,
  steps: [
    {
      id: uuid(),
      page: '/dashboard/home',
      text: `Always be up to date with Pargo's latest updates.`,
      title: 'News updates',
      attachedToElement: '.dashboard__news',
    },
    {
      id: uuid(),
      page: '/dashboard/home',
      text: `Hover over the bar graph to see how many credits you have spent this month.`,
      title: 'Credit usage',
      attachedToElement: '.dashboard__credits-usage',
    },
    {
      id: uuid(),
      page: '/dashboard/home',
      text: `Click and go.`,
      title: 'Direct actions',
      attachedToElement: '.dashboard__actions',
    },
    {
      id: uuid(),
      page: '/dashboard/home',
      text: `View the recently created orders and action them directly.`,
      title: 'Recently created orders',
      attachedToElement: '.dashboard__orders',
    },
    {
      id: uuid(),
      page: '/dashboard/home',
      text: `Visit Pargo Quick Tips anytime to freshen up your myPargo skills. For additional support please visit the Pargo Help Centre.`,
      title: 'Support',
      attachedToElement: '.dashboard__help',
    },
  ],
};
export const uploadOrders = {
  page: '/create/upload',
  paymentGuard: undefined,
  steps: [
    {
      id: uuid(),
      page: '/create/upload',
      text: `Upload orders in bulk. Download the correct Excel template. Use the template ‘Legend’ as a guide. Upload the completed template.`,
      title: 'Mass upload orders',
      attachedToElement: '.upload-files',
    },
  ],
};

export const connectStore = {
  page: '/connect-store',
  paymentGuard: undefined,
  steps: [
    {
      id: uuid(),
      page: '/connect-store',
      text: 'Sync your online orders to myPargo for direct actioning in one portal.',
      title: 'Connect store',
      attachedToElement: '.connect',
    },
  ],
};

export const account = {
  page: '/account',
  paymentGuard: undefined,
  steps: [
    {
      id: uuid(),
      page: '/account',
      text: `View your account details`,
      title: 'User details',
      attachedToElement: '.profile__user-details',
    },
    {
      id: uuid(),
      page: '/account',
      text: `View your business details.`,
      title: 'Business details',
      attachedToElement: '.profile__business-details',
    },
    {
      id: uuid(),
      page: '/account',
      text: `View your business address.`,
      title: 'Business address',
      attachedToElement: '.profile__business-address',
    },
    {
      id: uuid(),
      page: '/account',
      text: `View the warehouses associated with your account.`,
      title: 'Your warehouses',
      attachedToElement: '.profile__warehouse-details',
    },
  ],
};

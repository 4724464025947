import { environment } from 'src/environments/environment';
import { NotificationConfig } from '../../notifications/models/notifications';
import { Warehouse } from '@shared/models/warehouse';

export const isBlank = (value: any): boolean => {
  return (
    value === null ||
    value === undefined ||
    value.length === 0 ||
    (typeof value == 'string' && !!value.match(/^\s*$/)) ||
    (typeof value == 'object' && Object.keys(value).length == 0)
  );
};

export const createErrorNotificationConfig = (error: any): NotificationConfig => {
  const message =
    error?.error?.message ??
    error?.error?.errors?.[0]?.title ??
    `Something went wrong. If this issue persists, contact ${environment.pargoMail}`;

  return {
    message: message,
    type: 'fade',
    class: 'error',
  };
};

export const isNumeric = (value: string): boolean => {
  return /^\d+$/.test(value.replace(/\s+/g, ''));
};

export const isSystemGeneratedWarehouseRef = (reference: string): boolean => {
  const defaultWarehouseRefChar = 'W';
  return reference.charAt(0) === defaultWarehouseRefChar && isNumeric(reference.substring(1));
};

export const sortWarehouses = (warehouses: Warehouse[]): Warehouse[] => {
  return warehouses.sort((a, b) => {
    if (isSystemGeneratedWarehouseRef(a.reference) && isSystemGeneratedWarehouseRef(b.reference)) {
      const aNumber = Number(a.reference.substring(1));
      const bNumber = Number(b.reference.substring(1));
      return aNumber < bNumber ? -1 : 1;
    } else {
      return a.reference.toLowerCase() < b.reference.toLowerCase() ? -1 : 1;
    }
  });
};

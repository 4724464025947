<div [class]="small ? 'dropdown dropdown--small' : 'dropdown'">
  <label [class]="open ? 'dropdown__label dropdown__label--open' : 'dropdown__label'" *ngIf="value"
    >{{label}}</label
  >
  <div
    [class]="open ? 'dropdown__select dropdown__select--open' : 'dropdown__select'"
    (click)="toggleDropdown()"
  >
    <span class="dropdown__selected">{{value ? value.label : label}}</span>
    <mat-icon class="dropdown__icon"> {{ open ? 'expand_less' : 'expand_more'}} </mat-icon>
  </div>
  <ul class="dropdown__options" *ngIf="open">
    <li
      class="dropdown__option"
      *ngFor="let item of options"
      (click)="setDropdownValue(item.value)"
    >
      {{item.label}}
    </li>
  </ul>
</div>

import { createAction, props } from '@ngrx/store';
import { NotificationConfig } from 'src/app/modules/notifications/models/notifications';

export const INIT_APPLICATION = 'GLOBAL/INIT_APPLICATION';
export const REFRESH_LOGIN = 'GLOBAL/REFRESH_LOGIN';
export const LOGOUT_SUCCESS = 'GLOBAL/LOGOUT_SUCCESS';
export const LOGIN_SUCCESS = 'GLOBAL/LOGIN_SUCCESS';
export const ADD_CREDITS = 'GLOBAL/ADD_CREDITS';
export const LOGOUT = 'GLOBAL/LOGOUT';
export const SIGNUP_AUTH = 'GLOBAL/SIGNUP_AUTH';
export const AUTH_REDIRECTION = 'GLOBAL/AUTH_REDIRECTION';
export const ADD_NOTIFICATION = 'GLOBAL/ADD_NOTIFICATION';
export const THROW_RESPONSE_ERR = 'GLOBAL/THROW_RESPONSE_ERR';

export const refreshLogin = createAction(REFRESH_LOGIN);
export const logout = createAction(LOGOUT);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const addCredits = createAction(ADD_CREDITS, props<{ credits }>());
export const signUpAuth = createAction(SIGNUP_AUTH);
export const authRedirection = createAction(AUTH_REDIRECTION, props<{ supplier: any }>());
export const addNotification = createAction(
  ADD_NOTIFICATION,
  props<{ notification: NotificationConfig }>(),
);

export const actions = {
  refreshLogin,
  loginSuccess,
  logoutSuccess,
  addCredits,
  logout,
  signUpAuth,
  addNotification,
};

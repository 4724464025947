<div class="connect">
  <div class="container">
    <h2 class="connect__heading">Connect Your Store</h2>

    <div class="connect__plugin-cards-row">
      <h3 class="connect--web-only connect__sub-heading">
        Supported plugins by Pargo (Download, configure, start)
      </h3>
      <h3 class="connect--mobile-only connect__sub-heading">Supported plugins</h3>
      <app-plugin-card
        class="connect__first-plugin"
        [pluginConfig]="ShopifyConfig"
        (pluginActivation)="activatePlugin($event)"
      ></app-plugin-card>
      <app-plugin-card
        [pluginConfig]="WoocommerceConfig"
        (pluginActivation)="activatePlugin($event)"
      ></app-plugin-card>
    </div>

    <div class="connect--web-only connect__plugin-cards-row">
      <h3 class="connect__sub-heading">Connect Via Pargo API, Requires technical assistance.</h3>
      <app-plugin-card
        class="connect__first-plugin"
        [pluginConfig]="PargoApiConfig"
        (pluginActivation)="activatePlugin($event)"
      ></app-plugin-card>
    </div>
  </div>
</div>

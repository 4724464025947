import { createAction, props } from '@ngrx/store';

export const GET_ORDERS = 'GLOBAL/GET_ORDERS';
export const SET_CURRENT_ORDER = 'GLOBAL/SET_CURRENT_ORDER';
export const ADD_NOTIFICATION = 'GLOBAL/ADD_NOTIFICATION';
export const REFRESH_TOKEN_SUCCESS = 'GLOBAL/REFRESH_TOKEN_SUCCESS';
export const LOGIN_SUCCESS = 'GLOBAL/LOGIN_SUCCESS';
export const COPY_ORDER = 'GLOBAL/COPY_ORDER';
export const REFRESH_PROFILE = 'GLOBAL/REFRESH_PROFILE';
export const EDIT_ORDER = 'GLOBAL/EDIT_ORDER';
export const RETURN_ORDER = 'GLOBAL/RETURN_ORDER';
export const HIGHLIGHT_ORDER = 'GLOBAL/HIGHLIGHT_ORDER';
export const RESET_FILTERS = 'GLOBAL/RESET_FILTERS';
export const ADD_CREDITS = 'GLOBAL/ADD_CREDITS';

export const addNotification = createAction(ADD_NOTIFICATION, props<{ notification }>());

export const getOrders = createAction(GET_ORDERS);

export const copyOrder = createAction(COPY_ORDER, props<{ order }>());

export const editOrder = createAction(EDIT_ORDER, props<{ order }>());

export const returnOrder = createAction(RETURN_ORDER, props<{ order }>());

export const setCurrentOrder = createAction(SET_CURRENT_ORDER, props<{ id: string }>());

export const highlightOrder = createAction(HIGHLIGHT_ORDER, props<{ id: string }>());

export const addCredits = createAction(ADD_CREDITS, props<{ credits: string }>());

export const actions = {
  addNotification,
  getOrders,
  setCurrentOrder,
  copyOrder,
  editOrder,
  returnOrder,
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { RouterLinkModule } from '../../atoms/router-link/router-link.module';

import { NavigationFlyoutComponent } from './components/navigation-flyout/navigation-flyout';

@NgModule({
    declarations: [NavigationFlyoutComponent],
    imports: [CommonModule, MatIconModule, RouterLinkModule],
    providers: [],
    exports: [NavigationFlyoutComponent]
})
export class NavigationFlyoutModule {}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FaqPageComponent } from './modules/shared/pages/faq-page/faq-page';

import { EmailSignature } from './modules/shared/components/molecules/email-signature/email-signature';

import { AuthGuardService } from './modules/authentication/services/authentication-guard.service';

import { createChildrenRoutes } from './modules/create/create-routing.module';

const appRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboards').then((m) => m.DashboardModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication').then((m) => m.AuthenticationModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./modules/orders').then((m) => m.OrdersModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'create',
    children: createChildrenRoutes,
    canActivate: [AuthGuardService],
  },
  {
    path: 'credits',
    loadChildren: () => import('./modules/credits').then((m) => m.CreditsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/profile').then((m) => m.ProfileModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'pay',
    loadChildren: () =>
      import('./modules/international-payment').then((m) => m.InternationalPaymentModule),
  },
  {
    path: 'connect-store',
    loadChildren: () => import('./modules/connect-store').then((m) => m.ConnectStoreModule),
    pathMatch: 'full',
  },
  {
    path: 'faq',
    component: FaqPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'hack/signature',
    component: EmailSignature,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

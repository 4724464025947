import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { InitialState } from '../reducers/authentication';
import { signUpInProgress } from '../selectors/authentication';

@Injectable()
export class SignUpGuardService  {
  signUpInProgress$: Observable<any> = this.store.select(signUpInProgress);

  constructor(private router: Router, private store: Store<InitialState>) {}

  canActivate(): Observable<boolean> {
    return this.signUpInProgress$.pipe(
      map((userIsSigningUp: boolean) => {
        if (!userIsSigningUp) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      }),
    );
  }
}

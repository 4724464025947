import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerHeadingModule } from '../../typography/container-heading/container-heading.module';

import { ButtonModule } from '../../atoms/button/button.module';

import { CustomsFeeOverview } from './components/customs-fee-overview/customs-fee-overview';

@NgModule({
    declarations: [CustomsFeeOverview],
    imports: [CommonModule, MatIconModule, ContainerHeadingModule, ButtonModule],
    providers: [],
    exports: [CustomsFeeOverview]
})
export class CustomFeeOverviewModule {}

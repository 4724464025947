import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormBuilder,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { AddressResult } from 'src/app/modules/shared/components/molecules/json-search/models/json-search';

@Component({
  selector: 'app-order-receiver-home',
  templateUrl: './order-receiver-home.html',
  styleUrls: ['./order-receiver-home.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderReceiverHomeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrderReceiverHomeComponent),
      multi: true,
    },
  ],
})
export class OrderReceiverHomeComponent implements ControlValueAccessor, Validator {
  @Input() heading?: string;
  @Input() deliveryMethod?: string;

  @Output() updateRegion = new EventEmitter();

  receiverForm = this.formBuilder.group({
    addressOne: ['', [Validators.required, Validators.maxLength(255)]],
    addressTwo: ['', [Validators.maxLength(255)]],
    suburb: ['', [Validators.required]],
    postalCode: ['', [Validators.required]],
    city: ['', [Validators.required]],
    province: [''],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}

  get endpoints() {
    return [`${window.location.origin}/assets/data/route-guide.json`];
  }

  get suburbPostalCodeCity() {
    const { suburb, postalCode, city } = this.receiverForm.value;
    if (suburb) {
      return {
        suburb,
        postalCode,
        city,
      };
    }
  }

  setDetails(value: AddressResult): void {
    this.receiverForm.patchValue({
      suburb: value ? String(value.suburb) : undefined,
      postalCode: value ? String(value.postalCode) : undefined,
      city: value ? String(value.city) : undefined,
      province: value ? String(value.province) : undefined,
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val && Object.keys(val).length) {
      this.receiverForm.patchValue({ ...val });
    }

    if (val === null) {
      this.receiverForm.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.receiverForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.receiverForm.disable();
    } else {
      this.receiverForm.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (this.receiverForm.valid) {
      const address = this.receiverForm.value;
      this.updateRegion.emit(address);
    }

    return this.receiverForm.valid
      ? null
      : {
          valid: false,
          message: 'Receiver details section is invalid',
        };
  }
}

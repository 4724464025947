import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Container } from './components/container/container';

@NgModule({
    declarations: [Container],
    imports: [CommonModule],
    providers: [],
    exports: [Container]
})
export class ContainerModule {}

<div class="plugin-card">
  <img class="plugin-card__img" [src]="pluginConfig.logo" />
  <div class="plugin-card__offers-container">
    <div class="plugin-card__offer" *ngFor="let offer of pluginConfig.offers">
      <img
        class="plugin-card__offer-icon"
        src="assets/images/supplier-plugins/success checkmark.svg"
      />
      <div class="plugin-card__offer-text">{{ offer }}</div>
    </div>
  </div>
  <pargo-button (click)="activatePlugin()" [label]="pluginConfig.actionLabel"></pargo-button>
</div>

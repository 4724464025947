import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  ViewContainerRef,
  ViewChild,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import {
  UntypedFormGroup,
  UntypedFormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormBuilder,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

import { selectProcesses, selectUser } from '../../../selectors/create';

import { InitialState } from '../../../reducers/create';
import { User } from '../../../models/create';

@Component({
  selector: 'app-order-delivery-method',
  templateUrl: './order-delivery-method.html',
  styleUrls: ['./order-delivery-method.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeliveryMethodComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DeliveryMethodComponent),
      multi: true,
    },
  ],
})
export class DeliveryMethodComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChild('componentContainer', { read: ViewContainerRef, static: true })
  componentContainer: ViewContainerRef;

  @Input() editing: boolean;

  @Input() deliveryMethod: string;

  @Output() updateRegion = new EventEmitter();

  current: number = 0;

  processes$: Observable<any> = this.store.select(selectProcesses);

  processes;

  process: string;

  subscriptions: Subscription[] = [];

  user$: Observable<object>;

  defaultMapToken: string;

  deliveryMethodForm: UntypedFormGroup = this.formBuilder.group({
    w2p: this.formBuilder.control({}),
    w2d: this.formBuilder.control({}),
    w2dPlus: this.formBuilder.control({}),
    deliveryMethod: [],
  });

  processTabs = [
    {
      process: 'w2p',
      heading: 'Pick up point',
    },
    {
      process: 'w2d',
      heading: 'Home delivery',
    },
    {
      process: 'w2pPlus',
      heading: 'Home delivery plus',
    },
  ];

  constructor(
    public store: Store<InitialState>,
    public formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.deliveryMethod = params.process;
      this.setDeliveryMethod();
    });
  }

  ngOnInit() {
    this.user$ = this.store.select(selectUser);
    const userSubscription = this.user$.subscribe((user: User) => {
      this.defaultMapToken = user.defaultMapToken;
    });
    this.subscriptions.push(userSubscription);

    const processesSubscription = this.processes$.subscribe((processes) => {
      if (processes) {
        this.processes = processes;
        this.processTabs = this.processTabs.filter(
          (item) => this.processes.includes(item.process) && !this.editing,
        );
      }
    });
    this.subscriptions.push(processesSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnChanges(changes) {
    const {
      deliveryMethod: { currentValue },
    } = changes;
    this.deliveryMethod = currentValue;
    this.changeSlideByProcess(currentValue);
  }

  handleUpdateRegion(address) {
    this.updateRegion.emit(address);
  }

  changeSlideByProcess(processType) {
    if (processType === 'w2d') {
      this.goToSlide(1);
    }

    if (processType === 'w2p') {
      this.goToSlide(0);
    }

    if (processType === 'w2pPlus') {
      this.goToSlide(0);
    }
  }

  sliderClass(index) {
    return index === this.current
      ? 'stock-slider__header-item stock-slider__header-item--active'
      : 'stock-slider__header-item';
  }

  goToSlide(slideNumber) {
    this.current = slideNumber;
    this.setDeliveryMethod();
  }

  setDeliveryMethod() {
    const name = this.deliveryMethod;
    this.deliveryMethodForm.removeControl(this.deliveryMethod);

    if (!!this.deliveryMethodForm.get(name)) {
      this.deliveryMethodForm.setControl(name, new UntypedFormControl(null));
    } else {
      this.deliveryMethodForm.addControl(name, new UntypedFormControl(null));
    }

    if (!this.editing) {
      this.deliveryMethod =
        this.processTabs && this.processTabs[this.current]
          ? this.processTabs[this.current].process
          : undefined;
    }

    this.deliveryMethodForm.patchValue({
      deliveryMethod: this.deliveryMethod,
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val && Object.keys(val).length) {
      if (val && val.deliveryMethod) {
        this.changeSlideByProcess(val.deliveryMethod);
      }
      this.deliveryMethodForm.patchValue({ ...val });
    }
  }

  registerOnChange(fn: any): void {
    this.deliveryMethodForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.deliveryMethodForm.disable();
    } else {
      this.deliveryMethodForm.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.deliveryMethodForm.valid
      ? null
      : {
          valid: false,
          message: 'The delivery method you picked is not filled in correctly',
        };
  }
}

<section class="warehouse" [formGroup]="warehouseForm">
  <h4 class="warehouse__heading heading heading__lined">
    <span>{{ heading ? heading : 'Collect parcel from' }}</span>
  </h4>
  <div class="warehouse__information">
    <app-warehouse-details
      [warehouses]="warehouses"
      [cb]="changeWarehouse"
      [selected]="warehouseForm.value.warehouse"
      [isReturn]="heading === 'Return to'"
    >
    </app-warehouse-details>

    <input
      type="hidden"
      [value]="warehouseForm.value.warehouse"
      formControlName="warehouse"
      name="warehouse"
    />
  </div>
</section>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContainerHeading } from './components/container-heading/container-heading';

@NgModule({
    declarations: [ContainerHeading],
    imports: [CommonModule],
    providers: [],
    exports: [ContainerHeading]
})
export class ContainerHeadingModule {}

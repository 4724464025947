import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { InitialState } from '../../../reducers/authentication';
import { resetPasswordRequest } from '../../../actions/authentication';

@Component({
  selector: 'app-reset-request-form',
  templateUrl: './reset-request-form.html',
  styleUrls: ['./reset-request-form.scss'],
})
export class ResetRequestFormComponent {
  loading = false;

  submitted = false;

  form: UntypedFormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(private formBuilder: UntypedFormBuilder, private store: Store<InitialState>) {}

  onSubmit() {
    this.loading = true;

    if (this.form.invalid) {
      this.loading = false;
      return;
    }

    if (this.form.valid) {
      const { email } = this.form.value;
      this.store.dispatch(resetPasswordRequest({ email }));
    }
  }
}

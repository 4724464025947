import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { reducers } from '../reducers';
import { environment as env } from 'src/environments/environment';

export const DevtoolsModule = StoreDevtoolsModule.instrument({
  maxAge: 25,
  logOnly: !env.production,
});

const stateObjectsToClear = ['credits'];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['core', 'payments'],
    rehydrate: true,
    restoreDates: true,
  })(reducer);
}

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === 'GLOBAL/LOGOUT') {
      stateObjectsToClear.forEach((stateObj) => {
        state[stateObj] = undefined;
      });
    }

    return reducer(state, action);
  };
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, clearState];

export const RouterStoreModule = StoreRouterConnectingModule.forRoot();

export const Store = StoreModule.forRoot(reducers, { metaReducers });

<section class="auth-form">
  <div class="auth-form__logo-container">
    <img class="auth-form__logo-container-img" src="assets/images/logo.png" />
  </div>
  <form class="auth-form__form" validate (ngSubmit)="onSubmit()" [formGroup]="form">
    <h2 class="auth-form__title">Forgot your password?</h2>
    <p class="auth-form__text">
      Enter your email to send a password reset link and reset your password.
    </p>
    <div class="auth-form__password">
      <pargo-input class="auth-form__input" label="Email">
        <input class="input" type="email" formControlName="email" required />
      </pargo-input>
    </div>

    <pargo-button
      class="auth-form__button"
      type="submit"
      [loading]="loading"
      label="Send reset link"
    >
    </pargo-button>
  </form>
</section>

import { Component, Input, Output, HostListener, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'pargo-dropdown',
  templateUrl: './dropdown.html',
  styleUrls: ['./dropdown.scss'],
})
export class Dropdown {
  @Input() open?: boolean = false;
  @Input() label?: string = 'Select a value';
  @Input() value?: { label: string; value: string | number };
  @Input() items?: { label: string; value: string | number }[];
  @Input() small?: boolean = false;

  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  get options() {
    if (this.value) {
      return (
        this.items && [...this.items].filter((item) => item && item.value !== this.value.value)
      );
    } else {
      return [...this.items];
    }
  }

  setDropdownValue(value: string) {
    this.value = this.items && [...this.items].find((item) => item && item.value === value);
    this.change.emit(this.value || null);
    this.open = false;
  }

  toggleDropdown() {
    this.open = !this.open;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      this.open = true;
    } else {
      this.open = false;
    }

    // this.change.emit(this.value || null);
  }
}

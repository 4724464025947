import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { RouterLink } from './components/router-link/router-link';

@NgModule({
    declarations: [RouterLink],
    imports: [CommonModule, RouterModule, MatIconModule],
    providers: [],
    exports: [RouterLink]
})
export class RouterLinkModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';

import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-volumetric-weight-dialog',
  templateUrl: './volumetric-weight-dialog.html',
  styleUrls: ['./volumetric-weight-dialog.scss'],
})
export class VolumetricWeightDialogComponent implements OnInit, OnDestroy {
  volumetricWeightForm = this.formBuilder.group({
    width: ['', [Validators.required]],
    height: ['', [Validators.required]],
    length: ['', [Validators.required]],
    actual: ['', [Validators.required]],
  });

  maxWeight: number = 15;
  weightDivider: number = 5000;
  weight: number;
  submitting: boolean = false;
  overSized: boolean = false;
  result: any;

  subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<VolumetricWeightDialogComponent>,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    const weightFormSubscription = this.volumetricWeightForm.statusChanges.subscribe(() => {
      this.calculateResult();
    });
    this.subscriptions.push(weightFormSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  get isDisabled() {
    if (this.volumetricWeightForm.invalid || this.overSized === true) {
      return true;
    }

    return false;
  }

  calculateResult() {
    const values = this.volumetricWeightForm.value;

    if (values.width && values.height) {
      const { width, height, length, actual } = values;

      if (width && height && length) {
        const volumetricWeight = Math.round((width * height * length) / this.weightDivider);

        this.weight = actual > volumetricWeight ? actual : volumetricWeight;

        this.overSized = this.weight > this.maxWeight;

        this.result = {
          title: `${this.weight}kg volumetric weight`,
          description: this.overSized
            ? 'Unfortunately, the volumetric weight is too high.'
            : 'On confirming the correct weight will be selected for you.',
          icon: this.overSized ? 'warning' : 'check',
          status: this.overSized ? 'warning' : 'success',
        };
      }
    }
  }

  handleClear() {
    this.volumetricWeightForm.reset();
    this.result = undefined;
  }

  handleConfirm() {
    this.submitting = true;

    if (this.volumetricWeightForm.valid) {
      const values = this.volumetricWeightForm.value;
      const { width, height, length } = values;
      if (width && height && length && this.weight) {
        this.dialogRef.close({ weight: this.weight });
      }
    }
  }

  handleClose() {
    this.volumetricWeightForm.reset();
    this.dialogRef.close();
  }
}

<div class="data-table__search" *ngIf="currentPage && currentPage.length > 0 || searchValue">
  <div class="data-table__search-container">
    <pargo-input class="data-table__search-wrapper" [label]="searchPlaceholder">
      <input
        #searchInput
        class="input__text data-table__search-input"
        value="{{searchValue}}"
        (keydown.enter)="handleSearchByEnterKey($event)"
      />
    </pargo-input>
    <mat-icon class="data-table__search-icon" (click)="handleSearchIconClick(searchInput.value)">
      {{searchValue ? 'close' : 'search'}}
    </mat-icon>
  </div>
  <pargo-dropdown
    class="data-table__dropdown"
    label="Show"
    [value]="currentChunkOption"
    [items]="chunkOptions"
    (change)="changeChunkSize($event)"
  ></pargo-dropdown>
</div>

<div *ngIf="dataMeetingCriteria.length > 0; else noResults">
  <table class="data-table">
    <thead class="data-table__head">
      <tr class="data-table__head-row">
        <th
          class="data-table__head-cell"
          *ngFor="let header of headers; let i = index"
          [ngStyle]="{'width': columnWidths ? columnWidths[i] + '%' : '100%' }"
          (click)="isSortableColumn(i) ? sortItems(header) : null"
        >
          {{header}}

          <mat-icon class="data-table__head-icon" *ngIf="isSortableColumn(i)">unfold_more</mat-icon>
        </th>
      </tr>
    </thead>
    <tbody class="data-table__body" *ngIf="!currentPage">
      <tr class="data-table__body-row">
        <td class="data-table__body-cell data-table__body-cell--no-info">
          No information found to be displayed
        </td>
      </tr>
    </tbody>
    <tbody class="data-table__body" *ngIf="currentPage && currentPage.length > 0">
      <tr class="data-table__body-row" *ngFor="let row of currentPage; let i = index">
        <td
          class="data-table__body-cell"
          *ngFor="let value of row; let j = index"
          [ngStyle]="{'width': columnWidths ? columnWidths[j] + '%' : '100%' }"
          [ngClass]="{'data-table__icon-edit' : resolveValue(value) === 'edit-icon'}"
          title="{{ resolveValue(value) === 'warehouse-reference' ? value[0] : '' }}"
        >
          <div>
            {{ resolveValue(value) === false ? value : '' }}

            <span *ngIf="resolveValue(value) === 'warehouse-reference'">
              {{ formatWarehouseReference(value[0])}}
            </span>

            <pargo-cta
              *ngIf="resolveValue(value) === 'cta'"
              type="link"
              icon="download-pargo"
              [routerLink]="value[0]"
            >
            </pargo-cta>

            <mat-icon
              class="data-table__icon-status"
              *ngIf="resolveValue(value) === 'icon'"
              [svgIcon]="value[0]"
            ></mat-icon>

            <mat-icon
              class="data-table__icon-edit"
              *ngIf="resolveValue(value) === 'edit-icon'"
              [svgIcon]="value[0]"
              (click)="selectWarehouseToEdit(row[0][0])"
            ></mat-icon>

            <input
              *ngIf="isWarehouseSelection(value)"
              type="radio"
              [checked]="value[0]"
              (click)="warehouseSelection(value[1], value[0], row[0])"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <footer class="data-table__footer" *ngIf="currentPage && currentPage.length > 0">
    <div class="data-table__footer-filler"></div>
    <div>
      <ul class="data-table__pagination" *ngIf="chunks && chunks.length > 1">
        <li
          class="data-table__pagination-item data-table__pagination-item--previous"
          (click)="setPagination(pagination.current - 1)"
        >
          <span *ngIf="pagination.current"> Previous </span>
        </li>
        <li
          class="data-table__pagination-item data-table__pagination-item--current"
          (click)="setPagination(pagination.current)"
        >
          {{current + 1}}
        </li>
        <li
          class="data-table__pagination-item data-table__pagination-item--next"
          *ngIf="pagination.current < chunks.length -1"
          (click)="setPagination(pagination.current  + 1)"
        >
          <span> Next </span>
        </li>
      </ul>
    </div>
    <div class="data-table__amount-indicator">
      <span>{{ totalShowing }}</span> of <span>{{ dataMeetingCriteria.length }}</span>
    </div>
  </footer>
</div>
<ng-template #noResults>
  <div class="data-table__no-results">
    <img class="data-table__no-results__img" src="assets/images/auth/search-box.svg" />
    <h2 class="data-table__no-results__heading">No results found</h2>
    <p class="data-table__no-results__description">We can’t find anything matching your search</p>
  </div>
</ng-template>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerHeadingModule } from '../../typography/container-heading/container-heading.module';

import { ButtonModule } from '../../atoms/button/button.module';

import { CustomsIntro } from './components/customs-intro/customs-intro';

@NgModule({
    declarations: [CustomsIntro],
    imports: [CommonModule, MatIconModule, ContainerHeadingModule, ButtonModule],
    providers: [],
    exports: [CustomsIntro]
})
export class CustomsIntrosModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { RouterLinkModule } from '../router-link/router-link.module';

import { Cta } from './components/cta/cta';

@NgModule({
    declarations: [Cta],
    imports: [CommonModule, MatIconModule, RouterLinkModule],
    providers: [],
    exports: [Cta]
})
export class CtaModule {}

<section class="auth-form">
  <div class="auth-form__logo-container">
    <img class="auth-form__logo-container-img" src="assets/images/logo.png" />
  </div>
  <form
    *ngIf="requires_password; else auto_confirm"
    class="auth-form__form"
    validate
    (ngSubmit)="onSubmit()"
    [formGroup]="form"
  >
    <h2 class="auth-form__title">Confirm your account</h2>
    <p class="auth-form__text">Enter your new password and sign in straight away.</p>
    <div class="auth-form__password">
      <pargo-input class="auth-form__input" label="Password" formName="password" [form]="form">
        <input class="input" type="password" formControlName="password" autocomplete="off" />
      </pargo-input>
    </div>

    <div class="auth-form__password">
      <pargo-input
        class="auth-form__input"
        label="Re-enter your password"
        formName="repeatPassword"
        [form]="form"
      >
        <input class="input" type="password" formControlName="repeatPassword" autocomplete="off" />
      </pargo-input>
    </div>

    <pargo-button
      class="auth-form__button"
      type="submit"
      [loading]="loading"
      label="Confirm and Sign In"
    >
    </pargo-button>
  </form>

  <ng-template #auto_confirm>
    <div></div>
    <h2 class="auth-form__title auth-form__single-heading">Confirming your account...</h2>
  </ng-template>
</section>

<div class="walkthrough-explanation" cdkDrag *ngIf="(enabled$ | async) && (current$ | async)">
  <header class="walkthrough-explanation__header">
    <mat-icon class="walkthrough-explanation__close" (click)="closeWalkthrough()"> close </mat-icon>
  </header>

  <body class="walkthrough-explanation__body">
    <div class="walkthrough-explanation__content">
      <h3 class="walkthrough-explanation__heading">{{(current$ | async)?.title}}</h3>
      <p class="walkthrough-explanation__explanation" [innerHTML]="(current$ | async)?.text"></p>
    </div>
  </body>
  <footer class="walkthrough-explanation__footer">
    <button class="walkthrough-explanation__previous" type="button" (click)="previousStep()">
      previous
    </button>
    <button class="walkthrough-explanation__next" type="button" (click)="nextStep()">next</button>
  </footer>
</div>

<div class="walkthrough-steps">
  <div class="walkthrough-steps__steps" *ngFor="let step of steps">
    <app-walkthrough
      [page]="step.page"
      [text]="step.text"
      [title]="step.title"
      [id]="step.id"
      [attachedToElement]="step.attachedToElement"
      [current]="current"
      [enabled]="enabled"
    ></app-walkthrough>
  </div>
</div>

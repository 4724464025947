import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sign-up-header',
  templateUrl: './sign-up-header.component.html',
  styleUrls: ['./sign-up-header.component.scss'],
})
export class SignUpHeaderComponent {
  @Input() heading: string;
  @Input() subHeading: string;
  @Input() description: string;
}

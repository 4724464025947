import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DashboardBoxModule } from '../layout/dashboard-box/dashboard-box.module';
import { InformationTableModule } from '../molecules/table/information-table/information-table.module';
import { WarehouseDetailsComponent } from './warehouse-details';
import { ButtonModule } from '../atoms/button/button.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [WarehouseDetailsComponent],
    imports: [CommonModule, DashboardBoxModule, InformationTableModule, ButtonModule, MatIconModule],
    providers: [],
    exports: [WarehouseDetailsComponent]
})
export class WarehouseDetailsModule {}

import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { InitialState } from '../../../reducers/authentication';
import { confirmAccount } from '../../../actions/authentication';
import { FormValidationService } from '../../../../shared/services/form-validation.service';

@Component({
  selector: 'app-confirm-account-form',
  templateUrl: './confirm-account-form.html',
  styleUrls: ['./confirm-account-form.scss'],
})
export class ConfirmAccountFormComponent implements AfterViewInit {
  loading = false;

  submitted = false;

  email: string = undefined;

  confirm_code: string = undefined;

  form: UntypedFormGroup;

  requires_password: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<InitialState>,
    private activatedRoute: ActivatedRoute,
    private formValidationService: FormValidationService,
  ) {
    this.form = this.formBuilder.group({
      repeatPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.formValidationService.directPasswordMatch,
        ],
      ],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const { email, confirm_code, requires_password } = params;
      this.email = email;
      this.confirm_code = confirm_code;
      if (requires_password) {
        this.requires_password = requires_password === 'true';
        if (!this.requires_password) {
          this.store.dispatch(
            confirmAccount({
              confirm: {
                confirm_code: this.confirm_code,
                email: this.email,
              },
            }),
          );
        }
      }
    });
  }

  onSubmit() {
    this.loading = true;

    if (this.form.invalid) {
      this.loading = false;
      Object.keys(this.form.controls).forEach((field) => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }

    if (this.form.valid && this.confirm_code && this.email) {
      const { password, repeatPassword } = this.form.value;
      this.store.dispatch(
        confirmAccount({
          confirm: {
            confirm_code: this.confirm_code,
            email: this.email,
            password_confirmation: repeatPassword,
            password,
          },
        }),
      );
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { InputModule } from '../../atoms/input/input.module';

import { SearchService } from './services/search.service';
import { JsonSearchComponent } from './components/json-search/json-search';

@NgModule({
    declarations: [JsonSearchComponent],
    imports: [CommonModule, MatIconModule, InputModule],
    providers: [SearchService],
    exports: [JsonSearchComponent]
})
export class JsonSearchModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as core from '../../../core/selectors/core';
import { InitialState as CoreInitialState } from '../../../core/reducers/core';

import { createReducerKey, InitialState } from '../reducers/create';

export const createState = createFeatureSelector(createReducerKey);

export const selectSubmitting = createSelector(
  createState,
  (state: InitialState) => state.submitting,
);

export const selectOrder = createSelector(
  createState,
  core.selectCore,
  (state: InitialState, coreState: CoreInitialState) => {
    const warehouses: any = coreState.warehouses;
    const order: any = state.order;

    const warehouse: any =
      warehouses &&
      warehouses.find((warehouse: any) => {
        return (
          order &&
          order.from &&
          order.from.suburb &&
          order.from.city &&
          warehouse &&
          warehouse.suburb &&
          warehouse.city &&
          warehouse.suburb.toUpperCase() == order.from.suburb.toUpperCase() &&
          warehouse.city.toUpperCase() == order.from.city.toUpperCase()
        );
      });

    return {
      order: order ? order : undefined,
      warehouse: warehouse
        ? warehouse.reference
        : warehouses && warehouses[0]
        ? warehouses[0].reference
        : undefined,
    };
  },
);

export const selectProcesses = createSelector(
  core.selectCoreSettings,
  (coreSettings: CoreInitialState['settings']) => coreSettings.processes,
);

export const selectCredits = createSelector(
  core.selectCore,
  (coreState: CoreInitialState) => coreState.credits,
);

export const selectWarehouses = createSelector(
  core.selectCore,
  (coreState: CoreInitialState) => coreState.warehouses,
);

export const selectPayments = createSelector(
  core.selectCore,
  (coreState: CoreInitialState) => coreState.payments,
);

export const selectType = createSelector(createState, (state: InitialState) => state.type);

export const selectUpload = createSelector(createState, (state: InitialState) => state.upload);

export const selectRegion = createSelector(createState, (state: InitialState) => state.region);

export const selectUser = createSelector(
  core.selectProfile,
  (profile: CoreInitialState['profile']) => {
    return {
      name: profile?.user?.attributes.name,
      email: profile?.user?.attributes.email,
      avatar: profile?.user?.attributes.large,
      defaultMapToken: profile?.user?.defaultMapToken,
      defaultMapReturnToken: profile?.user?.defaultMapReturnToken,
    };
  },
);

export const selectHasCredits = createSelector(
  core.selectCore,
  (coreState: CoreInitialState) => coreState.settings.hasCredits,
);

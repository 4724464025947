import { WarehouseDefaultsData } from '@profile/models/profile';
import { Warehouse } from '@shared/models/warehouse';

export function getUuid() {
  return (<any>window).device.uuid || 'xxxx';
}

export function extractScanditNativeBarcode(scannerResults) {
  return scannerResults &&
    scannerResults.newlyRecognizedCodes &&
    scannerResults.newlyRecognizedCodes[0].data
    ? scannerResults.newlyRecognizedCodes[0].data
    : null;
}

export function extractScanditWebBarcode(scannerResults) {
  return scannerResults && scannerResults.barcodes && scannerResults.barcodes[0].data
    ? scannerResults.barcodes[0].data
    : null;
}

export function updateWarehousesData(
  warehouses: Object[],
  warehouseDefaultsData: WarehouseDefaultsData,
) {
  warehouses.forEach((warehouse: Warehouse) => {
    if (warehouseDefaultsData.primary) {
      if (warehouse.id === warehouseDefaultsData.id) {
        warehouse.primary = true;
      } else {
        warehouse.primary = false;
      }
    }
    if (warehouseDefaultsData.primary_return) {
      if (warehouse.id === warehouseDefaultsData.id) {
        warehouse.primary_return = true;
      } else {
        warehouse.primary_return = false;
      }
    }
  });
  return warehouses;
}

export function createProfileObject(
  related: {
    default_map_token: string;
    default_map_return_token: string;
    attributes: Object;
    returns_app_url: string;
    customer_driven_returns_enabled: boolean;
    profilePictureUrls: Object;
  },
  user: Object,
) {
  return {
    user: {
      ...user,
      defaultMapToken: related.default_map_token,
      defaultMapReturnToken: related.default_map_return_token,
    },
    supplier: {
      ...related.attributes,
      profilePictureUrls: related.profilePictureUrls,
      returnsAppUrl: related.returns_app_url,
      returnsEnabled: related.customer_driven_returns_enabled,
    },
  };
}

export function reOrderWarehouses(warehouses: any[]) {
  if (warehouses && warehouses.length) {
    return warehouses.reduce((acc, warehouse) => {
      if (warehouse.primary) {
        acc = [warehouse, ...acc];
      } else {
        acc = [...acc, warehouse];
      }
      return acc;
    }, []);
  } else {
    return [];
  }
}

import { environment as env } from '@env/environment';

export const AT_COLLECTION_POINT = 'at_collection_point';
export const COMPLETED = 'completed';
export const NOT_COMPLETED = 'not_completed';
export const CONFIRMED = 'confirmed';
export const PENDING = 'pending';
export const AT_COURIER = 'at_courier';

export const W2P = 'w2p';
export const W2D = 'w2d';
export const NP2P = 'np2p';
export const NP2P_CHILD = 'np2p_child';
export const NP2P_PARENT = 'np2p_parent';
export const P2W_RETURN = 'p2w_return';
export const W2P_NOT_COLLECTED = 'w2p_not_collected';
export const W2D_FOR_RETURN = 'w2d_for_return';
export const W2D_PLUS = 'w2d_plus';
export const D2W = 'd2w';
export const P2P = 'p2p';
export const P2D = 'p2d';
export const MAX_ORDERS_PER_PAGE = 100;

export const ORDER_STATUSES = {
  AT_COLLECTION_POINT,
  COMPLETED,
  NOT_COMPLETED,
  CONFIRMED,
  PENDING,
  AT_COURIER,
};

export const ORDER_STATUS_COLOR = {
  BLUE: '#6fcfeb',
  GREEN: '#21a355',
  LIME: '#80e069',
  ORANGE: '#ff983b',
  RED: '#d95151',
  YELLOW: '#fff200',
};

export const ORDER_TYPES = {
  W2P,
  W2D,
  NP2P,
  NP2P_CHILD,
  NP2P_PARENT,
  P2W_RETURN,
  W2P_NOT_COLLECTED,
  W2D_FOR_RETURN,
  D2W,
  W2D_PLUS,
  P2P,
  P2D,
};

export const REJECTABLE_ORDER_TYPES = [D2W, P2W_RETURN];
export const RETURNABLE_ORDER_TYPES = [W2D, W2P];

export const orderStatusCode = {
  completed: {
    label: 'Completed',
    icon: '',
    color: '#FFF200',
    statusCode: 'completed',
    value: 'completed',
  },
  not_completed: {
    label: 'Not Completed',
    icon: '',
    color: '#d95151',
    statusCode: 'not_completed',
    value: 'notCompleted',
  },
  confirmed: {
    label: 'Confirmed',
    icon: '',
    color: '#80e069',
    statusCode: 'confirmed',
    value: 'confirmed',
  },
  pending: {
    label: 'Pending',
    icon: '',
    color: '#ff983b',
    statusCode: 'pending',
    value: 'pending',
  },
  at_courier: {
    label: 'At courier',
    icon: '',
    color: '#6fcfeb',
    statusCode: 'at_courier',
    value: 'inTransit',
  },
  at_drop_off_point: {
    label: 'Returning',
    icon: '',
    color: '#ba7158',
    statusCode: 'at_drop_off_point',
    value: 'atDropOffPoint',
  },
  at_collection_point: {
    label: 'At Pargo point',
    icon: '',
    color: '#21a355',
    statusCode: 'at_collection_point',
    value: 'inStore',
  },
  cancelled: {
    label: 'Rejected',
    icon: '',
    color: '#f38181',
    statusCode: 'cancelled',
    value: 'cancelled',
  },
};

export const orderType = {
  w2p: 'Pickup point',
  w2d: 'Home delivery',
  w2d_plus: 'Home delivery plus',
  np2p: 'Instant collection',
  np2p_child: 'Instant collection',
  np2p_parent: 'Instant collection',
  p2w_return: 'Pickup Point Return',
  d2w: 'Return from Home',
  p2d: 'Home Delivery',
  p2p: 'Pickup Point',
};

export const creditsMailTo: string = env.pargoMail;
export const creditsMailSubject: string = JSON.stringify('Request new credits for My Pargo');
export const creditsMailBody: string =
  'I would like to request 100 credits to be added to my, My Pargo account.';

export const ORDER_REGION = {
  LOCAL: 'local',
  MAIN: 'main',
  REGIONAL: 'regional',
  UNKNOWN: 'unknown',
};

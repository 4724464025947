export const pointInRect = (vpRect, y, x) =>
  vpRect.left <= x && vpRect.right >= x && vpRect.top <= y && vpRect.bottom >= y;

export const rectOverlap = (vpRect, el) =>
  pointInRect(vpRect, el.top, el.left) ||
  pointInRect(vpRect, el.bottom, el.left) ||
  pointInRect(vpRect, el.bottom, el.right) ||
  pointInRect(vpRect, el.top, el.right);

export const isElementVisibleInViewport = (el) => {
  const vpRect = {
    top: document.documentElement.clientTop || 0,
    left: document.documentElement.clientLeft || 0,
    bottom: window.innerHeight || document.documentElement.clientHeight,
    right: window.innerWidth || document.documentElement.clientWidth,
  };
  return rectOverlap(vpRect, el.getBoundingClientRect());
};

export const scrollIntoViewIfNeeded = (el) => {
  if (el && !isElementVisibleInViewport(el)) {
    el.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'nearest',
    });
  }
};

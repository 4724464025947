import * as XLSX from 'xlsx';

import { Injectable } from '@angular/core';
import { DATE_FORMAT_SHORT, formatDate } from '../../../helpers/date';

export const prepaidSupplierWeightValidation = {
  message: 'Weight *',
  required: true,
  regex: /(5)|(10)|(15)/,
  options: [
    { label: '5kg', value: '5' },
    { label: '10kg', value: '10' },
    { label: '15kg', value: '15' },
  ],
};

export const monthlySupplierWeightValidation = {
  message: 'Weight *',
  required: false,
  regex: /^\d{1,4}\.\d{0,2}$|^\d{1,4}$|^$/,
};

export const validation = {
  weight: {},
  'warehouse code': {
    message: 'Warehouse *',
    required: true,
    regex: /[A-Z-a-z-0-9]*/,
  },
  area: {
    message: 'Must match *',
    required: true,
    search: {
      label: 'Area',
      indexes: ['Suburb', 'Postal Code', 'City'],
      endpoints: [`${window.location.origin}/assets/data/route-guide.json`],
      fields: ['Suburb', 'Postal Code', 'City'],
    },
  },
};

export const excel = {
  w2p: {
    fields: [
      {
        name: 'warehouse code',
        message: 'The warehouse code is required',
        required: true,
        regex: /[A-Z-a-z-0-9]*/,
      },
      {
        name: 'pickup point code',
        message: 'A valid pickup point code is required',
        required: false,
        regex: /^(|pup[1-9][0-9]{0,3}|PUP0|PUP1)$/,
      },
      {
        name: 'weight',
        message: 'Weight is required (5, 10 or 15)',
        required: true,
        regex: /([5|10|15])/,
      },
      {
        name: 'external reference',
        message: 'Required, max 40 characters',
        required: true,
        regex: /^.{1,40}$/,
      },
      { name: 'value', message: 'Value is required', required: true, regex: /[0-9]*/ },
      {
        name: 'description',
        message: 'Description is required',
        required: true,
        regex: /[A-Z-a-z]*/,
      },
      {
        name: 'first name',
        message: 'First name is required',
        required: true,
        regex: /[A-Z-a-z]*/,
      },
      { name: 'last name', message: 'Last name is required', required: true, regex: /[A-Z-a-z]*/ },
      {
        name: 'email',
        message: 'A valid email is required',
        required: true,
        regex:
          /(?:[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      },
      {
        name: 'phone numbers',
        message: 'Phone number is required and must be a number',
        required: true,
      },
      { name: 'address1', message: 'Address is required', required: false },
      { name: 'address2', message: 'Address is required', required: false },
      { name: 'suburb', message: 'Suburb is required', required: false },
      { name: 'postalcode', message: 'Postalcode is required', required: false },
      { name: 'city', message: 'City is required', required: false },
      {
        name: 'country',
        message: 'Country is required and must be a country code (ZA)',
        required: false,
      },
    ],
    legenda: [
      ['Legenda', '* is required'],
      [
        'Warehouse code *',
        "In your profile you can find this reference. The standard is ‘1' if you have one collection address",
      ],
      ['Pickup point code *', 'This is the pickup point ID which start with ‘pup’ (eg, pup123)'],
      ['Weight *', "Either ‘5', ‘10’, ‘15’ kg is the value which you can use"],
      ['External reference *', ' Your internal reference towards the order'],
      ['Value *', 'Order value'],
      ['Description *', 'Order description: (eg, ‘Cloths’, ‘Books’'],
      ['First name *', 'First name of the customer'],
      ['Last name *', 'Last name of the customer'],
      ['Email *', 'Email of the customer'],
      ['Phone numbers *', 'Phone number of the customer'],
      ['Address1', 'Home address of the customer'],
      ['Address2', 'Extra information about the customers address'],
      ['Suburb', 'Suburb of the customers home address'],
      ['Postalcode', 'Postal code of the customer'],
      ['City', 'City of the customer'],
      ['Country', "￼Country - 'ZA’ for South Africa (country code)"],
    ],
  },
  w2d: {
    fields: [
      {
        name: 'warehouse code',
        message: 'The warehouse code is required',
        required: true,
        regex: /[A-Z-a-z-0-9]*/,
      },
      {
        name: 'weight',
        message: 'Weight is required (5, 10 or 15)',
        required: true,
        regex: /([5|10|15])/,
      },
      {
        name: 'external reference',
        message: 'Required, max 40 characters',
        required: true,
        regex: /^.{1,40}$/,
      },
      { name: 'value', message: 'Value is required', required: true, regex: /[0-9]*/ },
      {
        name: 'description',
        message: 'Description is required',
        required: true,
        regex: /[A-Z-a-z]*/,
      },
      {
        name: 'first name',
        message: 'First name is required',
        required: true,
        regex: /[A-Z-a-z]*/,
      },
      { name: 'last name', message: 'Last name is required', required: true, regex: /[A-Z-a-z]*/ },
      { name: 'email', message: 'A valid email is required', required: false },
      {
        name: 'phone numbers',
        message: 'Phone number is required and must be a number',
        required: true,
      },
      { name: 'address1', message: 'Address is required', required: true },
      { name: 'address2', message: 'Address is required', required: false },
      { name: 'suburb', message: 'Suburb is required', required: true },
      { name: 'postalcode', message: 'Postalcode is required', required: true },
      { name: 'city', message: 'City is required', required: true },
      {
        name: 'country',
        message: 'Country is required and must be a country code (ZA)',
        required: true,
      },
    ],
    legenda: [
      ['Legenda', '* is required'],
      [
        'Warehouse code *',
        "In your profile you can find this reference. The standard is ‘1' if you have one collection address",
      ],
      ['Weight *', "Either ‘5', ‘10’, ‘15’ kg is the value which you can use"],
      ['External reference *', ' Your internal reference towards the order'],
      ['Value *', 'Order value'],
      ['Description *', 'Order description: (eg, ‘Cloths’, ‘Books’'],
      ['First name *', 'First name of the customer'],
      ['Last name *', 'Last name of the customer'],
      ['Email *', 'Email of the customer'],
      ['Phone numbers *', 'Phone number of the customer'],
      ['Address1 *', 'Home address of the customer'],
      ['Address2 ', 'Extra information about the customers address'],
      ['Suburb *', 'Suburb of the customers home address'],
      ['Postalcode *', 'Postal code of the customer'],
      ['City *', 'City of the customer'],
      ['Country *', "￼Country - 'ZA’ for South Africa (country code)"],
    ],
  },
  p2w_return: {
    fields: [
      {
        name: 'warehouse code',
        message: 'The warehouse code is required',
        required: true,
        regex: /[A-Z-a-z-0-9]*/,
      },
      {
        name: 'pickup point code',
        message: 'A valid pickup point code is required',
        required: true,
        regex: /^(pup)([1-9]{1})([0-9]{1,3})|(PUP0|PUP1)/,
      },
      {
        name: 'weight',
        message: 'Weight is required (5, 10 or 15)',
        required: true,
        regex: /([5|10|15])/,
      },
      {
        name: 'external reference',
        message: 'Required, max 40 characters',
        required: true,
        regex: /^.{1,40}$/,
      },
      { name: 'value', message: 'Value is required', required: true, regex: /[0-9]*/ },
      {
        name: 'description',
        message: 'Description is required',
        required: true,
        regex: /[A-Z-a-z]*/,
      },
      {
        name: 'first name',
        message: 'First name is required',
        required: true,
        regex: /[A-Z-a-z]*/,
      },
      { name: 'last name', message: 'Last name is required', required: true, regex: /[A-Z-a-z]*/ },
      {
        name: 'email',
        message: 'A valid email is required',
        required: true,
        regex:
          /(?:[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      },
      {
        name: 'phone numbers',
        message: 'Phone number is required and must a number',
        required: true,
      },
    ],
    legenda: [
      ['Legenda', '* is required'],
      [
        'Warehouse code *',
        "In your profile you can find this refernce. The standard is ‘1' if you have one collection address",
      ],
      ['Pickup point code *', 'This is the pickup point ID which start with ‘pup’ (eg, pup123)'],
      ['Weight *', "Either ‘5', ‘10’, ‘15’ kg is the value which you can use"],
      ['External reference *', ' Your internal reference towards the order'],
      ['Value *', 'Order value'],
      ['Description *', 'Order description: (eg, ‘Cloths’, ‘Books’'],
      ['First name *', 'First name of the customer'],
      ['Last name *', 'Last name of the customer'],
      ['Email *', 'Email of the customer'],
      ['Phone numbers *', 'Phone number of the customer'],
    ],
  },
};

@Injectable()
export class ExcelService {
  private file: any;

  private workbook: any;

  private parsedFile: any = [];

  private excelData: any = excel;

  private sheetNames = {
    orders: 'Orders',
    legenda: 'Legenda',
  };

  constructor() {}

  private async processFile() {
    if (this.workbook) {
      const sheet = this.workbook.Sheets[this.sheetNames.orders];
      const processedSheet = await XLSX.utils.sheet_to_json(sheet, {
        blankrows: false,
        defval: '',
      });
      this.parsedFile = [this.parsedFile, ...processedSheet];

      return true;
    }
    return false;
  }

  private async loadFile(event) {
    const data = event.currentTarget.result;
    this.workbook = await XLSX.read(data, {
      type: 'binary',
    });
    await this.processFile();
    return true;
  }

  public async parse(file) {
    this.file = file;
    const result = await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        await this.loadFile(event);
        resolve(this.parsedFile);
      };

      reader.onerror = reject;

      reader.readAsBinaryString(file);
    });

    return result;
  }

  public async generate(type) {
    const data = this.excelData[type];

    if (data) {
      const fields = data.fields.map((field) => field.name);

      const orders: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([fields]);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, orders, this.sheetNames.orders);

      const legenda: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data.legenda);
      XLSX.utils.book_append_sheet(wb, legenda, this.sheetNames.legenda);

      const today = new Date();

      XLSX.writeFile(wb, `myPargo-upload-${formatDate(today, DATE_FORMAT_SHORT)}.xlsx`, {
        type: 'file',
      });
    }
  }

  public reParse() {
    if (this.file) {
      this.parse(this.file);
    }
  }

  public clear() {
    this.file = undefined;
    this.parsedFile = [];
    this.workbook = undefined;
  }

  public get data() {
    return this.parsedFile;
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pargo-container-heading',
  templateUrl: './container-heading.html',
  styleUrls: ['./container-heading.scss'],
})
export class ContainerHeading {
  @Input() heading: string;
  @Input() subtitle?: string;
  @Input() subtitle2?: string;
}

import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ModalButton } from './components/modal-button/modal-button';

@NgModule({
    declarations: [ModalButton],
    imports: [CommonModule, MatIconModule, MatDialogModule],
    providers: [],
    exports: [ModalButton]
})
export class ModalButtonModule {}

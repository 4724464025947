import { createFeatureSelector, createSelector } from '@ngrx/store';

import { notificationsReducerKey, InitialState } from '../reducers/notifications';

export const notificationState = createFeatureSelector(notificationsReducerKey);

export const selectNotifications = createSelector(
  notificationState,
  (state: InitialState) => state.notifications,
);

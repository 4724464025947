<ul class="faq-list" *ngIf="items">
  <li class="faq-list__item" *ngFor="let item of items; let id = index">
    <app-information-pill
      (toggle)="handleClick($event)"
      [heading]="item.heading"
      [information]="item.information"
      [text]="item.text"
      [id]="id + 1"
      [open]="id + 1 === current"
    >
    </app-information-pill>
  </li>
</ul>

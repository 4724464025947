import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ORDER_ICONS } from '../../../../../../icons';
import { CustomIcon } from '../../../../../../core/models/icons';

@Component({
  selector: 'app-return-form-summary',
  templateUrl: './return-summary.html',
  styleUrls: ['./return-summary.scss'],
})
export class ReturnSummaryComponent {
  @Input() formValues: any;

  @Input() amount: object;

  open: boolean = true;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    ORDER_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  toggle() {
    this.open = !this.open;
  }

  get from() {
    if (
      this.formValues.returnMethod &&
      this.formValues.returnMethod.returnMethod &&
      this.formValues.returnMethod[this.formValues.returnMethod.returnMethod]
    ) {
      const returnMethod = this.formValues.returnMethod.returnMethod;
      const values = this.formValues.returnMethod[this.formValues.returnMethod.returnMethod];
      switch (returnMethod) {
        case 'p2w_return':
          return values.pickupPointName;
        case 'd2w':
          return `${values.addressOne ? values.addressOne : ''} ${
            values.addressTwo ? values.addressTwo : ''
          } ${values.postalCode ? values.postalCode : ''} ${values.suburb ? values.suburb : ''} ${
            values.city ? values.city : ''
          }`;
      }
    }
  }
}

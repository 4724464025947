import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { DeliverySummaryComponent } from './components/delivery-summary/delivery-summary';

@NgModule({
  declarations: [DeliverySummaryComponent],
  imports: [CommonModule, MatIconModule],
  providers: [],
  exports: [DeliverySummaryComponent],
})
export class DeliverySummaryModule {}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { headers } from '@orders/components/order-validation/constants/data-table';
import uuid from 'uuid/v4';
import { environment } from '../../../../environments/environment';
import { AnalyticsEvent } from '../../models/analyticsEvent';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  eventAgent: string;
  eventHost: string;
  sessionID: string;
  appName: string = 'mypargo';
  userIp: string;
  supplier: string;
  eventLocation: {
    lat: number;
    lon: number;
  } = {
    lat: null,
    lon: null,
  };

  headers = {
    'Content-Type': 'application/json',
  };

  constructor(protected http: HttpClient, protected localStorageService: LocalStorageService) {}

  init() {
    this.eventAgent = navigator.userAgent;
    this.eventHost = window.location.hostname;
    if (!localStorage.getItem('sessionID')) {
      this.sessionID = uuid();
      localStorage.setItem('sessionID', this.sessionID);
    } else {
      this.sessionID = localStorage.getItem('sessionID');
    }
    this.getUserLocation();
    this.getUserIp();
    this.getSupplierRef();
  }

  async logEvent(event: AnalyticsEvent) {
    if (this.eventLocation.lat === null) {
      await this.getUserLocation();
    }
    if (!this.userIp) {
      await this.getUserIp();
    }
    if (!this.supplier) {
      this.getSupplierRef();
    }
    this.sendEvent(event);
  }

  sendEvent(event: AnalyticsEvent): void {
    this.http
      .post(
        environment.analyticsApiUrl,
        {
          session: this.sessionID,
          app: this.appName,
          ...this.createAnalyticsEvent(event),
        },
        { headers: new HttpHeaders(headers) },
      )
      .toPromise()
      .then(() => {});
  }

  createAnalyticsEvent(event: AnalyticsEvent): object {
    return {
      event: {
        agent: this.eventAgent,
        host: this.eventHost,
        data: {
          location: this.eventLocation,
          supplier: this.supplier,
        },
        journey: event.journey,
        event_type: event.event_type,
        name: event.name,
        ref_data: {
          journey_status: event.journey_status,
          page_num: event.page_num,
          event_num: event.event_num,
          user_ip: this.userIp,
        },
      },
    };
  }

  async getUserLocation(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.eventLocation.lon = position.coords.longitude;
            this.eventLocation.lat = position.coords.latitude;
            resolve();
          },
          () => {
            this.eventLocation.lon = 0;
            this.eventLocation.lat = 0;
            resolve();
          },
        );
      } else {
        this.eventLocation.lon = 0;
        this.eventLocation.lat = 0;
        resolve();
      }
    });
  }

  async getUserIp(): Promise<void> {
    try {
      const response = await this.http.get('https://api.ipify.org/?format=json').toPromise();
      this.userIp = response['ip'];
    } catch (error) {
      this.userIp = '';
    }
  }

  async getSupplierRef() {
    this.supplier = this.localStorageService.getItem('core')?.profile?.supplier?.reference || '';
  }
}

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { InitialState } from '../../../reducers/authentication';
import { login } from '../../../actions/authentication';
import { loginSubmitted } from '../../../selectors/authentication';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.html',
  styleUrls: ['./login-form.scss'],
})
export class LoginFormComponent {
  loading = false;

  loginSubmitted$: Observable<boolean> = this.store.select(loginSubmitted);

  returnUrl: string;

  user;

  matcher: string;

  subscriptions: Subscription[] = [];

  form: UntypedFormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    remember: [''],
  });

  constructor(private formBuilder: UntypedFormBuilder, private store: Store<InitialState>) {}

  onSubmit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((field) => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }

    const { email, password, remember } = this.form.controls;
    const credentials = {
      username: email.value,
      password: password.value,
      remember: remember.value,
    };

    this.store.dispatch(login({ login: credentials }));
  }
}

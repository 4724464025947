import { CustomIcon } from '../models/icon';

export const DownloadIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Download.svg',
  name: 'download-pargo',
};

export const PaidIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Paid.svg',
  name: 'paid-pargo',
};

export const OutStandingIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Outstanding.svg',
  name: 'outstanding-pargo',
};

export const PendingIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Pending.svg',
  name: 'pending-pargo',
};

export const EditIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Edit.svg',
  name: 'edit-pargo',
};

export const TABLE_ICONS: CustomIcon[] = [
  DownloadIcon,
  PaidIcon,
  OutStandingIcon,
  PendingIcon,
  EditIcon,
];

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { NotificationsRoutingModule } from './notifications-routing.module';

import { NotificationsComponent } from './components/notifications/notifications';
import { NotificationComponent } from './components/notification/notification';

import NotificationsReducer, { notificationsReducerKey } from './reducers/notifications';

@NgModule({
  declarations: [NotificationsComponent, NotificationComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,

    NotificationsRoutingModule,
    StoreModule.forFeature(notificationsReducerKey, NotificationsReducer),
  ],
  exports: [NotificationsComponent, NotificationComponent],
})
export class NotificationsModule {
  constructor() {}
}

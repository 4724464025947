<a [class]="classes" [href]="href" *ngIf="!isRouterLink" [target]="externalLinkTarget">
  <ng-container *ngTemplateOutlet="linkTemplateOutlet"></ng-container>
</a>
<a
  [class]="classes"
  [routerLinkActive]="routerLinkActive ? routerLinkActive : [] "
  (click)="handleInternalRoute()"
  *ngIf="isRouterLink"
>
  <ng-container *ngTemplateOutlet="linkTemplateOutlet"></ng-container>
</a>

<ng-template #linkTemplateOutlet>
  <ng-content></ng-content>
</ng-template>

<section class="navigation-flyout">
  <div class="navigation-flyout__content">
    <ul class="navigation-flyout__list">
      <li class="navigation-flyout__item" *ngFor="let item of items">
        <pargo-router-link
          class="navigation-flyout__link"
          [routerLink]="item.url"
          [queryParams]="item.state ? item.state : null"
          *ngIf="item.url"
        >
          <span class="navigation-flyout__title">{{item.title}}</span>
          <p class="navigation-flyout__description" *ngIf="item.description">
            {{item.description}}
          </p>
        </pargo-router-link>

        <div class="navigation-flyout__link" (click)="item.fn()" *ngIf="item.fn">
          <span class="navigation-flyout__title">{{item.title}}</span>
          <p class="navigation-flyout__description" *ngIf="item.description">
            {{item.description}}
          </p>
        </div>
      </li>
    </ul>
    <pargo-router-link class="navigation-flyout__all" *ngIf="all" [routerLink]="all.url">
      {{all.title}}
    </pargo-router-link>
  </div>
</section>

<div class="info-dialog">
  <div class="info-dialog__close-button-container">
    <span (click)="close('')" class="info-dialog__close-button">x</span>
  </div>
  <div class="info-dialog__logo-container">
    <img class="info-dialog__logo" [src]="imgSrc" />
  </div>
  <h2 class="info-dialog__heading">{{ heading }}</h2>
  <p class="info-dialog__text" [innerHTML]="text"></p>

  <div class="info-dialog__button-container">
    <pargo-button (click)="close(primaryBtnLabel)" [label]="primaryBtnLabel"></pargo-button>
    <div class="info-dialog__secondary-button-container">
      <pargo-button
        *ngIf="secondaryBtnLabel"
        (click)="close(secondaryBtnLabel)"
        [label]="secondaryBtnLabel"
        secondary="true"
      ></pargo-button>
    </div>
  </div>
</div>

import { createAction, props } from '@ngrx/store';

export const INIT_APPLICATION = 'GLOBAL/INIT_APPLICATION';
export const ADD_NOTIFICATION = 'GLOBAL/ADD_NOTIFICATION';
export const REFRESH_LOGIN = 'GLOBAL/REFRESH_LOGIN';
export const LOGOUT = 'GLOBAL/LOGOUT';
export const REFRESH_TOKEN_SUCCESS = 'GLOBAL/REFRESH_TOKEN_SUCCESS';
export const LOGIN_SUCCESS = 'GLOBAL/LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'GLOBAL/LOGOUT_SUCCESS';

export const addNotification = createAction(ADD_NOTIFICATION, props<{ notification }>());

export const refreshLogin = createAction(REFRESH_LOGIN);

export const logout = createAction(LOGOUT);

export const loginSuccess = createAction(LOGIN_SUCCESS);

export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const actions = {
  addNotification,
  refreshLogin,
  loginSuccess,
  logoutSuccess,
  logout,
};

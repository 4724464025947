import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { RouterLinkModule } from '../../atoms/router-link/router-link.module';
import { NavigationFlyoutModule } from '../../molecules/navigation-flyout/navigation-flyout.module';
import { CreditsAmountModule } from '../../molecules/credits-amount/credits-amount.module';

import { NavigationComponent } from './components/navigation/navigation';
import { WalkthroughModule } from '../../../../walkthrough/index';

@NgModule({
    declarations: [NavigationComponent],
    imports: [
        CommonModule,
        MatIconModule,
        RouterLinkModule,
        NavigationFlyoutModule,
        CreditsAmountModule,
        WalkthroughModule,
    ],
    providers: [],
    exports: [NavigationComponent]
})
export class NavigationModule {}

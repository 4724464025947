import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CreateRootComponent } from './create';

import { CreatePageComponent } from './pages/create/create.component';
import { ReturnPageComponent } from './pages/return/return.component';
import { UploadPageComponent } from './pages/upload/upload';

import CreateReducer, { createReducerKey } from './reducers/create';
import { CreateEffects } from './effects/create.effects';
import { CreateService } from './services/create.service';
import { CreateRoutingModule } from './create-routing.module';
import { ExcelService } from './services/excel.service';

import { OrderReceiverHomeComponent } from './components/forms/order-receiver-home/order-receiver-home';
import { ReturnFromHomeComponent } from './components/forms/return-from-home/return-from-home';
import { PickupPointSelectionComponent } from './components/forms/pickup-point-selection/pickup-point-selection';
import { VolumetricWeightDialogComponent } from './components/dialogs/volumetric-weight-dialog/volumetric-weight-dialog';
import { ParcelDetailsComponent } from './components/forms/order-parcel-details/order-parcel-details';
import { OrderCustomerComponent } from './components/forms/order-customer/order-customer';
import { OrderReceiverComponent } from './components/forms/order-receiver/order-receiver';
import { OrderWarehouseComponent } from './components/forms/order-warehouse/order-warehouse';

import { AddOrderFormComponent } from './components/forms/add-order-form/add-order-form';
import { ReturnOrderFormComponent } from './components/forms/return-order-form/return-order-form';

import { DeliverySummaryModule } from './components/summary/delivery-summary.module';
import { ReturnSummaryModule } from './components/summary/return-summary.module';
import { DeliveryMethodComponent } from './components/forms/order-delivery-method/order-delivery-method';
import { ReturnMethodComponent } from './components/forms/return-order-method/return-order-method';

import { SharedModule } from '../shared';
import { WalkthroughModule } from '../walkthrough';

@NgModule({
  declarations: [
    CreateRootComponent,

    CreatePageComponent,
    ReturnPageComponent,
    UploadPageComponent,

    OrderReceiverHomeComponent,
    ReturnFromHomeComponent,
    PickupPointSelectionComponent,
    ParcelDetailsComponent,
    OrderCustomerComponent,
    OrderReceiverComponent,
    OrderWarehouseComponent,
    AddOrderFormComponent,
    ReturnOrderFormComponent,
    DeliveryMethodComponent,
    ReturnMethodComponent,
    VolumetricWeightDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    HttpClientModule,
    MatIconModule,

    CreateRoutingModule,
    StoreModule.forFeature(createReducerKey, CreateReducer),
    EffectsModule.forFeature([CreateEffects]),

    DeliverySummaryModule,
    ReturnSummaryModule,
    WalkthroughModule,
    SharedModule,
  ],
  providers: [CreateService, ExcelService],
  exports: [
    CreateRootComponent,
    CreatePageComponent,
    UploadPageComponent,
    ReturnPageComponent,

    OrderReceiverHomeComponent,
    ReturnFromHomeComponent,
    PickupPointSelectionComponent,
    ParcelDetailsComponent,
    OrderCustomerComponent,
    OrderWarehouseComponent,
    AddOrderFormComponent,
    ReturnOrderFormComponent,
    DeliveryMethodComponent,
    ReturnMethodComponent,
    VolumetricWeightDialogComponent,
  ],
  bootstrap: [CreateRootComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateModule {}

export interface WarehouseSelectionOptions {
  warehouseType: WarehouseType;
  value: boolean;
  reference: string;
}

export enum WarehouseType {
  PRIMARY = 'primary',
  RETURN = 'return',
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { InitialState } from '../../../reducers/authentication';
import {
  createUserAccount,
  createUserAccountResetEmailExists,
} from '../../../actions/authentication';
import {
  createUserAccountInProgress,
  createUserAccountSuccessful,
  createUserAccountEmailAlreadyExists,
} from '../../../selectors/authentication';
import { FormValidationService } from '@shared/services/form-validation.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { createDialogConfig } from '@globalHelpers/dialog';
import { LOGO_IMG } from '@shared/constants/images';
import { AnalyticsService } from '@core/services/common/analytics.service';
import { AnalyticsEvent } from '@core/models/analyticsEvent';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class SignUpFormComponent implements OnDestroy, OnInit {
  createUserAccountInProgress$: Observable<boolean> = this.store.select(
    createUserAccountInProgress,
  );

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  source: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<InitialState>,
    private formValidationService: FormValidationService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
  ) {
    this.form = this.formBuilder.group({
      email: [
        '',
        [Validators.required, this.formValidationService.email, Validators.maxLength(255)],
      ],
      fullName: [
        '',
        [
          Validators.required,
          this.formValidationService.userNameCharacters,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          this.formValidationService.isWhiteSpace,
          Validators.minLength(8),
          Validators.maxLength(255),
        ],
      ],
      repeatPassword: ['', [Validators.required, this.formValidationService.directPasswordMatch]],
      marketingOptIn: [false],
    });
  }

  ngOnInit(): void {
    this.showDialogOnAccountCreateSuccess();
    this.showDialogOnAccountEmailExistsErr();

    this.route.queryParams.subscribe((params) => {
      this.source = params.source ?? 'myPargo';
    });
    const event = new AnalyticsEvent('signup', 'view', 'signup_page_view', 'start', 1, 1);
    this.analyticsService.logEvent(event);
  }

  onSubmit(): void {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((field) => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }

    const { email, password, marketingOptIn, fullName } = this.form.controls;
    const userAccountData = {
      email: email.value,
      password: password.value,
      marketing_allowed: marketingOptIn.value,
      name: fullName.value,
      source: this.source,
    };

    this.store.dispatch(createUserAccount({ userAccountData: userAccountData }));
  }

  showDialogOnAccountCreateSuccess(): void {
    const createUserAccountSuccessful$: Observable<boolean> = this.store.select(
      createUserAccountSuccessful,
    );
    const createUserAccountSuccessfulSubscription = createUserAccountSuccessful$.subscribe(
      (signUpSuccessful) => {
        if (signUpSuccessful) {
          this.openAccountCreationSuccessDialog();
          const event = new AnalyticsEvent(
            'signup',
            'view',
            'signup_page_email_sent_dialog_view',
            'in_progress',
            1,
            3,
          );
          this.analyticsService.logEvent(event);
        }
      },
    );
    this.subscriptions.push(createUserAccountSuccessfulSubscription);
  }

  showDialogOnAccountEmailExistsErr(): void {
    const emailAlreadyExists$: Observable<boolean> = this.store.select(
      createUserAccountEmailAlreadyExists,
    );
    const emailAlreadyExistsSubscription = emailAlreadyExists$.subscribe((emailExists) => {
      if (emailExists) {
        this.openAccountEmailExistsDialog();
      }
    });
    this.subscriptions.push(emailAlreadyExistsSubscription);
  }

  openAccountCreationSuccessDialog(): void {
    const btnLabel = 'Continue';
    const heading = 'Verification E-mail Sent!';
    const text =
      "We've sent you an email with a link to verify your account. You can continue with the sign-up process, but you will need to verify your account to access myPargo";

    const dialogConfig = createDialogConfig(btnLabel, heading, text, LOGO_IMG);
    const dialogRef = this.dialog.open(InfoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      const event = new AnalyticsEvent(
        'signup',
        'click',
        'signup_page_email_sent_dialog_continue',
        'in_progress',
        1,
        4,
      );
      this.analyticsService.logEvent(event);
      this.router.navigate(['/welcome']);
    });
  }

  openAccountEmailExistsDialog(): void {
    const btnLabel = 'Sign In';
    const heading = "Oh, look at that - you're already in!";
    const text =
      "There's already an active account with your email address.<br> Simply sign in to your account.";

    const dialogConfig = createDialogConfig(btnLabel, heading, text, LOGO_IMG);
    const dialogRef = this.dialog.open(InfoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((action: string) => {
      this.store.dispatch(createUserAccountResetEmailExists());
      if (action === 'Sign In') {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}

import { Injectable } from '@angular/core';

import { LoadOptions, Scripts } from '../models/scripts';

declare var document: any;

@Injectable()
export class ScriptService {
  private scripts: any = {};

  constructor() {}

  loadScriptStore(scriptsStore: Scripts[]) {
    scriptsStore.forEach((script: any) => {
      this.scripts[script.name] = {
        name: script.name,
        loaded: false,
        src: script.src,
        script: undefined,
      };
    });
  }

  load(scripts: { name: string; options: LoadOptions }[]) {
    const promises: Promise<any>[] = scripts.map((script): Promise<any> => {
      const { name, options } = script;
      return this.loadScript(name, options);
    });
    return Promise.all(promises);
  }

  unload() {
    if (this.scripts && this.scripts.length) {
      this.scripts.forEach((script) => {
        this.unloadScript(script.name);
      });
    }
  }

  loadScript(name: string, options?: LoadOptions) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name] && this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        if (this.scripts[name]) {
          let script = document.createElement('script');
          script.type = 'text/javascript';

          if (options && options.parameters) {
            script.src = `${this.scripts[name].src}${options.parameters}`;
          } else {
            script.src = this.scripts[name].src;
          }

          if (script.readyState) {
            //IE
            script.onreadystatechange = () => {
              if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                this.scripts[name].loaded = true;
                resolve({ script: name, loaded: true, status: 'Loaded' });
              }
            };
          } else {
            script.onload = () => {
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            };
          }
          script.onerror = (error: any) =>
            resolve({ script: name, loaded: false, status: 'Loaded' });

          this.scripts[name].script = script;

          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    });
  }

  unloadScript(name: string) {
    if (this.scripts[name]) {
      const { script } = this.scripts[name];

      script.parentNode.removeChild(script);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';

import WalkthroughReducer, { walkthroughReducerKey } from './reducers/walkthrough';
import { WalkthroughEffects } from './effects/walkthrough.effects';
import { WalkthroughService } from './services/walkthrough.service';

import { WalkthroughComponent } from './components/walkthrough/walkthrough';
import { WalkthroughToggleComponent } from './components/walkthrough-toggle/walkthrough-toggle';
import { WalkthroughExplanationComponent } from './components/walkthrough-explanation/walkthrough-explanation';

@NgModule({
    declarations: [WalkthroughComponent, WalkthroughToggleComponent, WalkthroughExplanationComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        MatIconModule,
        DragDropModule,
        StoreModule.forFeature(walkthroughReducerKey, WalkthroughReducer),
        EffectsModule.forFeature([WalkthroughEffects]),
    ],
    providers: [WalkthroughService],
    exports: [WalkthroughComponent, WalkthroughToggleComponent, WalkthroughExplanationComponent],
    bootstrap: []
})
export class WalkthroughModule {}

<pargo-container class="faq">
  <pargo-container-heading [heading]="heading" [subtitle]="subtitle"></pargo-container-heading>
  <pargo-shadowbox class="faq__shadowbox">
    <div class="faq__container">
      <ul class="faq__topics">
        <li class="faq__topic" *ngFor="let faqItems of faq; let id = index">
          <div class="faq__heading" (click)="handleClick(id)">
            <h2
              [class]="current === id ? 'faq__topic-heading faq__topic-heading--active' : 'faq__topic-heading'"
            >
              {{faqItems.heading}}
            </h2>
            <div
              [class]="current === id ? 'faq__open faq__open--open' : 'faq__open faq__open--close'"
            >
              <mat-icon class="faq__icon">
                {{current === id ? 'expand_less' : 'expand_more'}}
              </mat-icon>
            </div>
          </div>

          <app-faq-list
            class="faq__faq-list faq__faq-list--mobile"
            [items]="faqItems.items"
            [current]="0"
            *ngIf="id === current"
          >
          </app-faq-list>
        </li>
      </ul>
      <app-faq-list class="faq__faq-list faq__faq-list--desktop" [items]="items" [current]="0">
      </app-faq-list>
      <div class="faq__footer">{{subtitle}}</div>
    </div>
  </pargo-shadowbox>
</pargo-container>

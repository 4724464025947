import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginPageComponent } from './pages/login/login.component';
import { ResetRequestPageComponent } from './pages/reset-request/reset-request.component';
import { ResetPasswordPageComponent } from './pages/reset-password/reset-password.component';
import { SignUpPageComponent } from './pages/sign-up/sign-up.component';
import { ConfirmPageComponent } from './pages/confirm/confirm.component';
import { WelcomePageComponent } from './pages/welcome/welcome.component';
import { CreateProfilePageComponent } from './pages/business-info/create-profile/create-profile.component';
import { CreateWarehousePageComponent } from './pages/business-info/create-warehouse/create-warehouse.component';
import { SignUpCompletionPageComponent } from './pages/sign-up-completion/sign-up-completion.component';

import AuthenticationReducer, { authenticationReducerKey } from './reducers/authentication';
import { AuthenticationEffects } from './effects/authentication.effects';
import { AuthenticationService } from './services/authentication.service';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthGuardService } from './services/authentication-guard.service';
import { LocalStorageService } from '../../core/services/common/localstorage.service';

import { LoginFormComponent } from './components/forms/login/login-form';
import { ResetPasswordFormComponent } from './components/forms/reset-password/reset-password-form';
import { ResetRequestFormComponent } from './components/forms/reset-request/reset-request-form';
import { ConfirmAccountFormComponent } from './components/forms/confirm-account/confirm-account-form';
import { SignUpFormComponent } from './components/forms/sign-up-form/sign-up-form.component';

import { AuthBannerComponent } from './components/auth-banner/auth-banner.component';
import { AuthFormWelcomeHeaderComponent } from './components/auth-form-welcome-header/auth-form-welcome-header.component';
import { SignUpHeaderComponent } from './components/sign-up-header/sign-up-header.component';

import { SharedModule } from '../shared';
import { SwiperModule } from 'swiper/angular';
import { SignUpGuardService } from './services/signup-guard.service';

@NgModule({
  declarations: [
    LoginPageComponent,
    ResetRequestPageComponent,
    ResetPasswordPageComponent,
    SignUpPageComponent,
    ConfirmPageComponent,
    WelcomePageComponent,
    CreateProfilePageComponent,
    CreateWarehousePageComponent,
    SignUpCompletionPageComponent,

    LoginFormComponent,
    ResetPasswordFormComponent,
    ResetRequestFormComponent,
    ConfirmAccountFormComponent,
    SignUpFormComponent,

    AuthBannerComponent,
    AuthFormWelcomeHeaderComponent,
    SignUpHeaderComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,

    AuthenticationRoutingModule,
    StoreModule.forFeature(authenticationReducerKey, AuthenticationReducer),
    EffectsModule.forFeature([AuthenticationEffects]),

    SharedModule,
    SwiperModule,
  ],
  providers: [AuthenticationService, AuthGuardService, SignUpGuardService, LocalStorageService],
  exports: [
    LoginPageComponent,
    ResetRequestPageComponent,
    ResetPasswordPageComponent,
    SignUpPageComponent,
    ConfirmPageComponent,
    WelcomePageComponent,
    CreateProfilePageComponent,
    CreateWarehousePageComponent,
    SignUpCompletionPageComponent,

    LoginFormComponent,
    ResetPasswordFormComponent,
    ResetRequestFormComponent,
    ConfirmAccountFormComponent,
    SignUpFormComponent,

    AuthBannerComponent,
    AuthFormWelcomeHeaderComponent,
    SignUpHeaderComponent,
    MatDialogModule,
  ],
  bootstrap: [
    LoginPageComponent,
    ResetRequestPageComponent,
    ResetPasswordPageComponent,
    ConfirmPageComponent,
    SignUpPageComponent,
    WelcomePageComponent,
  ],
})
export class AuthenticationModule {}

export class AnalyticsEvent {
  journey: string;
  event_type: string;
  name: string;
  journey_status: string;
  page_num: number;
  event_num: number;

  constructor(
    journey: string,
    event_type: string,
    name: string,
    journey_status: string,
    page_num: number,
    event_num: number,
  ) {
    this.journey = journey;
    this.event_type = event_type;
    this.name = name;
    this.journey_status = journey_status;
    this.page_num = page_num;
    this.event_num = event_num;
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  primaryBtnLabel: string;
  secondaryBtnLabel: string;
  heading: string;
  text: string;
  imgSrc: string;
  constructor(private dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.primaryBtnLabel = data.primaryBtnLabel;
    this.secondaryBtnLabel = data.secondaryBtnLabel;
    this.heading = data.heading;
    this.text = data.text;
    this.imgSrc = data.imgSrc;
  }

  close(action: string) {
    this.dialogRef.close(action);
  }
}

import {
  Component,
  Input,
  ViewChild,
  HostListener,
  AfterViewInit,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { UntypedFormGroup } from '@angular/forms';

import { PargoArrowIcon } from '../../../../../../../icons';
import { CustomIcon } from '../../../../../../../core/models/icons';

import { isBlank } from '../../../../../helpers/functions';
@Component({
  selector: 'pargo-input',
  templateUrl: './input.html',
  styleUrls: ['./input.scss'],
})
export class InputComponent implements AfterViewInit, OnDestroy {
  @Input() label: string;
  @Input() value: string;
  @Input() form?: UntypedFormGroup;
  @Input() formName?: string;
  @Input() hasFocus?: boolean;

  @ViewChild('containerElement', { static: true }) containerElement: ElementRef;

  labelElement: any;
  inputFieldElement: HTMLInputElement;

  pargoIconPrefix = 'pargo';
  customInputIcons: CustomIcon[] = [PargoArrowIcon];

  focus: boolean = false;
  error: boolean = false;
  _value: string | number;

  autoFillListener: any;
  focusListener: any;
  blurListener: any;
  valueListener: any;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.customInputIcons.forEach(({ path, name }: CustomIcon): void => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  ngAfterViewInit() {
    if (this.containerElement && this.containerElement.nativeElement) {
      const element = this.containerElement.nativeElement;

      if (element.children) {
        const [label, input] = element.children;
        this.labelElement = label;
        this.inputFieldElement = input;
        if (this.inputFieldElement) {
          this.focusListener = this.inputFieldElement.addEventListener(
            'focus',
            this.handleFocus.bind(this),
            false,
          );
          this.blurListener = this.inputFieldElement.addEventListener(
            'blur',
            this.handleBlur.bind(this),
            false,
          );
          this.autoFillListener = this.inputFieldElement.addEventListener(
            'animationstart',
            this.onAnimationStart,
            false,
          );

          setTimeout(() => {
            const value = this.inputFieldElement.value;
            if (!isBlank(value)) {
              this._value = value;
              this.toggleLabel();
            }
          });
        }

        if (this.inputFieldElement.type === 'number') {
          this.inputFieldElement.addEventListener('keypress', this.numericOnly.bind(this), false);
        }

        if (this.labelElement && this.hasFocus) {
          setTimeout(() => {
            this.handleLabelFocus();
          });
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.inputFieldElement) {
      if (this.autoFillListener) {
        const element = this.inputFieldElement;
        element.removeEventListener('animationstart', this.onAnimationStart, true);
      }

      if (this.blurListener) {
        const element = this.inputFieldElement;
        element.removeEventListener('blur', this.handleBlur, true);
      }

      if (this.focusListener) {
        const element = this.inputFieldElement;
        element.removeEventListener('focus', this.handleFocus, true);
      }
    }
  }

  onAnimationStart({ animationName }) {
    switch (animationName) {
      case 'onAutoFillStart':
        this.handleFocus();
      case 'onAutoFillCancel':
        this.handleBlur();
    }
  }

  get containerClass(): string {
    return `input__container${this.focus ? ' input__container--focus' : ''}${
      this.error ? ' input__container--error' : ''
    }`;
  }

  get labelClass(): string {
    return `input__label${this.focus ? ' input__label--focus' : ''}${
      this.error ? ' input__label--error' : ''
    }`;
  }

  handleLabelFocus() {
    if (this.inputFieldElement) {
      this.focus = true;
      this.inputFieldElement.focus();
    }
  }

  handleBlur() {
    if (isBlank(this._value) === true && this.focus === true) {
      this.handleError(this.inputFieldElement);
      this.focus = false;
    }
  }

  handleFocus() {
    if (isBlank(this._value) === true && this.focus === false) {
      this.handleError(this.inputFieldElement);
      this.focus = true;
    }
  }

  @HostListener('input', ['$event.target'])
  handleInput(e) {
    this._value = e.value;
    this.handleError(e);
    this.toggleLabel();
  }

  @HostListener('change', ['$event.target'])
  handleInputChange: any = (e) => {
    this._value = e.value;
    this.handleError(e);
    this.toggleLabel();
  };

  handleError(e: HTMLInputElement) {
    if (e.classList.contains('ng-invalid') && e.classList.contains('ng-touched')) {
      this.error = true;
    } else {
      this.error = false;
    }
  }

  toggleLabel() {
    if (isBlank(this._value) === false) {
      this.focus = true;
    } else {
      this.focus = false;
    }
  }

  numericOnly(event: KeyboardEvent): void {
    const key = event.key;
    if (key === 'e' || key === 'E' || key === '+' || key === '-') {
      event.preventDefault();
    }
  }
}

<section class="parcel-details" [formGroup]="parcelDetailsForm">
  <h3 class="parcel-details__heading heading heading__lined"><span>Parcel details</span></h3>

  <app-information
    class="parcel-details__weight-information"
    [description]="weightInformation.description"
    [title]="weightInformation.title"
    [modal]="volumetricWeightModal"
  >
    <div class="parcel-details__weight">
      <div class="parcel-weight__option">
        <label class="parcel-weight__label" for="small-parcel">
          <mat-icon class="parcel-weight__icon" svgIcon="small-parcel-pargo"></mat-icon>
          <span class="parcel-weight__text">0 - 5 kg</span>
          <input
            #inputRef
            id="small-parcel"
            type="radio"
            class="parcel-weight__radio"
            value="5"
            formControlName="weight"
            name="weight"
          />
        </label>
      </div>
      <div class="parcel-weight__option">
        <label class="parcel-weight__label" for="medium-parcel">
          <mat-icon class="parcel-weight__icon" svgIcon="medium-parcel-pargo"></mat-icon>
          <span class="parcel-weight__text">5 - 10 kg</span>
          <input
            #inputRef
            id="medium-parcel"
            type="radio"
            class="parcel-weight__radio"
            value="10"
            formControlName="weight"
            name="weight"
          />
        </label>
      </div>
      <div class="parcel-weight__option">
        <label class="parcel-weight__label" for="large-parcel">
          <mat-icon class="parcel-weight__icon" svgIcon="large-parcel-pargo"></mat-icon>
          <span class="parcel-weight__text">10 - 15 kg</span>
          <input
            #inputRef
            id="large-parcel"
            type="radio"
            class="parcel-weight__radio"
            value="15"
            formControlName="weight"
            name="weight"
          />
        </label>
      </div>
    </div>
  </app-information>

  <div class="parcel-details__information-input">
    <div class="parcel-details__information">
      <div class="parcel-information__reference">
        <pargo-input class="parcel-information__input" label="Own reference">
          <input class="input" type="text" formControlName="externalReference" />
        </pargo-input>
      </div>
      <div class="parcel-information__value">
        <pargo-input class="parcel-information__input" label="Parcel value">
          <input class="input" type="number" min="0" formControlName="packageValue" />
        </pargo-input>
      </div>
    </div>
    <div class="parcel-details__description">
      <div class="parcel-information__description">
        <pargo-input class="parcel-information__input" label="Parcel content description">
          <input class="input" type="text" formControlName="packageDescription" />
        </pargo-input>
      </div>
    </div>
  </div>
</section>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { InitialState } from '../../../core/reducers/core';
import { authenticated } from '../../../core/selectors/core';

@Injectable()
export class AuthGuardService  {
  authenticated$: Observable<any> = this.store.select(authenticated);

  constructor(private router: Router, private store: Store<InitialState>) {}

  canActivate(): Observable<boolean> {
    return this.authenticated$.pipe(
      map((authenticationSuccessful: boolean) => {
        if (!authenticationSuccessful) {
          this.router.navigate(['/login']);
          return false;
        }

        return true;
      }),
    );
  }
}

import {
  pickUpPointTableHeader,
  waybillTableHeader,
} from '@shared/components/data-table/components/data-table/constants/data-table-constants';
import { d2wRowItem, w2dRowItem, w2pRowItem } from '../models/order-validation';

export const headers = {
  w2p: [
    waybillTableHeader,
    'reference',
    'weight',
    'warehouse',
    pickUpPointTableHeader,
    'receiver',
    'receiver email',
    'receiver phone',
  ],
  d2w: [
    waybillTableHeader,
    'reference',
    'weight',
    'warehouse',
    'sending',
    'suburb postalcode',
    'sender email',
    'sender phone',
  ],
  w2d: [
    waybillTableHeader,
    'reference',
    'weight',
    'warehouse',
    'receiver',
    'suburb postalcode',
    'receiver email',
    'receiver phone',
  ],
  p2w_return: [waybillTableHeader, 'reference', 'weight', pickUpPointTableHeader, 'warehouse'],
  w2d_plus: [
    waybillTableHeader,
    'reference',
    'weight',
    'warehouse',
    'receiver',
    'suburb postalcode',
    'receiver email',
    'receiver phone',
  ],
};

export const prepaidSupplierWeightValidation = {
  message: 'Weight *',
  required: true,
  regex: /(5)|(10)|(15)/,
  options: [
    { label: '5kg', value: '5' },
    { label: '10kg', value: '10' },
    { label: '15kg', value: '15' },
  ],
};

export const monthlySupplierWeightValidation = {
  message: 'Weight *',
  required: false,
  regex: /^\d{1,4}\.\d{0,2}$|^\d{1,4}$|null$/,
};

export const cashOnDeliveryValidation = {
  message: 'Cash on delivery *',
  required: false,
  regex: /^\d{1,9}\.\d{0,2}$|^\d{1,9}$|null$/,
};

export const validation = {
  cubicWeight: {},
  warehouse: {
    message: 'Warehouse *',
    required: true,
    regex: /[A-Z-a-z-0-9]*/,
  },
  area: {
    message: 'Must match *',
    required: true,
    search: {
      label: 'Suburb and postalcode',
      indexes: ['Postal Code', 'Suburb', 'City'],
      endpoints: [`${window.location.origin}/assets/data/route-guide.json`],
      fields: ['Suburb', 'Postal Code'],
      displayFields: ['Suburb', 'Postal Code'],
    },
  },
};

const mapW2dData = (orders, cashOnDeliverySupported: boolean) => {
  return orders.map((order) => {
    const {
      trackingCode,
      externalReference,
      cubicWeight,
      from,
      to,
      receiver,
      paymentOnCollectionAmount,
    } = order;
    let updatedOrder: w2dRowItem = {
      trackingCode,
      externalReference,
      cubicWeight,
      warehouse: from && from.reference,
      fullName: receiver && receiver.fullName,
      area: {
        postalCode: to?.postalCode,
        suburb: to?.suburb?.toUpperCase(),
        province: to?.province?.toUpperCase(),
        city: to?.city?.toUpperCase(),
      },
      email: receiver && receiver.email,
      phoneNumber: receiver && receiver.phoneNumber,
    };
    if (cashOnDeliverySupported) {
      updatedOrder.paymentOnCollectionAmount = paymentOnCollectionAmount;
    }
    return updatedOrder;
  });
};

const mapD2wData = (orders) => {
  return orders.map((order) => {
    const { trackingCode, externalReference, cubicWeight, from, to, receiver } = order;
    let updatedOrder: d2wRowItem = {
      trackingCode,
      externalReference,
      cubicWeight: cubicWeight,
      warehouse: to.reference ?? '',
      fullName: receiver.fullName ?? '',
      area: {
        postalCode: from?.postalCode,
        suburb: from?.suburb?.toUpperCase(),
        province: from?.province?.toUpperCase(),
        city: from?.city?.toUpperCase(),
      },
      email: receiver.email ?? '',
      phoneNumber: receiver.phoneNumber ?? '',
    };
    return updatedOrder;
  });
};

const mapW2dPlus = (orders) => {
  return orders.map((order) => {
    const { trackingCode, externalReference, cubicWeight, from, to, receiver } = order;
    return {
      trackingCode,
      externalReference,
      cubicWeight,
      warehouse: from && from.reference,
      fullName: receiver && receiver.fullName,
      area: {
        postalCode: to?.postalCode,
        suburb: to?.suburb?.toUpperCase(),
        province: to?.province?.toUpperCase(),
        city: to?.city?.toUpperCase(),
      },
      email: receiver && receiver.email,
      phoneNumber: receiver && receiver.phoneNumber,
    };
  });
};

const mapW2pData = (orders, cashOnDeliverySupported: boolean) => {
  return orders.map((order) => {
    const {
      trackingCode,
      externalReference,
      cubicWeight,
      from,
      to,
      receiver,
      paymentOnCollectionAmount,
    } = order;
    const updatedOrder: w2pRowItem = {
      trackingCode,
      externalReference,
      cubicWeight,
      warehouse: from && from.reference,
      pickupPoint: to?.reference ?? '',
      fullName: receiver && receiver.fullName,
      email: receiver && receiver.email,
      phoneNumber: receiver && receiver.phoneNumber,
    };
    if (cashOnDeliverySupported) {
      updatedOrder.paymentOnCollectionAmount = paymentOnCollectionAmount;
    }
    return updatedOrder;
  });
};

const mapP2wReturnData = (orders) => {
  return orders.map((order) => {
    const { trackingCode, externalReference, cubicWeight, from, to } = order;
    return {
      trackingCode,
      externalReference,
      cubicWeight,
      pickupPoint: from?.reference ?? '',
      warehouse: to && to.reference,
    };
  });
};

const dataW2dOrders = (data, orders, warehouses) => {
  return orders.map((order, index) => {
    if (data && data[index]) {
      const orderData = data[index];

      const [firstName, ...lastName] = orderData.fullName.split(' ');
      const { suburb, postalCode, city, province } = orderData.area;

      const warehouse = warehouses.find(
        (warehouse) => String(warehouse.reference) === String(orderData.warehouse),
      );

      return {
        ...order,
        edited: orderData.edited,
        trackingCode: orderData.trackingCode,
        externalReference: orderData.externalReference,
        cubicWeight: orderData.cubicWeight,
        paymentOnCollectionAmount: orderData.paymentOnCollectionAmount
          ? Number(orderData.paymentOnCollectionAmount)
          : null,
        from: warehouse
          ? {
              suburb: warehouse.suburb,
              city: warehouse.city,
              postalCode: warehouse.postalCode,
              address1: warehouse.address1,
              address2: warehouse.address2,
              province: warehouse.province,
              country: warehouse.country,
              reference: orderData.warehouse,
            }
          : {
              ...order.from,
              reference: orderData.warehouse,
            },
        to: {
          ...order.to,
          postalCode: postalCode,
          suburb: suburb,
          city: city,
          province: province,
        },
        receiver: {
          ...order.receiver,
          fullName: orderData.fullName,
          firstName: firstName,
          lastName: lastName.join(' '),
          phoneNumber: orderData.phoneNumber,
          phoneNumbers: [orderData.phoneNumber],
          email: orderData.email,
        },
      };
    }
  });
};

const dataD2wOrders = (data, orders, warehouses) => {
  return orders.map((order, index) => {
    if (data && data[index]) {
      const orderData = data[index];

      const [firstName, ...lastName] = orderData.fullName.split(' ');
      const { suburb, postalCode, city, province } = orderData.area;

      const warehouse = warehouses.find(
        (warehouseData) => String(warehouseData.reference) === String(orderData.warehouse),
      );

      return {
        ...order,
        edited: orderData.edited,
        trackingCode: orderData.trackingCode,
        externalReference: orderData.externalReference,
        cubicWeight: orderData.cubicWeight,
        paymentOnCollectionAmount: orderData.paymentOnCollectionAmount
          ? Number(orderData.paymentOnCollectionAmount)
          : null,
        to: warehouse
          ? {
              suburb: warehouse.suburb,
              city: warehouse.city,
              postalCode: warehouse.postalCode,
              address1: warehouse.address1,
              address2: warehouse.address2,
              province: warehouse.province,
              country: warehouse.country,
              reference: orderData.warehouse,
            }
          : {
              ...order.to,
              reference: orderData.warehouse,
            },
        from: {
          ...order.from,
          postalCode: postalCode,
          suburb: suburb,
          city: city,
          province: province,
        },
        receiver: {
          ...order.receiver,
          fullName: orderData.fullName,
          firstName: firstName,
          lastName: lastName.join(' '),
          phoneNumber: orderData.phoneNumber,
          phoneNumbers: [orderData.phoneNumber],
          email: orderData.email,
        },
      };
    }
  });
};

const dataW2dPlusOrders = (data, orders, warehouses) => {
  return orders.map((order, index) => {
    if (data && data[index]) {
      const orderData = data[index];

      const [firstName, ...lastName] = orderData.fullName.split(' ');
      const { suburb, postalCode, city, province } = orderData.area.split(' ');

      const warehouse = warehouses.find(
        (warehouse) => String(warehouse.reference) === String(orderData.warehouse),
      );

      return {
        ...order,
        trackingCode: orderData.trackingCode,
        externalReference: orderData.externalReference,
        cubicWeight: orderData.cubicWeight,
        from: warehouse
          ? {
              suburb: warehouse.suburb,
              city: warehouse.city,
              postalCode: warehouse.postalCode,
              address1: warehouse.address1,
              address2: warehouse.address2,
              province: warehouse.province,
              country: warehouse.country,
              reference: orderData.warehouse,
            }
          : {
              ...order.from,
              reference: orderData.warehouse,
            },
        to: {
          ...order.to,
          postalCode: postalCode,
          suburb: suburb,
          city: city,
          province: province,
        },
        receiver: {
          ...order.receiver,
          fullName: orderData.fullName,
          firstName: firstName,
          lastName: lastName.join(' '),
          phoneNumber: orderData.phoneNumber,
          phoneNumbers: [orderData.phoneNumber],
          email: orderData.email,
        },
      };
    }
  });
};

const dataW2pOrders = (data, orders, warehouses) => {
  return orders.map((order, index) => {
    if (data && data[index]) {
      const orderData = data[index];

      const [firstName, ...lastName] = orderData.fullName.split(' ');

      const warehouse = warehouses.find(
        (warehouse) => String(warehouse.reference) === String(orderData.warehouse),
      );

      return {
        ...order,
        trackingCode: orderData.trackingCode,
        externalReference: orderData.externalReference,
        cubicWeight: orderData.cubicWeight,
        paymentOnCollectionAmount: orderData.paymentOnCollectionAmount
          ? Number(orderData.paymentOnCollectionAmount)
          : null,
        edited: true,
        from: warehouse
          ? {
              suburb: warehouse.suburb,
              city: warehouse.city,
              postalCode: warehouse.postalCode,
              address1: warehouse.address1,
              address2: warehouse.address2,
              province: warehouse.province,
              country: warehouse.country,
              reference: orderData.warehouse,
            }
          : {
              ...order.from,
              reference: orderData.warehouse,
            },
        to: {
          ...order.to,
          reference: orderData.pickupPoint,
        },
        receiver: {
          ...order.receiver,
          fullName: orderData.fullName,
          firstName: firstName,
          lastName: lastName.join(' '),
          phoneNumber: orderData.phoneNumber,
          phoneNumbers: [orderData.phoneNumber],
          email: orderData.email,
        },
      };
    }
  });
};

const dataP2wReturnOrders = (data, orders, warehouses) => {
  return orders.map((order, index) => {
    if (data && data[index]) {
      const orderData = data[index];

      const warehouse = warehouses.find(
        (warehouse) => String(warehouse.reference) === String(orderData.warehouse),
      );

      return {
        ...order,
        trackingCode: orderData.trackingCode,
        externalReference: orderData.externalReference,
        cubicWeight: orderData.cubicWeight,
        edited: true,
        from: {
          ...order.from,
          from: orderData.pickupPoint,
        },
        to: warehouse
          ? {
              suburb: warehouse.suburb,
              city: warehouse.city,
              postalCode: warehouse.postalCode,
              address1: warehouse.address1,
              address2: warehouse.address2,
              province: warehouse.province,
              country: warehouse.country,
              reference: orderData.warehouse,
            }
          : {
              ...order.to,
              reference: orderData.warehouse,
            },
      };
    }
  });
};

export const mapping = {
  w2p: (data, orders, warehouses) => dataW2pOrders(data, orders, warehouses),
  w2d: (data, orders, warehouses) => dataW2dOrders(data, orders, warehouses),
  p2w_return: (data, orders, warehouses) => dataP2wReturnOrders(data, orders, warehouses),
  w2d_plus: (data, orders, warehouses) => dataW2dPlusOrders(data, orders, warehouses),
  d2w: (data, orders, warehouse) => dataD2wOrders(data, orders, warehouse),
};

export const data = {
  w2p: (orders, cashOnDeliverySupported) => mapW2pData(orders, cashOnDeliverySupported),
  w2d: (orders, cashOnDeliverySupported) => mapW2dData(orders, cashOnDeliverySupported),
  d2w: (orders) => mapD2wData(orders),
  p2w_return: (orders) => mapP2wReturnData(orders),
  w2d_plus: (orders) => mapW2dPlus(orders),
};

export const cashOnDeliverySupport = {
  ZA: false,
  EG: true,
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerModule } from '../../layout/container/container.module';

import { CreditsPricelist } from './components/credits-pricelist/credits-pricelist';

@NgModule({
    declarations: [CreditsPricelist],
    imports: [CommonModule, MatIconModule, ContainerModule],
    providers: [],
    exports: [CreditsPricelist]
})
export class CreditsPricelistModule {}

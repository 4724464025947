import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { InitialState } from '../../../reducers/create';
import { convertW2pForm, convertW2dForm, formatPhoneNumber } from '../../../helpers/orders';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';

import {
  saveOrder,
  savePrintOrder,
  editOrder,
  editPrintOrder,
  submittingForm,
  resetForm,
  getRegion,
} from '../../../actions/create';
import {
  selectCredits,
  selectWarehouses,
  selectPayments,
  selectSubmitting,
  selectType,
  selectOrder,
  selectRegion,
} from '../../../selectors/create';

import { DeliveryDetails, Delivery } from './models/add-order-form';
import { Order } from '@orders/models/orders';
import { createCreditsDialogConfig, notEnoughCredits } from '@create/helpers/functions';

@Component({
  selector: 'app-add-order-form',
  templateUrl: './add-order-form.html',
  styleUrls: ['./add-order-form.scss'],
})
export class AddOrderFormComponent implements OnInit, OnDestroy {
  createOrderForm: UntypedFormGroup = this.formBuilder.group({
    parcelDetails: this.formBuilder.control({}),
    receiver: this.formBuilder.control({}),
    warehouse: this.formBuilder.control({}),
    deliveryMethod: this.formBuilder.control({}),
    another: [''],
  });

  submitType: string | undefined = undefined;
  warehouses$: Observable<object>;
  credits$: Observable<number>;
  payments$: Observable<object>;
  submitting$: Observable<boolean>;
  order$: Observable<object>;
  type$: Observable<string>;

  region$: Observable<string>;

  credits: number | undefined = undefined;
  payments: any | undefined = undefined;
  sufficientCredits: boolean = true;
  amount: number = 0;
  order: any = undefined;
  process;
  type: string = 'create';

  warehouses: any;

  submitting: boolean = false;
  submitted: boolean = false;

  mappedToOrder: boolean = false;

  errors: any[] = [];

  subscriptions: Subscription[] = [];

  region: string = 'main';
  toAddress: object;
  fromAddress: object;
  selectedWarehouseCountryCode: string;

  constructor(
    public store: Store<InitialState>,
    public formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    if (this.route && this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        this.process = params.process;
      });
    }

    this.warehouses$ = this.store.select(selectWarehouses);
    this.credits$ = this.store.select(selectCredits);
    this.payments$ = this.store.select(selectPayments);
    this.submitting$ = this.store.select(selectSubmitting);
    this.order$ = this.store.select(selectOrder);
    this.type$ = this.store.select(selectType);

    this.region$ = this.store.select(selectRegion);
  }

  ngOnInit() {
    const creditsSubscription: Subscription = this.credits$.subscribe((credits) => {
      this.credits = credits;
    });
    this.subscriptions.push(creditsSubscription);

    const paymentsSubscription = this.payments$.subscribe((payments) => {
      this.payments = payments;
    });
    this.subscriptions.push(paymentsSubscription);

    const warehouseSubscription = this.warehouses$.subscribe((warehouses) => {
      this.warehouses = warehouses;
    });
    this.subscriptions.push(warehouseSubscription);

    const regionSubscription = this.region$.subscribe((region) => {
      if (region !== this.region) {
        this.region = region;
        this.calculateAmount();
      }
    });
    this.subscriptions.push(regionSubscription);

    const typeSubscription = this.type$.subscribe((type) => {
      this.type = type;

      if (type === 'create') {
        this.createOrderForm.reset();
      }
    });

    this.subscriptions.push(typeSubscription);

    const orderSubscription = this.order$.subscribe((payload: { order; warehouse }) => {
      if (!this.submitting && this.mappedToOrder === false) {
        this.mapToForm(payload);
      }
      this.order = payload.order;
    });

    this.subscriptions.push(orderSubscription);

    const formSubscription = this.createOrderForm.statusChanges.subscribe(() => {
      this.handleUpdateRegion();
      this.calculateAmount();
    });

    this.subscriptions.push(formSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.createOrderForm.reset();
    this.store.dispatch(resetForm());
  }

  get formTitle() {
    switch (this.type) {
      case 'create':
        return 'Create a new order';
      case 'copy':
        return 'Duplicate order';
      case 'edit':
        return 'Edit order';
      default:
        return 'Create a new order';
    }
  }

  get formValues() {
    return this.createOrderForm.value;
  }

  mapToForm({ order, warehouse }) {
    let form;

    if (order) {
      switch (order.orderType) {
        case 'w2p':
          form = convertW2pForm(order, warehouse);
          this.process = order.orderType;
          break;
        case 'w2d':
          form = convertW2dForm(order, warehouse);
          this.process = order.orderType;
          break;
      }

      this.createOrderForm.patchValue({
        ...form,
      });
    }

    this.mappedToOrder = true;
  }

  checkFormError() {
    this.errors = [];
    Object.keys(this.createOrderForm.controls).forEach((key: string) => {
      const controls = this.createOrderForm.controls;
      this.errors.push(controls[key].errors);
    });
    this.errors = this.errors.filter((item) => item !== null);
  }

  calculateAmount() {
    const values = this.createOrderForm.value;
    const { parcelDetails, deliveryMethod } = values;

    const deliveryProcess =
      deliveryMethod && deliveryMethod.deliveryMethod !== null
        ? deliveryMethod.deliveryMethod
        : this.process;

    if (
      deliveryProcess &&
      this.payments !== null &&
      this.payments !== undefined &&
      deliveryProcess &&
      this.payments[deliveryProcess] &&
      parcelDetails &&
      parcelDetails.weight &&
      this.payments[deliveryProcess][this.region][parcelDetails.weight]
    ) {
      this.amount = this.payments[deliveryProcess][this.region][parcelDetails.weight];
    }
  }

  setSubmitType(type) {
    this.submitType = type;
  }

  scrollTop() {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

    if (supportsNativeSmoothScroll) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
  }

  editOrder(order, deliveryMethod, redirect) {
    if (this.submitType === 'confirm') {
      if (notEnoughCredits(this.payments, this.credits, this.amount)) {
        const activity = 'editOrder';
        this.openCreditsDialog(order, deliveryMethod, activity, redirect);
      } else {
        this.store.dispatch(editPrintOrder({ order, deliveryMethod, redirect }));
        this.store.dispatch(submittingForm({ submitting: true }));
      }
    } else if (this.createOrderForm.valid) {
      this.store.dispatch(editOrder({ order, deliveryMethod, redirect }));
      this.store.dispatch(submittingForm({ submitting: true }));
    }
  }

  saveOrder(order, deliveryMethod, redirect) {
    if (this.submitType === 'confirm') {
      if (notEnoughCredits(this.payments, this.credits, this.amount)) {
        const activity = 'saveOrder';
        this.openCreditsDialog(order, deliveryMethod, activity, redirect);
      } else {
        this.store.dispatch(savePrintOrder({ order, deliveryMethod, redirect }));
        if (!redirect) {
          this.resetForNewOrder();
        } else {
          this.store.dispatch(submittingForm({ submitting: true }));
        }
      }
    } else if (this.createOrderForm.valid) {
      this.store.dispatch(saveOrder({ order, deliveryMethod, redirect }));
      if (!redirect) {
        this.resetForNewOrder();
      } else {
        this.store.dispatch(submittingForm({ submitting: true }));
      }
    }
  }

  deepEqual(object1, object2) {
    if (object1 === undefined || object2 === undefined) {
      return false;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }

    return true;
  }

  isObject(object) {
    return object != null && typeof object === 'object';
  }

  handleUpdateRegion(address?: object) {
    const values = this.createOrderForm.value;
    if (values.warehouse) {
      const { warehouse: warehouseReference } = values.warehouse;

      const match = warehouseReference
        ? this.warehouses.find((warehouse) => {
            return String(warehouse.reference) === String(warehouseReference);
          })
        : this.warehouses.find((warehouse) => warehouse.primary);

      const to = address || this.toAddress;

      const from =
        {
          addressOne: match.address1,
          addressTwo: match.address2,
          city: match.city,
          postalCode: match.postalCode,
          suburb: match.suburb,
        } || this.fromAddress;

      if (
        this.deepEqual(from, this.fromAddress) === false ||
        this.deepEqual(to, this.toAddress) === false
      ) {
        if (from && to && this.process) {
          this.store.dispatch(getRegion({ from, to, processType: this.process }));
        }
      }

      if (address) {
        this.toAddress = address;
      }

      if (match) {
        this.fromAddress = from;
        this.selectedWarehouseCountryCode = match.country;
      }
    }
  }

  handleDoorDelivery(values: DeliveryDetails): Delivery {
    if (values) {
      const { warehouse } = values.warehouse;
      const { deliveryMethod } = values.deliveryMethod;

      const { addressOne, addressTwo, suburb, postalCode, city, province } =
        values.deliveryMethod[deliveryMethod];

      const { phone, phoneNumber, ...receiver } = values.receiver;
      const { weight, ...parcelDetails } = values.parcelDetails;

      return {
        warehouseAddressCode: warehouse,
        returnAddressCode: warehouse,
        trackingCode: null,
        cubicWeight: weight,
        ...parcelDetails,
        consignee: {
          ...receiver,
          phoneNumbers: [formatPhoneNumber(phone, this.selectedWarehouseCountryCode || 'ZA')],
          address1: addressOne,
          address2: addressTwo,
          suburb,
          postalCode,
          city,
          province,
          country: this.selectedWarehouseCountryCode || 'ZA',
        },
      };
    }
  }

  handlePickupPointDelivery(values) {
    const { warehouse } = values?.warehouse;
    const { phone, phoneNumber, ...receiver } = values.receiver;
    const { weight, ...parcelDetails } = values.parcelDetails;

    let ppCode = '';
    if (values && values.deliveryMethod) {
      const { deliveryMethod } = values.deliveryMethod;
      if (values.deliveryMethod[deliveryMethod]) {
        const { pickupPointCode, reference } = values.deliveryMethod[deliveryMethod];

        ppCode = pickupPointCode || reference;
      }
    }

    return {
      warehouseAddressCode: warehouse,
      returnAddressCode: warehouse,
      trackingCode: null,
      cubicWeight: weight,
      pickupPointCode: ppCode,
      ...parcelDetails,
      consignee: {
        ...receiver,
        phoneNumbers: [formatPhoneNumber(phone, this.selectedWarehouseCountryCode || 'ZA')],
        address1: null,
        address2: null,
        suburb: null,
        postalCode: null,
        city: null,
        country: null,
      },
    };
  }

  handleForm(): void {
    this.checkFormError();
    this.submitting = true;
    this.submitted = false;

    if (this.createOrderForm.valid) {
      const values = this.createOrderForm.value;
      let deliveryMethod: string = null;

      if (
        values.deliveryMethod &&
        this.checkNotEmpty(values.deliveryMethod[values.deliveryMethod.deliveryMethod])
      ) {
        ({ deliveryMethod } = values.deliveryMethod);

        const { another } = values;
        const redirect = another ? false : true;

        let order = {};

        switch (deliveryMethod) {
          case 'w2d':
            order = this.handleDoorDelivery(values);
            break;
          case 'w2p':
            order = this.handlePickupPointDelivery(values);
            break;
        }

        if (this.type === 'copy' || this.type === 'create') {
          this.saveOrder(order, deliveryMethod, redirect);
        }

        if (this.type === 'edit') {
          this.editOrder({ id: this.order.id, ...order }, deliveryMethod, redirect);
        }
      } else {
        this.errors.push({
          valid: false,
          message: 'Please select a Pick Up Point or enter a Home Delivery Address',
        });
        this.scrollTop();
      }
    }

    if (this.createOrderForm.invalid) {
      this.submitting = false;
      this.submitted = true;
      this.scrollTop();
    }
  }

  openCreditsDialog(
    order: Order,
    deliveryMethod: string,
    activity: string,
    redirect: boolean,
  ): void {
    const dialogConfig = createCreditsDialogConfig();
    const dialogRef = this.dialog.open(InfoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((action: string) => {
      if (action) {
        if (action === 'Buy Credits') {
          this.router.navigate(['/credits/buy']);
          redirect = false;
        }
        if (activity === 'saveOrder') {
          this.store.dispatch(saveOrder({ order, deliveryMethod, redirect }));
        } else {
          this.store.dispatch(editOrder({ order, deliveryMethod, redirect }));
        }
        if (redirect) {
          this.store.dispatch(submittingForm({ submitting: true }));
        } else {
          this.resetForNewOrder();
        }
      } else {
        this.submitting = false;
        this.store.dispatch(submittingForm({ submitting: false }));
      }
    });
  }

  resetForNewOrder(): void {
    this.createOrderForm.reset();
    this.scrollTop();
  }

  checkNotEmpty(input: any): boolean {
    if (input === null || input === undefined) {
      return false;
    }

    if (typeof input === 'object' && !Array.isArray(input)) {
      return Object.keys(input).length !== 0;
    }

    return true;
  }
}

import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/create';
import { actions as globalActions } from '../actions/globals';

export interface InitialState {
  type: 'copy' | 'edit' | 'create';
  order: object;
  submitting: boolean;
  region: string;
  upload: {
    loading: boolean;
    success: number[];
    error: number[];
  };
}

export const initialState: any = {
  type: 'create',
  order: undefined,
  submitting: false,
  region: 'main',
  upload: {
    loading: false,
    success: [],
    error: [],
  },
};

export const createReducerKey = 'create';

const createOrderReducer = createReducer(
  initialState,
  on(globalActions.copyOrder, (state: InitialState, { order }) => {
    return {
      ...state,
      type: 'copy',
      order,
    };
  }),
  on(globalActions.editOrder, (state: InitialState, { order }) => {
    return {
      ...state,
      type: 'edit',
      order,
    };
  }),
  on(globalActions.returnOrder, (state: InitialState, { order }) => {
    return {
      ...state,
      type: 'create',
      order,
    };
  }),
  on(actions.submittingForm, (state: InitialState, { submitting }) => {
    return {
      ...state,
      submitting,
    };
  }),
  on(actions.updateRegion, (state: InitialState, { region }) => {
    return {
      ...state,
      region: region === 'unknown' ? 'main' : region,
    };
  }),
  on(actions.submittedForm, (state: InitialState) => {
    return {
      ...state,
      type: 'create',
      order: undefined,
      submitting: false,
    };
  }),
  on(actions.resetForm, (state: InitialState) => {
    return {
      ...state,
      type: 'create',
      order: undefined,
      submitting: false,
    };
  }),
  on(actions.uploadOrders, (state: InitialState) => {
    return {
      ...state,
      upload: {
        loading: true,
        success: [],
        error: [],
      },
    };
  }),
  on(actions.uploadOrderSuccess, (state: InitialState, { row }) => {
    return {
      ...state,
      upload: {
        ...state.upload,
        success: [...state.upload.success, row],
      },
    };
  }),
  on(actions.uploadOrderError, (state: InitialState, { row }) => {
    return {
      ...state,
      upload: {
        ...state.upload,
        error: [...state.upload.error, row],
      },
    };
  }),
  on(actions.uploadOrdersDone, (state: InitialState) => {
    return {
      ...state,
      upload: {
        ...state.upload,
        loading: false,
      },
    };
  }),
);

export default function reducer(state, action) {
  return createOrderReducer(state, action);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { Dropdown } from './components/dropdown/dropdown';

@NgModule({
    declarations: [Dropdown],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [Dropdown]
})
export class DropdownModule {}

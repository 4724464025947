import { PluginConfig } from '../models/plugins';

export const SHOPIFY: PluginConfig = {
  logo: 'assets/images/supplier-plugins/shopify.svg',
  offers: ['Click and collect', 'Home delivery'],
  actionLabel: 'Connect now',
  action: 'dialog',
};

export const WOOCOMMERCE: PluginConfig = {
  logo: 'assets/images/supplier-plugins/woocommerce.svg',
  offers: ['Click and collect', 'Home delivery'],
  link: 'https://pargo.co.za/pargo-plugins/pargo-woocommerce-plugin/',
  actionLabel: 'Connect now',
  action: 'link',
};

export const PARGO_API: PluginConfig = {
  logo: 'assets/images/supplier-plugins/pargo.svg',
  offers: ['Click and collect', 'Home delivery', 'Returns', 'And more'],
  link: 'https://docs.pargo.co.za/',
  actionLabel: 'Access',
  action: 'link',
};

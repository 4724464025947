import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PluginConfig } from '../../models/plugins';

@Component({
  selector: 'app-plugin-card',
  templateUrl: './plugin-card.component.html',
  styleUrls: ['./plugin-card.component.scss'],
})
export class PluginCardComponent {
  @Input() pluginConfig: PluginConfig;
  @Output() pluginActivation = new EventEmitter<PluginConfig>();

  activatePlugin() {
    this.pluginActivation.emit(this.pluginConfig);
  }
}

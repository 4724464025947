import { Component, HostBinding, HostListener, Optional, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pargo-router-link',
  templateUrl: './router-link.html',
  styleUrls: ['./router-link.scss'],
})
export class RouterLink {
  @Input() routerLink: string;
  @Input() class?: string;
  @Input() target?: string;
  @Input() routerLinkActive?: string;
  @Input() queryParams?: object;

  externalLink = 'https://pargo.co.za';

  constructor(private router: Router) {}

  get isRouterLink(): boolean {
    if (this.routerLink && this.routerLink.includes(this.externalLink)) {
      return false;
    }

    if (this.routerLink && this.isExternalWebsite(this.routerLink)) {
      return false;
    }
    return true;
  }

  get externalLinkTarget(): string {
    if (this.target) {
      return this.target;
    }

    if (this.routerLink && this.routerLink.includes(this.externalLink)) {
      return '_self';
    }

    if (this.routerLink && this.isExternalWebsite(this.routerLink)) {
      return '_blank';
    }
  }

  get href(): any {
    return this.routerLink;
  }

  get classes(): string {
    return `router-link ${this.class}`;
  }

  get routerLinkUrl() {
    return [this.href, { ...this.queryParams }];
  }

  handleInternalRoute() {
    let url = undefined;

    if (typeof this.href === 'string') {
      url = this.href;
    }

    if (Array.isArray(this.href)) {
      url = this.href[0];
    }

    if (this.queryParams) {
      this.router.navigate([url], { queryParams: { ...this.queryParams } });
    } else {
      this.router.navigate([url]);
    }
  }

  isExternalWebsite(link): boolean {
    return link.includes('http://') || link.includes('https://');
  }
}

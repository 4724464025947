<section class="data-table">
  <div class="data-table__search" *ngIf="search === true">
    <div class="data-table__search-container">
      <input
        class="input__text data-table__search-input"
        value="{{searchValue}}"
        (keydown.enter)="handleSearch($event)"
        placeholder="Pickup point code, receiver, etc.."
      />
      <mat-icon class="data-table__search-icon" (click)="clearSearch()">
        {{searchValue ? 'close' : 'search'}}
      </mat-icon>
    </div>
    <pargo-dropdown
      class="available-credits__dropdown"
      label="Orders per page"
      [value]="currentChunkOption"
      [items]="chunkOptions"
      (change)="changeChunkSize($event)"
      [small]="true"
    ></pargo-dropdown>
  </div>

  <table class="data-table__table">
    <thead class="data-table__head">
      <tr class="data-table__head-row">
        <th class="data-table__head-cell data-table__head-cell--number">#</th>
        <th
          [class]="header === 'suburb postalcode' || header === 'area' ? 'data-table__head-cell data-table__head-cell--search' : 'data-table__head-cell'"
          *ngFor="let header of headers;"
          (click)="sortOrders(header)"
        >
          {{header}}
          <mat-icon class="data-table__head-icon">unfold_more</mat-icon>
        </th>
        <th
          [class]="delete ? 'data-table__head-cell data-table__head-cell--edit' : 'data-table__head-cell data-table__head-cell--edit data-table__head-cell--edit-delete'"
          *ngIf="edit"
        >
          edit
        </th>
        <th class="data-table__head-cell data-table__head-cell--delete" *ngIf="delete">delete</th>
      </tr>
    </thead>
    <tbody
      class="data-table__body"
      *ngIf="currentPage === undefined || (currentPage && currentPage.length === 0)"
    >
      <app-loading class="data-table__loading" title="Loading orders"> </app-loading>
    </tbody>
    <tbody class="data-table__body" *ngIf="currentPage && currentPage.length > 0">
      <tr
        [class]="editing === i ? 'data-table__body-row data-table__body-row--edit' : 'data-table__body-row'"
        *ngFor="let row of currentPage; let i = index"
      >
        <td
          [class]="this.errors && rowHasErrors(i) ? 'data-table__body-cell data-table__body-cell--number data-table__body-cell--number-error' : 'data-table__body-cell data-table__body-cell--number'"
        >
          {{row[0] + 1}}
        </td>

        <td
          [class]="tableBodyCellClass(this.errors && this.errors[row[0]] && this.errors[row[0]][j] === false, validation && validation[j].search, editing === i)"
          *ngFor="let value of removeIndex(row); let j = index"
        >
          <span *ngIf="isNonEditableCell(j) || showValue(row[0], j)">
            {{ displayValue(validation, j, value)}}
          </span>

          <pargo-input
            [class]="errors && errors[row[0]] && errors[row[0]][j] === false ? 'data-table__body-select data-table__body-select--error' : 'data-table__body-select'"
            [label]="
                    errors && errors[row[0]] && errors[row[0]][j] === false ? validation[j].message : headers[j]"
            *ngIf="!isNonEditableCell(j) && (editing === i  && validation && validation[j].options || errors && errors[row[0]] && errors[row[0]][j] === false && validation && validation[j].options)"
          >
            <select class="input data-table__body-select" (change)="editValue($event, row[0], j)">
              <option>Select an option</option>
              <option
                *ngFor="let option of validation[j].options"
                [value]="option.value"
                [selected]="toString(value) === toString(option.value)"
              >
                {{ option.label }}
              </option>
            </select>
          </pargo-input>

          <app-json-search
            *ngIf="editing === i && validation && validation[j].search || errors && errors[row[0]] && errors[row[0]][j] === false && validation && validation[j].search"
            [label]="validation[j].search.label"
            [processType]="processType"
            [indexes]="validation[j].search.indexes"
            [preliminarySuburb]="value.suburb"
            [preliminaryPostalCode]="value.postalCode"
            [noMatchesForPreliminaryAddress]="errors && errors[row[0]] && errors[row[0]][j] === false && validation && validation[j].search"
            [fields]="validation[j].search.fields"
            [displayFields]="validation[j].search.fields"
            [endpoints]="validation[j].search.endpoints"
            (search)="editSearchValue($event, row[0], j, validation[j].search.fields)"
          >
          </app-json-search>

          <pargo-input
            [class]="errors && errors[row[0]] && errors[row[0]][j] === false ? 'data-table__body-input data-table__body-input--error' : 'data-table__body-input'"
            [label]="errors && errors[row[0]] && errors[row[0]][j] === false ? validation[j].message : headers[j]"
            *ngIf="!isNonEditableCell(j) && (editing === i && validation && !validation[j].options && !validation[j].search || errors && errors[row[0]] && errors[row[0]][j] === false && validation && !validation[j].options && !validation[j].search)"
          >
            <input class="input" #input [value]="value" (change)="editValue($event, row[0], j)" />
          </pargo-input>
        </td>
        <td
          [class]="delete ? 'data-table__body-cell data-table__body-cell--edit' : 'data-table__body-cell data-table__body-cell--edit data-table__body-cell--edit-delete'"
          *ngIf="edit"
        >
          <mat-icon
            (click)="handleEdit(i)"
            svgIcon="edit-pargo"
            *ngIf="(editing !== i && editableRows === undefined) || (editing !== i && editableRows && editableRows[row[0]] === true)"
            class="data-table__body__icon"
          >
          </mat-icon>
          <mat-icon
            svgIcon="tick-pargo"
            (click)="saveEdit()"
            *ngIf="editing === i"
            class="data-table__body__icon"
          >
          </mat-icon>
        </td>
        <td class="data-table__body-cell data-table__body-cell--delete" *ngIf="delete">
          <mat-icon (click)="removeItem(i)" class="data-table__body__icon"> delete </mat-icon>
        </td>
      </tr>
    </tbody>
  </table>

  <ul class="data-table__pagination" *ngIf="chunks && chunks.length > 1">
    <li
      class="data-table__pagination-item"
      *ngIf="pagination.current "
      (click)="setPagination(pagination.current - 1)"
    >
      Previous
    </li>

    <li
      class="data-table__pagination-item"
      *ngFor="let previous of pagination.previous"
      (click)="setPagination(previous)"
    >
      {{previous + 1}}
    </li>

    <li
      class="data-table__pagination-item data-table__pagination-item--current"
      (click)="setPagination(pagination.current)"
    >
      {{current + 1}}
    </li>

    <li
      class="data-table__pagination-item"
      *ngFor="let next of pagination.next"
      (click)="setPagination(next)"
    >
      {{next + 1}}
    </li>

    <li
      class="data-table__pagination-item"
      *ngIf="pagination.current < chunks.length -1"
      (click)="setPagination(pagination.current  + 1)"
    >
      Next
    </li>
  </ul>
  <div class="data-table__actions" *ngIf="showActions">
    <button
      class="button button--secondary data-table__clear"
      (click)="clearOrders()"
      [disabled]="loading"
    >
      Clear
    </button>
    <button
      [class]="loading ? 'data-table__save loading' : 'data-table__save'"
      (click)="saveOrders()"
      [disabled]="loading || hasErrors === true || editing !== undefined"
    >
      <mat-icon
        *ngIf="loading"
        class="icon login__button-icon"
        svgIcon="pargo-arrow-pargo"
      ></mat-icon>
      Save & upload
    </button>
  </div>
</section>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../environments/environment';

import { WebConfigService } from './common/web-config.service';

const { apiUrl } = env;

@Injectable()
export class CoreService {
  constructor(protected http: HttpClient, private webConfig: WebConfigService) {}

  getProfile() {
    return this.http.get(`${apiUrl}/me`, this.webConfig.renderRequestHeaders());
  }

  getTransactions() {
    return this.http.get(`${apiUrl}/credits/transactions`, this.webConfig.renderRequestHeaders());
  }
}

import { createDialogConfig } from '@globalHelpers/dialog';
import { isBlank } from '@globalHelpers/functions';
import { COINS_IMG } from '@shared/constants/images';

export const downloadWaybill = (fileURL: string) => {
  const download = document.createElement('a');
  download.href = fileURL;
  download.target = '_blank';
  download.download = 'pargo-waybill.pdf';
  document.body.appendChild(download);
  download.click();
  document.body.removeChild(download);
};

export const createCreditsDialogConfig = () => {
  const primaryBtnLabel = 'Buy Credits';
  const secondaryBtnLabel = 'Buy Later';
  const heading = 'Uh oh, You Need Credits';
  const text =
    "Don't worry, your order will be saved in a pending state. You can confirm it after buying credits.";

  return createDialogConfig(primaryBtnLabel, heading, text, COINS_IMG, secondaryBtnLabel);
};

export const notEnoughCredits = (payments: any, credits: any, amount: number) => {
  return !isBlank(payments) && credits !== null && credits < amount;
};

import { CustomIcon } from '../core/models/icons';

export const DashboardIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Dashboard.svg',
  name: 'dashboard-pargo',
};

export const OrdersIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Orders.svg',
  name: 'orders-pargo',
};

export const OverviewIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Overview.svg',
  name: 'overview-pargo',
};

export const SearchIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Search.svg',
  name: 'search-pargo',
};

export const SendIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/SendParcel.svg',
  name: 'send-pargo',
};

export const FinanceIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Finance.svg',
  name: 'finance-pargo',
};

export const CourierIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Couriers.svg',
  name: 'courier-pargo',
};

export const AccountIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Account.svg',
  name: 'account-pargo',
};

export const SupportIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Support.svg',
  name: 'support-pargo',
};

export const AddIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Add.svg',
  name: 'add-pargo',
};

export const PargoArrowIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/PargoArrow.svg',
  name: 'pargo-arrow-pargo',
};

export const DateIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Date.svg',
  name: 'date-pargo',
};

export const WeightIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/ParcelWeight.svg',
  name: 'weight-pargo',
};

export const PargoPinIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/PargoPin.svg',
  name: 'pargo-pin-pargo',
};

export const SignOutIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/SignOut.svg',
  name: 'sign-out-pargo',
};

export const DeleteIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Delete.svg',
  name: 'delete-pargo',
};

export const CancelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Cancel.svg',
  name: 'cancel-pargo',
};

export const EditIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Edit.svg',
  name: 'edit-pargo',
};

export const FilterIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Filter.svg',
  name: 'filter-pargo',
};

export const HomeDeliveryIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/HomeDelivery.svg',
  name: 'home-delivery-pargo',
};

export const ParcelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Parcel.svg',
  name: 'parcel-pargo',
};

export const LargeParcelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/LargeParcel.svg',
  name: 'large-parcel-pargo',
};

export const listIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/List.svg',
  name: 'list-pargo',
};

export const MediumParcelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/MediumParcel.svg',
  name: 'medium-parcel-pargo',
};

export const PupIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Pup.svg',
  name: 'pup-pargo',
};

export const RecreateIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Recreate.svg',
  name: 'recreate-pargo',
};

export const SegmentIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Segment.svg',
  name: 'segment-pargo',
};

export const SmallParcelIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/SmallParcel.svg',
  name: 'small-parcel-pargo',
};

export const AddOrderIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/AddOrder.svg',
  name: 'add-order-pargo',
};

export const CreditsIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Credits.svg',
  name: 'credits-pargo',
};

export const ConfirmableOrderIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/ConfirmableOrder.svg',
  name: 'confirmable-order-pargo',
};

export const UnconfirmableOrderIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/UnconfirmableOrder.svg',
  name: 'unconfirmable-order-pargo',
};

export const PrintIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Print.svg',
  name: 'print-pargo',
};

export const WarehouseIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Warehouse.svg',
  name: 'warehouse-pargo',
};

export const PhoneIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Phone.svg',
  name: 'phone-pargo',
};

export const EmailIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Email.svg',
  name: 'email-pargo',
};

export const AvatarIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Avatar.svg',
  name: 'avatar-pargo',
};

export const SettingsIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Settings.svg',
  name: 'settings-pargo',
};

export const PinIcon: CustomIcon = {
  path: 'assets/images/toolbar-icons/Pin.svg',
  name: 'pin-pargo',
};

export const Instagram: CustomIcon = {
  path: 'assets/images/toolbar-icons/instagram.svg',
  name: 'instagram-pargo',
};

export const StocktakeHome: CustomIcon = {
  path: 'assets/images/toolbar-icons/StocktakeHome.svg',
  name: 'stocktake-home-pargo',
};

export const Facebook: CustomIcon = {
  path: 'assets/images/toolbar-icons/facebook.svg',
  name: 'facebook-pargo',
};

export const Twitter: CustomIcon = {
  path: 'assets/images/toolbar-icons/twitter.svg',
  name: 'twitter-pargo',
};

export const Linkedin: CustomIcon = {
  path: 'assets/images/toolbar-icons/linkedin.svg',
  name: 'linkedin-pargo',
};

export const Youtube: CustomIcon = {
  path: 'assets/images/toolbar-icons/youtube.svg',
  name: 'youtube-pargo',
};

export const Upload: CustomIcon = {
  path: 'assets/images/toolbar-icons/UploadFile.svg',
  name: 'upload-pargo',
};

export const Print: CustomIcon = {
  path: 'assets/images/toolbar-icons/Print.svg',
  name: 'print-pargo',
};

export const Business: CustomIcon = {
  path: 'assets/images/toolbar-icons/Business.svg',
  name: 'business-pargo',
};

export const About: CustomIcon = {
  path: 'assets/images/toolbar-icons/About.svg',
  name: 'about-pargo',
};

export const AboutV2: CustomIcon = {
  path: 'assets/images/toolbar-icons/AboutV2.svg',
  name: 'about-v2-pargo',
};

export const Shopper: CustomIcon = {
  path: 'assets/images/toolbar-icons/Shopper.svg',
  name: 'shopper-pargo',
};

export const BusinessV2: CustomIcon = {
  path: 'assets/images/toolbar-icons/BusinessV2.svg',
  name: 'business-v2-pargo',
};

export const Edit: CustomIcon = {
  path: 'assets/images/toolbar-icons/Edit.svg',
  name: 'edit-pargo',
};

export const Duplicate: CustomIcon = {
  path: 'assets/images/toolbar-icons/Duplicate.svg',
  name: 'duplicate-pargo',
};

export const Undo: CustomIcon = {
  path: 'assets/images/toolbar-icons/Undo.svg',
  name: 'undo-pargo',
};

export const ChevronDown: CustomIcon = {
  path: 'assets/images/toolbar-icons/ChevronDown.svg',
  name: 'chevron-down-pargo',
};

export const ChevronUp: CustomIcon = {
  path: 'assets/images/toolbar-icons/ChevronUp.svg',
  name: 'chevron-up-pargo',
};

export const ChevronRight: CustomIcon = {
  path: 'assets/images/toolbar-icons/ChevronRight.svg',
  name: 'chevron-right-pargo',
};

export const ChevronLeft: CustomIcon = {
  path: 'assets/images/toolbar-icons/ChevronLeft.svg',
  name: 'chevron-left-pargo',
};

export const Tick: CustomIcon = {
  path: 'assets/images/toolbar-icons/Tick.svg',
  name: 'tick-pargo',
};

export const MENU_ICONS: CustomIcon[] = [
  DashboardIcon,
  OrdersIcon,
  FinanceIcon,
  AccountIcon,
  SupportIcon,
  SignOutIcon,
  AddOrderIcon,
  CreditsIcon,
  StocktakeHome,
  Business,
  About,
  AboutV2,
  Shopper,
  BusinessV2,
];

export const HEADER_ICONS: CustomIcon[] = [
  SignOutIcon,
  AddOrderIcon,
  CreditsIcon,
  AvatarIcon,
  SettingsIcon,
  StocktakeHome,
  Business,
  About,
  AboutV2,
  Shopper,
  BusinessV2,
];

export const BATCH_ACTION_ICONS: CustomIcon[] = [AddIcon, OrdersIcon, FinanceIcon, AccountIcon];

export const ORDER_ICONS: CustomIcon[] | [] = [
  CourierIcon,
  DateIcon,
  PargoPinIcon,
  ParcelIcon,
  ConfirmableOrderIcon,
  UnconfirmableOrderIcon,
  PrintIcon,
  PupIcon,
  PinIcon,
  EmailIcon,
  PhoneIcon,
  MediumParcelIcon,
  SmallParcelIcon,
  Print,
  Edit,
  DeleteIcon,
  CancelIcon,
  Duplicate,
  Undo,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  ChevronDown,
  Tick,
];

export const TIMELINE_ICONS: CustomIcon[] = [AddIcon, OrdersIcon, FinanceIcon, AccountIcon];

export const LOGIN_ICONS: CustomIcon[] = [PargoArrowIcon];

export const FILTER_ICONS: CustomIcon[] = [PargoArrowIcon, SignOutIcon, FilterIcon];

export const MODAL_ICONS: CustomIcon[] = [AddIcon];

export const LOADING_ICONS: CustomIcon[] = [PargoArrowIcon];

export const PAYMENT_ICONS: CustomIcon[] = [
  ParcelIcon,
  SmallParcelIcon,
  MediumParcelIcon,
  LargeParcelIcon,
];

export const CREATE_ICONS: CustomIcon[] = [
  SmallParcelIcon,
  MediumParcelIcon,
  LargeParcelIcon,
  WeightIcon,
];

export const SHIPMENT_DETAIL_ICONS: CustomIcon[] = [PupIcon, PargoArrowIcon];

export const WAREHOUSE_ICONS: CustomIcon[] = [WarehouseIcon, PhoneIcon, EmailIcon, PinIcon];

export const SOCIAL_ICONS: CustomIcon[] = [Facebook, Instagram, Twitter, Linkedin, Youtube];

export const UPLOAD_ICONS: CustomIcon[] = [StocktakeHome, PargoArrowIcon, Upload];

export const BUTTON_ICONS: CustomIcon[] = [PargoArrowIcon];

import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ShopifyDialogComponent } from '../../components/shopify-dialog/shopify-dialog.component';

import { SHOPIFY, WOOCOMMERCE, PARGO_API } from '../../constants/plugin-config';
import { PluginConfig } from '../../models/plugins';

@Component({
  templateUrl: './connect-store.component.html',
  styleUrls: ['./connect-store.component.scss'],
})
export class ConnectStorePageComponent {
  readonly ShopifyConfig = SHOPIFY;
  readonly WoocommerceConfig = WOOCOMMERCE;
  readonly PargoApiConfig = PARGO_API;

  constructor(private dialog: MatDialog) {}

  activatePlugin(pluginConfig: PluginConfig) {
    if (pluginConfig.action === 'link') {
      window.open(pluginConfig.link, '_blank');
    } else {
      this.dialog.open(ShopifyDialogComponent, {
        disableClose: true,
        autoFocus: true,
      });
    }
  }
}

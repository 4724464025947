import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { InitialState } from '../../../reducers/create';
import { convertP2wReturnForm, formatPhoneNumber } from '../../../helpers/orders';
import {
  submittingForm,
  saveReturnOrder,
  getRegion,
  savePrintReturnOrder,
} from '../../../actions/create';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { Order } from '@orders/models/orders';

import {
  selectCredits,
  selectWarehouses,
  selectPayments,
  selectSubmitting,
  selectType,
  selectOrder,
  selectRegion,
  selectUser,
} from '../../../selectors/create';
import { ReturnDetails, Return } from './models/return-order-form';
import { createCreditsDialogConfig, notEnoughCredits } from '@create/helpers/functions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-return-order-form',
  templateUrl: './return-order-form.html',
  styleUrls: ['./return-order-form.scss'],
})
export class ReturnOrderFormComponent implements OnInit, OnDestroy {
  createOrderForm: UntypedFormGroup = this.formBuilder.group({
    parcelDetails: this.formBuilder.control({}),
    receiver: this.formBuilder.control({}),
    returnMethod: this.formBuilder.control({}),
    warehouse: this.formBuilder.control({}),
    another: [''],
  });

  submitType: string | undefined = undefined;
  warehouses$: Observable<object[]>;
  credits$: Observable<number>;
  payments$: Observable<object>;
  submitting$: Observable<boolean>;
  order$: Observable<object>;
  type$: Observable<string>;
  region$: Observable<string>;

  credits: number | undefined = undefined;
  payments: any | undefined = undefined;
  sufficientCredits: boolean = true;
  order: any = undefined;
  process: string = 'p2w_return';
  type: string = 'create';
  amount: number = 0;

  warehouses: any[];

  region: string = 'main';
  toAddress: object;
  selectedWarehouseCountryCode: string;

  submitting: boolean = false;
  submitted: boolean = false;

  creditInformation = undefined;
  errors: any[] = [];
  subscriptions: Subscription[] = [];
  user$: Observable<object>;

  constructor(
    public store: Store<InitialState>,
    public formBuilder: UntypedFormBuilder,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.warehouses$ = this.store.select(selectWarehouses);
    this.credits$ = this.store.select(selectCredits);
    this.payments$ = this.store.select(selectPayments);
    this.submitting$ = this.store.select(selectSubmitting);
    this.order$ = this.store.select(selectOrder);
    this.type$ = this.store.select(selectType);
    this.region$ = this.store.select(selectRegion);
    this.user$ = this.store.select(selectUser);
  }

  ngOnInit() {
    const creditsSubscription = this.credits$.subscribe((credits) => {
      this.credits = credits;
    });

    this.subscriptions.push(creditsSubscription);

    const paymentsSubscription = this.payments$.subscribe((payments) => {
      this.payments = payments;
    });
    this.subscriptions.push(paymentsSubscription);

    const warehouseSubscription = this.warehouses$.subscribe((warehouses) => {
      this.warehouses = warehouses;
    });
    this.subscriptions.push(warehouseSubscription);

    const typeSubscription = this.type$.subscribe((type) => {
      this.type = type;

      if (type === 'create') {
        this.createOrderForm.reset();
      }
    });
    this.subscriptions.push(typeSubscription);

    const regionSubscription = this.region$.subscribe((region) => {
      if (region !== this.region) {
        this.region = region;
        this.calculateAmount();
      }
    });
    this.subscriptions.push(regionSubscription);

    const ordersSubscription = this.order$.subscribe((payload: { order; warehouse }) => {
      if (!this.submitting) {
        this.mapToForm(payload);
      }
      this.order = payload.order;
    });
    this.subscriptions.push(ordersSubscription);

    const formSubscription = this.createOrderForm.statusChanges.subscribe(() => {
      this.handleUpdateRegion();
      this.calculateAmount();
    });
    this.subscriptions.push(formSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  get formValues() {
    return this.createOrderForm.value;
  }

  updateReturnMethod(process: string) {
    this.process = process;
  }

  mapToForm({ order, warehouse }) {
    let form;
    if (order) {
      form = convertP2wReturnForm(order, warehouse);

      this.createOrderForm.patchValue({
        ...form,
      });
    }
  }

  checkFormError() {
    this.errors = [];
    Object.keys(this.createOrderForm.controls).forEach((key: string) => {
      const controls = this.createOrderForm.controls;
      this.errors.push(controls[key].errors);
    });
    this.errors = this.errors.filter((item) => item !== null);
  }

  handleUpdateRegion(address?: object) {
    const values = this.createOrderForm.value;
    if (values.warehouse) {
      const { warehouse: warehouseReference } = values.warehouse;

      const match = warehouseReference
        ? this.warehouses.find((warehouse: any) => {
            return String(warehouse.reference) === String(warehouseReference);
          })
        : this.warehouses.find((warehouse) => warehouse.primary);

      const to = address || this.toAddress;

      this.selectedWarehouseCountryCode = match.country;

      if (address) {
        this.toAddress = address;
      }

      const from = {
        addressOne: match.address1,
        addressTwo: match.address2,
        city: match.city,
        postalCode: match.postalCode,
        suburb: match.suburb,
      };

      if (from && to && this.process) {
        this.store.dispatch(getRegion({ from, to, processType: this.process }));
      }
    }
  }

  calculateAmount() {
    const values = this.createOrderForm.value;
    const { parcelDetails, returnMethod } = values;

    const returnProcess =
      returnMethod && returnMethod.returnMethod !== null ? returnMethod.returnMethod : this.process;

    if (
      returnProcess &&
      this.payments !== null &&
      this.payments !== undefined &&
      returnProcess &&
      this.payments[returnProcess] &&
      parcelDetails &&
      parcelDetails.weight &&
      this.payments[returnProcess][this.region][parcelDetails.weight]
    ) {
      this.amount = this.payments[returnProcess][this.region][parcelDetails.weight];
    }
  }

  setSubmitType(type: string) {
    this.submitType = type;
  }

  scrollTop() {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

    if (supportsNativeSmoothScroll) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
  }

  saveOrder(order: Order | {}, returnMethod: string, redirect: boolean) {
    if (this.submitType === 'confirm') {
      if (notEnoughCredits(this.payments, this.credits, this.amount)) {
        const activity = 'saveOrder';
        this.openCreditsDialog(order, returnMethod, activity, redirect);
      } else {
        this.store.dispatch(savePrintReturnOrder({ order, returnMethod, redirect }));
        if (!redirect) {
          this.resetForNewOrder();
        } else {
          this.store.dispatch(submittingForm({ submitting: true }));
        }
      }
    } else if (this.createOrderForm.valid) {
      this.store.dispatch(saveReturnOrder({ order, returnMethod, redirect }));
      if (!redirect) {
        this.resetForNewOrder();
      } else {
        this.store.dispatch(submittingForm({ submitting: true }));
      }
    }
  }

  handleForm(): void {
    this.checkFormError();
    this.submitting = true;
    this.submitted = false;

    if (this.createOrderForm.valid) {
      const values = this.createOrderForm.value;
      let returnMethod: string = null;

      if (values?.returnMethod?.p2w_return || values?.returnMethod?.d2w) {
        ({ returnMethod } = values.returnMethod);

        const { another } = values;
        const redirect = another ? false : true;

        let order = {};

        switch (returnMethod) {
          case 'p2w_return':
            order = this.handlePickupPointReturn(values);
            break;
          case 'd2w':
            order = this.handleDoorReturn(values);
            break;
        }

        if (this.type === 'create') {
          this.saveOrder(order, returnMethod, redirect);
        }
      } else {
        this.errors.push({
          valid: false,
          message: 'Please select a Pick Up Point or enter a Home Delivery Address',
        });
        this.scrollTop();
      }
    }

    if (this.createOrderForm.invalid) {
      this.submitting = false;
      this.submitted = true;
      this.scrollTop();
    }
  }

  handlePickupPointReturn(values) {
    const { warehouse } = values?.warehouse;
    const { phone, phoneNumber, ...receiver } = values.receiver;
    const { weight, ...parcelDetails } = values.parcelDetails;

    let ppCode = '';
    if (values && values.returnMethod) {
      const { returnMethod } = values.returnMethod;
      if (values.returnMethod[returnMethod]) {
        const { pickupPointCode, reference } = values.returnMethod[returnMethod];

        ppCode = pickupPointCode || reference;
      }
    }

    return {
      warehouseAddressCode: warehouse,
      returnAddressCode: warehouse,
      trackingCode: null,
      cubicWeight: weight,
      pickupPointCode: ppCode,
      ...parcelDetails,
      consignee: {
        ...receiver,
        phoneNumbers: [formatPhoneNumber(phone, this.selectedWarehouseCountryCode || 'ZA')],
        address1: null,
        address2: null,
        suburb: null,
        postalCode: null,
        city: null,
        country: null,
      },
    };
  }

  handleDoorReturn(values: ReturnDetails): Return {
    if (values) {
      const { warehouse } = values.warehouse;
      const { returnMethod } = values.returnMethod;

      const { addressOne, addressTwo, suburb, postalCode, city } =
        values.returnMethod[returnMethod];

      const { phone, phoneNumber, ...receiver } = values.receiver;
      const { weight, ...parcelDetails } = values.parcelDetails;

      return {
        warehouseAddressCode: warehouse,
        returnAddressCode: warehouse,
        trackingCode: null,
        cubicWeight: weight,
        ...parcelDetails,
        consignee: {
          ...receiver,
          phoneNumbers: [formatPhoneNumber(phone, this.selectedWarehouseCountryCode || 'ZA')],
          address1: addressOne,
          address2: addressTwo,
          suburb: suburb,
          postalCode: postalCode,
          city: city,
          country: this.selectedWarehouseCountryCode || 'ZA',
        },
      };
    }
  }

  openCreditsDialog(
    order: Order | {},
    returnMethod: string,
    activity: string,
    redirect: boolean,
  ): void {
    const dialogConfig = createCreditsDialogConfig();
    const dialogRef = this.dialog.open(InfoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((action: string) => {
      if (action) {
        if (action === 'Buy Credits') {
          this.router.navigate(['/credits/buy']);
          redirect = false;
        }
        this.store.dispatch(saveReturnOrder({ order, returnMethod, redirect }));
        if (redirect) {
          this.store.dispatch(submittingForm({ submitting: true }));
        } else {
          this.resetForNewOrder();
        }
      } else {
        this.submitting = false;
        this.store.dispatch(submittingForm({ submitting: false }));
      }
    });
  }

  resetForNewOrder(): void {
    this.createOrderForm.reset();
    this.scrollTop();
  }
}

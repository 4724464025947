import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { PargoArrow } from '../../constants/icons';
import { CustomIcon } from '../../models/icon';

@Component({
  selector: 'pargo-cta',
  templateUrl: './cta.html',
  styleUrls: ['./cta.scss'],
})
export class Cta {
  @Input() label: string;
  @Input() routerLink: string;
  @Input() class?: string;
  @Input() icon?: string;
  @Input() secondary?: boolean;
  @Input() type?: string;
  @Input() queryParams?: object;

  clicked: boolean = false;
  clickedRevertTimout: number = 500;

  pargoIconPrefix = 'pargo';
  loadingIcon = 'pargo-arrow-pargo';

  customButtonIcons: CustomIcon[] = [PargoArrow];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.customButtonIcons.forEach(({ path, name }: CustomIcon): void => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  get className(): string {
    if (this.type === 'link') {
      return `cta cta__link ${this.secondary ? ' cta__link--secondary' : ''} ${this.class}`;
    }

    if (this.type === 'button') {
      return `cta cta__button ${this.secondary ? ' cta__button--secondary' : ''} ${this.class}`;
    }
  }

  get iconType(): boolean {
    return this.icon && this.icon.includes(this.pargoIconPrefix);
  }

  handleClick(): void {
    this.handleClicked();
  }

  handleClicked(): void {
    this.clicked = true;
    setTimeout(() => {
      this.clicked = false;
    }, this.clickedRevertTimout);
  }
}

<section class="upload container">
  <h2 class="heading upload__heading">
    upload orders
    <span class="upload__errors" *ngIf="tableData && tableData.length && errors">
      {{errorAmount}} {{errorAmount === 1 ? 'order' : 'orders'}} {{errorAmount === 1 ? 'needs' :
      'need'}} adjustment{{errorAmount === 1 ? '' : 's'}}
    </span>
  </h2>

  <app-upload-files
    class="upload__files"
    *ngIf="tableData === undefined"
    [cb]="handleFiles"
    [extentions]="extentions"
    [generate]="generateExcel"
    [generateOptions]="generateOptions"
    [max]="1"
  ></app-upload-files>
  <app-data-table
    class="upload__data-table"
    [data]="tableData"
    [validation]="validation"
    search
    *ngIf="tableData && tableData.length"
    (handleSaveOrders)="uploadOrders($event)"
    [search]="true"
    [processType]="currentProcess"
    [editable]="false"
    [loading]="loading"
    (handleClearOrders)="clearOrders()"
    (containsErrors)="handleContainsErrors($event)"
    [disableAddressValidation]="true"
  >
  </app-data-table>
</section>

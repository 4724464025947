import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { FaqListComponent } from './components/faq-list/faq-list';
import { InformationPillComponent } from './components/information-pill/information-pill';

@NgModule({
    declarations: [FaqListComponent, InformationPillComponent],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [FaqListComponent, InformationPillComponent]
})
export class FaqListModule {}

import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  constructor() {}

  public getItem(name: string): any {
    return this.parseItem(localStorage.getItem(name));
  }

  public setItem(name: string, value: any): any {
    return localStorage.setItem(name, this.stringifyItem(value));
  }

  public hasItem(name): boolean {
    return !!this.getItem(name);
  }

  public removeItem(name: string): boolean {
    if (this.hasItem(name)) {
      localStorage.removeItem(name);
      return true;
    }
    return false;
  }

  public parseItem(item: string): any {
    if (item) {
      return JSON.parse(item);
    }
    return undefined;
  }

  public stringifyItem(item): string {
    return JSON.stringify(item);
  }
}

<div class="shopify-dialog">
  <div class="shopify-dialog__close-button-container">
    <span (click)="close()" class="shopify-dialog__close-button">x</span>
  </div>
  <div class="shopify-dialog__logo-container">
    <img class="shopify-dialog__logo" src="assets/images/supplier-plugins/shopify.svg" />
  </div>
  <h2 class="shopify-dialog__heading">Connect Your Shopify Store</h2>

  <form [formGroup]="form" class="shopify-dialog__form" validate (ngSubmit)="onSubmit()">
    <pargo-input
      label="eg. https://my-online-store.myshopify.com"
      formName="storeUrl"
      [form]="form"
    >
      <input class="input" type="text" formControlName="storeUrl" />
    </pargo-input>
    <p class="shopify-dialog__form-text">
      By entering your store URL, you will allow myPargo to connect easily to your Shopify store
      account.
    </p>
    <div class="shopify-dialog__button-container">
      <pargo-button type="submit" label="Connect my store"></pargo-button>
    </div>

    <p class="shopify-dialog__form-text shopify-dialog__form-text--black">
      Don’t have a Shopify store?
      <a class="shopify-dialog__form-link" href="https://www.shopify.com/" target="_blank"
        >Create an account</a
      >
    </p>
  </form>
</div>

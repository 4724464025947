<div class="auth-form-welcome-header">
  <div class="auth-form-welcome-header__logo-container">
    <img class="auth-form-welcome-header__logo-container-img" src="assets/images/logo.png" />
  </div>
  <div class="auth-form-welcome-header__heading-container">
    <h2 class="auth-form-welcome-header__heading-container-heading">
      Welcome to <br />
      MyPargo
    </h2>
  </div>
</div>

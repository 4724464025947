import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConnectStoreRoutingModule } from './connect-store-routing.module';

import { ConnectStorePageComponent } from './pages/connect-store/connect-store.component';

import { PluginCardComponent } from './components/plugin-card/plugin-card.component';

import { SharedModule } from '../shared';
import { WalkthroughModule } from '../walkthrough';
import { ShopifyDialogComponent } from './components/shopify-dialog/shopify-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [ConnectStorePageComponent, PluginCardComponent, ShopifyDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ConnectStoreRoutingModule,
        SharedModule,
        WalkthroughModule,
    ],
    providers: [],
    exports: [ConnectStorePageComponent],
    bootstrap: []
})
export class ConnectStoreModule {}

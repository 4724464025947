<div [class]="className">
  <div class="card-information__icon" *ngIf="icon">
    <mat-icon>{{icon}}</mat-icon>
  </div>
  <div class="card-information__header">
    <h4 class="card-information__title">{{title}}</h4>
    <div class="card-information__description" *ngIf="description" [innerHTML]="description"></div>
    <app-modal-button
      [component]="modal.component"
      [icon]="modal.icon"
      [confirm]="modal.fn"
      [label]="modal.label"
      [classEnhance]="modal.class"
      [type]="modal.type"
      *ngIf="modal"
    >
    </app-modal-button>
  </div>

  <div class="card-information__date" *ngIf="date">
    {{ formatDate(date) }} {{ formatTime(date) }}
  </div>
</div>

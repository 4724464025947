import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { scrollIntoViewIfNeeded } from '../../helpers/element';

import { disableWalkthrough, showStep } from '../../actions/walkthrough';

import {
  selectWalkthroughEnabled,
  selectWalkthroughCurrent,
  selectWalkthroughSteps,
  selectWalkthroughNavigation,
} from '../../selectors/walkthrough';

interface Step {
  id: string;
  page: string;
  text: string;
  title: string;
}

@Component({
  selector: 'app-walkthrough-explanation',
  templateUrl: './walkthrough-explanation.html',
  styleUrls: ['./walkthrough-explanation.scss'],
})
export class WalkthroughExplanationComponent implements OnInit {
  enabled$: Observable<boolean>;
  enabled: boolean;

  current$: Observable<any>;
  current: Step;

  steps: Step[];
  steps$: Observable<{ steps: Step[] }>;

  navigation$: Observable<{ navigation: object }>;
  navigation: { next: string; previous: string; current: string; pages: string[] };

  element: HTMLElement;

  constructor(private store: Store<any>, private router: Router) {
    this.enabled$ = this.store.select(selectWalkthroughEnabled);
    this.current$ = this.store.select(selectWalkthroughCurrent);
    this.steps$ = this.store.select(selectWalkthroughSteps);
    this.navigation$ = this.store.select(selectWalkthroughNavigation);
  }

  ngOnInit() {
    this.enabled$.subscribe((enabled) => {
      this.enabled = enabled;
      this.setHighlightedElement();

      if (enabled === false) {
        this.removeHighlightedElement();
      }
    });

    this.current$.subscribe((current) => {
      if (this.current === undefined) {
        this.current = current;
        this.setHighlightedElement();
      } else {
        if (current && current.id !== this.current.id) {
          this.current = current;
          this.removeHighlightedElement();
          this.setHighlightedElement();
        }
      }
    });

    this.steps$.subscribe(({ steps }) => {
      this.steps = steps;
    });

    this.navigation$.subscribe(({ navigation }: any) => {
      this.navigation = navigation;
    });
  }

  setHighlightedElement() {
    if (this.current && this.enabled) {
      const { attachedToElement }: any = this.current;

      if (attachedToElement && this.current) {
        setTimeout(() => {
          this.element = document.querySelector(attachedToElement);

          if (this.element) {
            const { id }: any = this.current;
            this.element.classList.add(id);
            this.element.classList.add('walkthrough-highlighted');
            scrollIntoViewIfNeeded(this.element);
          }
        }, 10);
      }
    }
  }

  removeHighlightedElement() {
    if (this.element) {
      if (this.current && this.current.id) {
        const { id }: any = this.current;
        this.element.classList.remove(id);
      }
      this.element.classList.remove('walkthrough-highlighted');
    }
  }

  nextStep() {
    let next = undefined;
    this.removeHighlightedElement();

    this.steps.forEach((step: any, index) => {
      if (this.current.id === step.id) {
        if (index === this.steps.length - 1) {
          next = false;
          return;
        } else {
          next = this.steps[index + 1];
          return;
        }
      }
    });

    if (next) {
      this.store.dispatch(showStep({ id: next.id }));
    } else {
      this.router.navigate([this.navigation.next]);
    }
  }

  previousStep() {
    let previous = undefined;
    this.removeHighlightedElement();

    this.steps.forEach((step: any, index) => {
      if (this.current.id === step.id) {
        if (index === 1) {
          previous = false;
          return;
        } else {
          previous = this.steps[index - 1];
          return;
        }
      }
    });

    if (previous) {
      this.store.dispatch(showStep({ id: previous.id }));
    } else {
      this.router.navigate([this.navigation.previous]);
    }
  }

  closeWalkthrough() {
    this.store.dispatch(disableWalkthrough());
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ContainerHeadingModule } from '../../typography/container-heading/container-heading.module';
import { ContainerModule } from '../../layout/container/container.module';
import { ShadowboxModule } from '../../layout/shadow-box/shadowbox.module';

import { FaqListModule } from '../../molecules/faq-list/faq-list.module.ts';

import { Faq } from './components/faq/faq';

@NgModule({
    declarations: [Faq],
    imports: [
        CommonModule,
        MatIconModule,
        ContainerHeadingModule,
        ContainerModule,
        ShadowboxModule,
        FaqListModule,
    ],
    providers: [],
    exports: [Faq]
})
export class FaqModule {}

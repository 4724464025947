import { Component, Input } from '@angular/core';

import { isBlank } from '../../../../../../../helpers/functions';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.html',
  styleUrls: ['./faq.scss'],
})
export class Faq {
  @Input() heading: string;
  @Input() subtitle?: string;
  @Input() footerSubtitle?: string;
  @Input() faq: any;
  @Input() current?: number = 0;

  constructor() {}

  get items() {
    if (!isBlank(this.current)) {
      return this.faq[this.current].items;
    }

    return undefined;
  }

  handleClick(id: number) {
    if (this.current === id) {
      this.current = undefined;
    } else {
      this.current = id;
    }
  }
}

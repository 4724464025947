import {
  Component,
  Input,
  ViewChildren,
  QueryList,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import * as R from 'ramda';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { TABLE_ICONS } from '../../constants/icons';
import { CustomIcon } from '../../models/icon';
import { WarehouseSelectionOptions, WarehouseType } from '../../models/warehouse-options';

@Component({
  selector: 'pargo-information-table',
  templateUrl: './information-table.html',
  styleUrls: ['./information-table.scss'],
})
export class InformationTable implements OnInit, OnChanges {
  @Input() headers: string[];
  @Input() sortableColumns: number[];
  @Input() mobileStickyColumns: number[];

  @Input() chunkSize: number = 5;
  @Input() data: object[];

  @Input() loading?: boolean;
  @Input() search?: boolean;
  @Input() showIndex?: boolean;
  @Input() columnWidths?: number[];

  @Output() updateSelectedWarehouse = new EventEmitter<WarehouseSelectionOptions>();
  @Output() warehouseToEdit = new EventEmitter<string>();

  @ViewChildren('valueContainer') valueContainers: QueryList<any>;

  chunkIndex: number = 0;
  chunks: any = [];
  current: number = 0;
  dataMeetingCriteria: object[];

  sort: { direction: string; by: string } = { direction: 'asc', by: '' };

  searchResults: any = undefined;
  searchValue: string = undefined;
  searchFn = (val) =>
    R.filter(
      R.compose(
        R.any((value) =>
          R.contains(val.toLowerCase(), value ? value.toString().toLowerCase() : ''),
        ),
        R.values,
      ),
    );

  pagination: {
    current: number;
    steps: number;
    previous: number[];
    next: number[];
  } = {
    previous: [],
    next: [],
    current: 0,
    steps: 0,
  };

  currentPage: object[];

  editing: number;

  initialValidation: boolean = false;

  chunkOptions = [
    {
      label: '5',
      value: 5,
    },
    {
      label: '10',
      value: 10,
    },
    {
      label: '25',
      value: 25,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ];

  currentChunkOption = {
    label: '5',
    value: 5,
  };

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    TABLE_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  ngOnInit() {
    this.setHeaders();
    this.chunkData();
  }

  ngOnChanges() {
    this.setHeaders();
    this.chunkData();
  }

  get searchPlaceholder() {
    const searchText = this.headers.slice(0, 3);
    return `Search ...`;
  }

  get totalShowing(): number {
    if (this.current === this.chunks.length - 1) {
      return this.dataMeetingCriteria.length;
    }
    return this.chunkSize * this.current + this.chunkSize;
  }

  wrapperClass(number: number): string {
    return `wrapper-class--${number}`;
  }

  resolveValue(value) {
    if (typeof value !== 'object') {
      return false;
    }

    if (Array.isArray(value)) {
      const [_, type] = value;
      return type;
    }
  }

  isSortableColumn(i) {
    if (i && this.sortableColumns) {
      return this.sortableColumns.indexOf(i) !== -1;
    }
  }

  isMobileStickyColumn(i) {
    if (i && this.mobileStickyColumns) {
      return this.mobileStickyColumns.indexOf(i) !== -1;
    }
  }

  changeChunkSize(e) {
    this.currentChunkOption = e;
    const { value } = e;
    this.chunkSize = value;
    this.chunkData(this.dataMeetingCriteria);
  }

  displayOptionValue(options, value) {
    const option = options.find((option) => option.value == value);
    if (option) {
      return option.label;
    }
  }

  isBlank(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value.length === 0 ||
      (typeof value == 'string' && !!value.match(/^\s*$/)) ||
      (typeof value == 'object' && Object.keys(value).length == 0)
    );
  }

  setHeaders() {
    if (!this.headers && this.data && this.data.length > 0) {
      const [first] = this.data;
      this.headers = Object.keys(first);
    }
  }

  setCurrentPage() {
    if (this.chunks[this.current]) {
      this.currentPage = this.chunks[this.current];
    } else {
      this.current = this.chunks.length - 1;
      this.pagination.current = this.current;
      this.currentPage = this.chunks[this.current];
    }
  }

  async chunkData(data = this.data) {
    this.dataMeetingCriteria = data;
    this.chunkIndex = 0;
    this.chunks = [];

    if (data && data.length < this.chunkSize) {
      let index = 0;

      if (this.showIndex) {
        this.chunks[0] = data.map((row) => {
          const values = Object.values(row);
          const addedIndex = [index, ...values];
          index++;
          return addedIndex;
        });
      } else {
        this.chunks[0] = data;
      }
    } else if (data && data.length >= this.chunkSize) {
      let index = 0;

      while (this.chunkIndex < data.length) {
        const items = data.slice(this.chunkIndex, this.chunkSize + this.chunkIndex);

        if (this.showIndex) {
          const itemsValueIndex = items.map((row) => {
            const values = Object.values(row);
            const addedIndex = [index, ...values];
            index++;
            return addedIndex;
          });
          this.chunks.push(itemsValueIndex);
        } else {
          this.chunks.push(items);
        }

        this.chunkIndex += this.chunkSize;
      }
    }

    this.setPagination();
  }

  removeIndex(row) {
    const [_, ...rest] = row;
    return rest;
  }

  async sortItems(by) {
    const { direction: currentDirection } = this.sort;
    const direction = currentDirection === 'asc' ? 'desc' : 'asc';
    this.sort = { direction, by };

    this.data = await this.sortFn();
    this.chunkData();
  }

  handleSearchByEnterKey(event: { preventDefault: () => void; target: { value: any } }): void {
    event.preventDefault();
    const value = event.target.value;
    this.searchData(value);
  }

  searchData(value: string): void {
    const result = this.searchFn(value)(this.data);
    this.chunkData(result);
    this.searchValue = value;
  }

  handleSearchIconClick(value: string): void {
    if (this.searchValue) {
      this.clearSearch();
    } else {
      this.searchData(value);
    }
  }

  clearSearch() {
    this.searchValue = undefined;
    this.chunkData();
  }

  async sortFn() {
    const { by, direction } = this.sort;

    const order =
      direction === 'asc' ? R.ascend(R.path(by.split('|'))) : R.descend(R.path(by.split('|')));

    return R.sort(order, this.data);
  }

  async setPagination(current = this.current) {
    const steps = this.chunks.length;

    const previous = this.chunks.slice(0, current);
    const next = this.chunks.slice(current, this.chunks.length);

    this.pagination = {
      steps,
      previous: previous.length,
      next: next.length,
      current: current,
    };

    this.current = current;
    this.setCurrentPage();
  }

  warehouseSelection(warehouseType: WarehouseType, value: boolean, referenceArr: string[]): void {
    const selectionOptions: WarehouseSelectionOptions = {
      warehouseType,
      value,
      reference: referenceArr[0],
    };
    this.updateSelectedWarehouse.emit(selectionOptions);
  }

  isWarehouseSelection(option: any): boolean {
    const value = this.resolveValue(option);
    return value === WarehouseType.RETURN || value === WarehouseType.PRIMARY;
  }

  selectWarehouseToEdit(reference: string): void {
    this.warehouseToEdit.emit(reference);
  }

  formatWarehouseReference(reference: string): string {
    const maxDisplayLength = 12;
    return reference.length > maxDisplayLength
      ? reference.substring(0, maxDisplayLength) + '...'
      : reference;
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { PargoArrowIcon } from '../../../../../../../icons';
import { CustomIcon } from '../../../../../../../core/models/icons';

@Component({
  selector: 'pargo-button',
  templateUrl: './button.html',
  styleUrls: ['./button.scss'],
})
export class Button {
  @Input() label: string;
  @Input() class?: string;
  @Input() icon?: string;
  @Input() secondary?: boolean;
  @Input() loading?: boolean;
  @Input() type?: 'submit' | 'button';
  @Input() disabled?: boolean;

  @Output() cb = new EventEmitter();

  clicked: boolean = false;
  clickedRevertTimout: number = 500;

  pargoIconPrefix = 'pargo';
  loadingIcon = 'pargo-arrow-pargo';

  customButtonIcons: CustomIcon[] = [PargoArrowIcon];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.customButtonIcons.forEach(({ path, name }: CustomIcon): void => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  get className(): string {
    return `button${this.secondary ? ' button--secondary' : ''}${this.loading ? ' loading' : ''} ${
      this.class
    }`;
  }

  get iconType(): boolean {
    this.setIcon();
    return this.icon && this.icon.includes(this.pargoIconPrefix);
  }

  setIcon(): void {
    if (this.loading) {
      setTimeout(() => {
        this.icon = this.loadingIcon;
      });
    }
  }

  handleClick(): void {
    this.handleClicked();

    this.cb.emit();
  }

  handleClicked(): void {
    this.clicked = true;
    setTimeout(() => {
      this.clicked = false;
    }, this.clickedRevertTimout);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';

import { InputModule } from '../atoms/input/input.module';
import { DropdownModule } from '../atoms/dropdown/dropdown.module';
import { JsonSearchModule } from '../molecules/json-search/json-search.module';
import { LoadingModule } from '../molecules/loading/loading.module';

import { SearchService } from '../molecules/json-search/services/search.service';

import { DataTableComponent } from './components/data-table/data-table';

@NgModule({
    declarations: [DataTableComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        InputModule,
        DropdownModule,
        JsonSearchModule,
        LoadingModule,
    ],
    providers: [SearchService],
    exports: [DataTableComponent]
})
export class DataTableModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { LoadingComponent } from './components/loading/loading';

@NgModule({
    declarations: [LoadingComponent],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [LoadingComponent]
})
export class LoadingModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SocialComponent } from './components/social/social';

@NgModule({
    declarations: [SocialComponent],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [SocialComponent]
})
export class SocialModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/common/web-config.service';
import {
  BusinessProfile,
  CompanyDetails,
  UserAccount,
  WarehouseDetails,
  Warehouse,
} from '../models/authentication.models';

const { apiUrl } = env;

@Injectable()
export class AuthenticationService {
  constructor(protected http: HttpClient, private webConfig: WebConfigService) {}

  login(credentials) {
    return this.http.post(
      `${apiUrl}/auth`,
      { ...credentials },
      this.webConfig.renderRequestHeaders(),
    );
  }

  refreshToken(token) {
    return this.http.post(
      `${apiUrl}/auth/refresh`,
      { ...token },
      this.webConfig.renderRequestHeaders(),
    );
  }

  reset(reset) {
    return this.http.post(
      `${apiUrl}/auth/reset`,
      { ...reset },
      this.webConfig.renderRequestHeaders(),
    );
  }

  confirmAccount(confirm) {
    return this.http.post(
      `${apiUrl}/auth/confirm`,
      { ...confirm },
      this.webConfig.renderRequestHeaders(),
    );
  }

  resetPasswordRequest(email) {
    return this.http.post(
      `${apiUrl}/auth/forgot `,
      { email },
      this.webConfig.renderRequestHeaders(),
    );
  }

  signUp(userAccountData: UserAccount): Observable<Object> {
    return this.http.post(
      `${apiUrl}/signup`,
      { ...userAccountData },
      this.webConfig.renderRequestHeaders(),
    );
  }

  resendConfirmationEmail(email: string) {
    return this.http.post(
      `${apiUrl}/auth/resend_confirm `,
      { email },
      this.webConfig.renderRequestHeaders(),
    );
  }

  updateProfile(profile: BusinessProfile): Observable<Object> {
    const profileData = this.mapProfileData(profile);
    return this.http.post(
      `${apiUrl}/signup/update-profile`,
      { ...profileData },
      this.webConfig.renderRequestHeaders(),
    );
  }

  updateWarehouse(warehouse: Warehouse): Observable<Object> {
    const warehouseData = this.mapWarehouseData(warehouse);
    return this.http.post(
      `${apiUrl}/signup/update-profile`,
      { ...warehouseData },
      this.webConfig.renderRequestHeaders(),
    );
  }

  private mapProfileData(profile: BusinessProfile): CompanyDetails {
    return {
      company: {
        name: profile.registeredBusinessName,
        trading_as: profile.businessTradingName,
        phone_number: profile.businessPhoneNumber,
        vat_number: profile.vatNumber,
        business_url: profile.businessURL,
        registration_number: profile.businessRegistrationNumber,
        industry: profile.industry,
        support: {
          phone_number: profile.customerCareNumber,
          email: profile.customerCareEmail,
        },
        address: {
          main: {
            unit: profile.address?.unit,
            address_1: profile.address.street,
            suburb: profile.address.suburb,
            postal_code: profile.address.postalCode,
            city: profile.address.cityOrTown,
            province: profile.address.province,
          },
        },
      },
    };
  }

  private mapWarehouseData(warehouse: Warehouse): WarehouseDetails {
    return {
      company: {
        address: {
          '1': {
            unit: warehouse.address?.unit,
            address_1: warehouse.address.street,
            suburb: warehouse.address.suburb,
            postal_code: warehouse.address.postalCode,
            city: warehouse.address.cityOrTown,
            province: warehouse.address.province,
            contact: {
              name: warehouse.wareHouseContactPerson,
              email: warehouse.warehouseEmailAddress,
              phone_number: warehouse.warehousePhoneNumber,
            },
          },
        },
      },
    };
  }
}

import { combineReducers } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

import coreReducer, { InitialState } from './core';

export interface InitialAppState {
  core: InitialState;
}

export const reducers = {
  router: routerReducer,
  core: coreReducer,
};

export default combineReducers(reducers, {});

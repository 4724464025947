<section class="login">
  <app-auth-form-welcome-header></app-auth-form-welcome-header>
  <form class="login__form" validate (ngSubmit)="onSubmit()" [formGroup]="form">
    <h2 class="login__title">Sign in Details</h2>

    <div class="login__username">
      <pargo-input class="login__input" label="Email" formName="email" [form]="form">
        <input class="input" type="email" formControlName="email" />
      </pargo-input>
    </div>

    <div class="login__password">
      <pargo-input class="login__input" label="Password" formName="password" [form]="form">
        <input class="input" type="password" formControlName="password" autocomplete="off" />
      </pargo-input>
    </div>

    <div class="login__remember">
      <label class="login__remember-label">
        <input class="login__remember" type="checkbox" formControlName="remember" />
        <span class="login__remember-text">Remember me</span>
      </label>
      <a class="login__login-link login__forgot" [routerLink]="['/auth/forgot-password']">
        Forgot password?
      </a>
    </div>

    <pargo-button
      class="login__button"
      type="submit"
      [loading]="loginSubmitted$ | async"
      label="Sign In"
    >
    </pargo-button>

    <p class="login__account">
      Don't have an account?
      <a class="login__account-link login__link" [routerLink]="['/auth/sign-up']">Sign up now.</a>
    </p>
  </form>
</section>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ButtonModule } from '../../../atoms/button/button.module';

import { CustomPackage } from './components/custom-package/custom-package';
import { DefaultPackage } from './components/default-package/default-package';

@NgModule({
    declarations: [CustomPackage, DefaultPackage],
    imports: [CommonModule, MatIconModule, ButtonModule],
    providers: [],
    exports: [CustomPackage, DefaultPackage]
})
export class CardCreditsPackagesModule {}

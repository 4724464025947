import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { Notification } from '../../models/notifications';
import { InitialState } from '../../reducers/notifications';
import { selectNotifications } from '../../selectors/notifications';
import { removeNotification } from '../../actions/notifications';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.html',
  styleUrls: ['./notifications.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications$: Observable<Notification[]> = this.store.select(selectNotifications);
  notifications: any;
  notificationsSubscription: Subscription;

  timers: any = {};
  removeFn = this.removeNotification.bind(this);

  constructor(private store: Store<InitialState>) {}

  ngOnInit() {
    this.notificationsSubscription = this.notifications$.subscribe((notifications: any) => {
      if (!notifications) return;
      this.notifications = [];
      for (let i = notifications.length - 1; i >= 0; i--) {
        this.notifications.push(notifications[i]);
        if (notifications[i].type === 'fade') {
          this.registerTimer(notifications[i].id);
        }
      }
    });
  }

  ngOnDestroy() {
    this.notificationsSubscription.unsubscribe();
  }

  registerTimer(id) {
    this.timers[id] = setTimeout(() => this.removeNotification(id), 5000);
  }

  removeNotification(id) {
    if (this.timers && this.timers[id]) {
      clearTimeout(this.timers[id]);
      delete this.timers[id];
    }

    this.store.dispatch(removeNotification({ id }));
  }
}

import { Component, forwardRef, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormBuilder,
  Validator,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { CREATE_ICONS } from '../../../../../icons';
import { CustomIcon } from '../../../../../core/models/icons';
import { VolumetricWeightDialogComponent } from '../../dialogs/volumetric-weight-dialog/volumetric-weight-dialog';
import { FormValidationService } from '@shared/services/form-validation.service';

@Component({
  selector: 'app-order-parcel-details',
  templateUrl: './order-parcel-details.html',
  styleUrls: ['./order-parcel-details.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParcelDetailsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ParcelDetailsComponent),
      multi: true,
    },
  ],
})
export class ParcelDetailsComponent implements ControlValueAccessor, Validator {
  @Input() heading?: string;

  parcelDetailsForm = this.formBuilder.group({
    weight: ['', [Validators.required]],
    externalReference: [
      '',
      [Validators.required, Validators.maxLength(40), this.formValidationService.isWhiteSpace],
    ],
    packageValue: ['', [Validators.required]],
    packageDescription: [
      '',
      [Validators.required, this.formValidationService.isWhiteSpace, Validators.maxLength(255)],
    ],
  });

  volumetricWeightModal = {
    fn: this.handleVolumetricWeight.bind(this),
    icon: 'weight-pargo',
    component: VolumetricWeightDialogComponent,
    label: 'Calculate volumetric weight',
    class: 'parcel-weight_volumetric',
    type: 'link',
  };

  volumetricWeightOpen: boolean = true;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    private formValidationService: FormValidationService,
  ) {
    CREATE_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  get weightInformation() {
    return {
      title: `Select the correct parcel weight`,
      description: `Make sure your parcel is less then <b>${this.parcelDetailsForm.value.weight} kg</b> and isn't over the <b>volumetric weight</b>.`,
    };
  }

  get descriptionInformation() {
    return {
      title: `Prohibited items`,
      description: `
            <br/>
            1. Firearms and ammunition<br/>
            2.  Explosives and incendiary substances* <br/>
            3. Chemical and toxic substances *<br/>
            <br/>
            *Pargo does not distribute any hazardous materials. The few exceptions such as toiletries, medicines, battery powered electronics
            and consumer devices, of which, all products distributed are aligned to ICAO Technical Instructions of items safe for air travel.
            `,
    };
  }

  closeVolumetricWeight() {
    this.volumetricWeightOpen = false;
  }

  openVolumetricWeight() {
    this.volumetricWeightOpen = true;
  }

  handleVolumetricWeight(props: { weight: number }) {
    if (props && props.weight) {
      const { weight } = props;

      if (weight <= 5) {
        this.parcelDetailsForm.patchValue({ weight: '5' });
      }

      if (weight > 5 && weight <= 10) {
        this.parcelDetailsForm.patchValue({ weight: '10' });
      }

      if (weight > 10 && weight <= 15) {
        this.parcelDetailsForm.patchValue({ weight: '15' });
      }
    }
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val && Object.keys(val).length) {
      this.parcelDetailsForm.patchValue({ ...val });
    }

    if (val === null) {
      this.parcelDetailsForm.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.parcelDetailsForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.parcelDetailsForm.disable();
    } else {
      this.parcelDetailsForm.enable();
    }
  }

  validate(c: AbstractControl): any {
    return this.parcelDetailsForm.valid
      ? null
      : {
          valid: false,
          message: 'Parcel details section is invalid',
        };
  }
}

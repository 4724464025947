import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerHeadingModule } from '../../typography/container-heading/container-heading.module';

import { ButtonModule } from '../../atoms/button/button.module';
import { InputModule } from '../../atoms/input/input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomsShipmentDetail } from './components/customs-shipment-detail/customs-shipment-detail';

@NgModule({
    declarations: [CustomsShipmentDetail],
    imports: [
        CommonModule,
        MatIconModule,
        ContainerHeadingModule,
        ButtonModule,
        InputModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [],
    exports: [CustomsShipmentDetail]
})
export class CustomsShipmentDetailsModule {}

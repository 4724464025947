import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { InitialState } from '../../../reducers/authentication';
import { resetPassword } from '../../../actions/authentication';
import { FormValidationService } from '../../../../shared/services/form-validation.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.html',
  styleUrls: ['./reset-password-form.scss'],
})
export class ResetPasswordFormComponent implements AfterViewInit {
  loading = false;

  submitted = false;

  email: string = undefined;

  token: string = undefined;

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<InitialState>,
    private activatedRoute: ActivatedRoute,
    private formValidationService: FormValidationService,
  ) {
    this.form = this.formBuilder.group({
      repeatPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.formValidationService.directPasswordMatch,
        ],
      ],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const { email, token } = params;
      this.email = email;
      this.token = token;
    });
  }

  onSubmit() {
    this.loading = true;

    if (this.form.invalid) {
      this.loading = false;
      Object.keys(this.form.controls).forEach((field) => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }

    if (this.form.valid && this.token && this.email) {
      const { password, repeatPassword } = this.form.value;
      this.store.dispatch(
        resetPassword({
          reset: {
            token: this.token,
            email: this.email,
            password_confirmation: repeatPassword,
            password,
          },
        }),
      );
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ShadowboxModule } from '../../layout/shadow-box/shadowbox.module';

import { ButtonModule } from '../../atoms/button/button.module';

import { ScriptService } from './services/script.service';

import { PaymentForm } from './components/payment-form/payment-form';

@NgModule({
    declarations: [PaymentForm],
    imports: [CommonModule, MatIconModule, ShadowboxModule, ButtonModule],
    providers: [ScriptService],
    exports: [PaymentForm]
})
export class PaymentFormModule {}

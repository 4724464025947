import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { CreditsAmount } from './components/credits-amount/credits-amount';

@NgModule({
    declarations: [CreditsAmount],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [CreditsAmount]
})
export class CreditsAmountModule {}

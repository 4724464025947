<main class="layout">
  <section
    [class]="
      (showNavBar$ | async) === true ? 'layout__main layout__main--authenticated' : 'layout__main'
    "
  >
    <app-navigation
      class="layout__navigation"
      [authenticated]="authenticated$ | async"
      [credits]="(core$ | async).credits"
      [profile]="profile$ | async"
      (logout)="handleLogout($event)"
      [hasCredits]="(core$ | async).settings.hasCredits"
      [processes]="(core$ | async).settings.processes"
      *ngIf="(showNavBar$ | async) === true"
    >
    </app-navigation>
    <app-notifications></app-notifications>
    <router-outlet id="router__outlet" class="layout__content"></router-outlet>
  </section>
</main>
<app-notifications></app-notifications>
<app-walkthrough-explanation *ngIf="authenticated$ | async"> </app-walkthrough-explanation>

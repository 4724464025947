import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerHeadingModule } from '../../typography/container-heading/container-heading.module';
import { ContainerModule } from '../../layout/container/container.module';

import { ConfirmationMessageModule } from '../../molecules/feedback/confirmation-message/confirmation-message.module';
import { PaymentFormModule } from '../../molecules/payment-form/payment-form.module';

import { CreditsPayment } from './components/credits-payment/credits-payment';

@NgModule({
    declarations: [CreditsPayment],
    imports: [
        CommonModule,
        MatIconModule,
        ContainerModule,
        ContainerHeadingModule,
        ConfirmationMessageModule,
        PaymentFormModule,
    ],
    providers: [],
    exports: [CreditsPayment]
})
export class CreditsPaymentModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ReturnSummaryComponent } from './components/return-summary/return-summary';

@NgModule({
  declarations: [ReturnSummaryComponent],
  imports: [CommonModule, MatIconModule],
  providers: [],
  exports: [ReturnSummaryComponent],
})
export class ReturnSummaryModule {}

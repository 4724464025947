<header class="header">
  <nav class="navigation">
    <div class="navigation__content">
      <div class="navigation__logo">
        <pargo-router-link class="logo__link" routerLink="/dashboard/home">
          <img class="logo" src="assets/images/logo.svg" />
        </pargo-router-link>
      </div>
      <mat-icon class="navigation__mobile" (click)="toggleMobileMenu()"> menu </mat-icon>
      <ul [class]="showMobile ? 'navigation__list navigation__list--open' : 'navigation__list'">
        <li class="navigation__item--close">
          <mat-icon class="navigation__mobile" (click)="toggleMobileMenu()"> close </mat-icon>
        </li>
        <li class="navigation__item" *ngFor="let item of items">
          <pargo-router-link
            *ngIf="item.url"
            class="navigation__link"
            [routerLink]="item.url"
            [routerLinkActive]="['navigation__link--active']"
          >
            {{item.title}}
          </pargo-router-link>
          <a *ngIf="item.fn" class="navigation__link" (click)="item.fn()"> {{item.title}} </a>
          <app-navigation-flyout
            class="navigation__flyout"
            *ngIf="item.submenu && item.submenu.items"
            [items]="item.submenu.items"
            [all]="item.submenu.all"
          >
          </app-navigation-flyout>
        </li>
      </ul>
    </div>
    <div class="navigation__actions">
      <ul class="navigation__list navigation__list--actions">
        <li class="navigation__item navigation__item--credits" *ngIf="hasCredits === true">
          <pargo-credits-amounts [credits]="credits"></pargo-credits-amounts>
        </li>
        <li class="navigation__item navigation__item--walkthrough">
          <app-walkthrough-toggle
            [hasCredits]="hasCredits"
            [processes]="processes"
          ></app-walkthrough-toggle>
          <app-navigation-flyout class="navigation__flyout" [items]="supportSubNav">
          </app-navigation-flyout>
        </li>
        <li class="navigation__item navigation__item--account" *ngIf="profile">
          <pargo-router-link
            [class]="'navigation__link navigation__link--avatar'"
            [routerLinkActive]="['navigation__link--active']"
            [routerLink]="['/account']"
          >
            <div class="navigation__avatar-container">
              <img class="navigation__avatar" [src]="getAvatar()" />
            </div>
            <span class="navigation__username"> {{username}} </span>
          </pargo-router-link>
          <app-navigation-flyout class="navigation__flyout" [items]="userSubNav">
          </app-navigation-flyout>
        </li>
      </ul>
    </div>
  </nav>
</header>

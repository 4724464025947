import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/walkthrough';

export interface InitialState {
  active: boolean;
  navigation: {
    current: string;
    pages: string[];
    next: string;
    previous: string;
  };
  pages: any;
  steps: any;
  current: any;
}

export const initialState: any = {
  active: false,
  steps: [],
  pages: [],
  current: undefined,
  navigation: {
    current: undefined,
    pages: undefined,
    next: undefined,
    previous: undefined,
  },
};

export const walkthroughReducerKey = 'walkthrough';

const setNextPage = (pages, url) => {
  if (pages && url) {
    let nextPage = undefined;

    pages.forEach((page, index) => {
      if (page === url) {
        if (index === pages.length - 1) {
          nextPage = pages[0];
          return;
        }

        nextPage = pages[index + 1];
        return;
      }
    });

    return nextPage;
  }
};

const setPreviousPage = (pages, url) => {
  if (pages && url) {
    let previousPage = undefined;

    pages.forEach((page, index) => {
      if (page === url) {
        if (index === 0) {
          previousPage = pages[pages.length - 1];
          return;
        }

        previousPage = pages[index - 1];
        return;
      }
    });

    return previousPage;
  }
};

const createOrderReducer = createReducer(
  initialState,
  on(
    actions.enableWalkthrough,
    (state: InitialState, { url, pages }: { url: string; pages: any }) => {
      const pageList = pages.map((page) => page.url);
      const page = pages.find((page: any) => page.url === url);

      return {
        ...state,
        active: true,
        steps: page && page.steps,
        pages: pages,
        navigation: {
          ...state.navigation,
          current: url,
          pages: pageList,
          next: setNextPage(pageList, url),
          previous: setPreviousPage(pageList, url),
        },
      };
    },
  ),
  on(actions.disableWalkthrough, (state: InitialState) => {
    return {
      ...state,
      steps: [],
      pages: undefined,
      active: false,
      current: undefined,
      navigation: {
        navigating: false,
        current: undefined,
        pages: undefined,
        next: undefined,
        previous: undefined,
      },
    };
  }),
  on(actions.resetStepsPageNavigation, (state: InitialState, { url }) => {
    const matchingPage = state.pages.find((page: any) => page.url === url);
    const steps = matchingPage ? matchingPage.steps : undefined;

    return {
      ...state,
      current: Array.isArray(steps) && steps.length ? steps[0] : undefined,
      steps: steps,
      navigation: {
        ...state.navigation,
        current: url,
        next: setNextPage(state.navigation.pages, url),
        previous: setPreviousPage(state.navigation.pages, url),
      },
    };
  }),
  on(actions.showStep, (state: InitialState, { id }) => {
    return {
      ...state,
      current: state.steps.find((step) => step.id === id),
    };
  }),
  on(actions.addWalkthroughStep, (state: InitialState, { step }) => {
    return {
      ...state,
      steps: [...state.steps, step],
      current: state.steps[0],
    };
  }),
  on(actions.removeWalkthroughStep, (state: InitialState, { id }) => {
    const steps = state.steps.filter((step) => step.id !== id);

    return {
      ...state,
      steps: steps,
      current: steps && steps.length ? state.current : undefined,
    };
  }),
);

export default function reducer(state, action) {
  return createOrderReducer(state, action);
}

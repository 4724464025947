import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { from } from 'rxjs';

import { WebConfigService } from '../../../../../../core/services/common/web-config.service';

import { Query, QueryInput, QueryContext, Results } from '../models/json-search';

import { environment } from '../../../../../../../environments/environment';

@Injectable()
export class SearchService {
  constructor(protected http: HttpClient, private webConfig: WebConfigService) {}

  _endpoint: string = environment.apiUrl;
  private _loading: boolean = false;
  private _context: QueryContext = {
    suburb: undefined,
    city: undefined,
    postalCode: undefined,
    province: undefined,
  };

  _searchQuery: Query = {
    processType: undefined,
    search: undefined,
    value: undefined,
    context: undefined,
  };

  _processType: string;

  _results: Results;

  homeDeliveryProcessType = 'w2d';

  get loading() {
    return this._loading;
  }

  get contextLength(): number {
    return Object.values(this._context).filter((x) => x).length;
  }

  get setContextKeys(): string[] {
    return Object.values(this._context).filter((x) => x);
  }

  get results() {
    return this._results;
  }

  clearResults() {
    this._results = undefined;
  }

  async query(): Promise<any> {
    this._loading = true;

    await from(
      this.http.post(
        `${this._endpoint}/autocomplete?with_matches=null`,
        { ...this._searchQuery },
        { ...this.webConfig.renderRequestHeaders() },
      ),
    )
      .toPromise()
      .then((results: Results) => {
        this._results = results;
      });
  }

  async search(query: QueryInput, processType: string): Promise<Results> {
    this._processType = processType;
    const { suburb, province, city } = query;
    const term = suburb;

    this._searchQuery = {
      processType,
      search: 'suburb',
      value: suburb,
      context: {
        term,
      },
    };

    if (processType !== this.homeDeliveryProcessType) {
      this._searchQuery.context.city = city;
      this._searchQuery.context.province = province;
    }

    this._results = undefined;
    await this.query();
    this._loading = false;
    this._searchQuery = undefined;

    return this._results;
  }
}

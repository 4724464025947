import { createAction, props } from '@ngrx/store';

export const ENABLE_WALKTHROUGH = 'WALKTHROUGH/ENABLE_WALKTHROUGH';
export const DISABLE_WALKTHROUGH = 'WALKTHROUGH/DISABLE_WALKTHROUGH';

export const RESET_STEPS_PAGE_NAVIGATION = 'WALKTHROUGH/RESET_STEPS_PAGE_NAVIGATION';

export const SHOW_STEP = 'WALKTHROUGH/SHOWSTEP';
export const ADD_WALKTHROUGH_STEP = 'WALKTHROUGH/ADD_WALKTHROUGH_STEP';
export const REMOVE_WALKTHROUGH_STEP = 'WALKTHROUGH/REMOVE_WALKTHROUGH_STEP';

export const SET_WALKTHROUGH_PAGES = 'WALKTHROUGH/SET_WALKTHROUGH_PAGES';

export const enableWalkthrough = createAction(
  ENABLE_WALKTHROUGH,
  props<{ url: string; pages: object[] }>(),
);

export const disableWalkthrough = createAction(DISABLE_WALKTHROUGH);

export const resetStepsPageNavigation = createAction(
  RESET_STEPS_PAGE_NAVIGATION,
  props<{ url: string }>(),
);

export const showStep = createAction(SHOW_STEP, props<{ id: string }>());

export const addWalkthroughStep = createAction(ADD_WALKTHROUGH_STEP, props<{ step: any }>());

export const removeWalkthroughStep = createAction(REMOVE_WALKTHROUGH_STEP, props<{ id: string }>());

export const actions = {
  enableWalkthrough,
  disableWalkthrough,
  showStep,
  addWalkthroughStep,
  removeWalkthroughStep,
  resetStepsPageNavigation,
};

<section class="receiver-details" [formGroup]="receiverForm">
  <div class="receiver-details__address">
    <div class="receiver-details__address-one">
      <pargo-input class="parcel-information__input" label="Address one">
        <input class="input" type="text" formControlName="addressOne" />
      </pargo-input>
    </div>
    <div class="receiver-details__address-two">
      <pargo-input class="parcel-information__input" label="Address two">
        <input class="input" type="text" formControlName="addressTwo" />
      </pargo-input>
    </div>
  </div>

  <div class="receiver-details__world">
    <div class="receiver-details__postalcode">
      <app-json-search
        label="Search by city and suburb"
        [processType]="deliveryMethod"
        [showCityInput]="true"
        [indexes]="['Suburb', 'PostalCode', 'City']"
        [fields]="['Suburb', 'PostalCode', 'City']"
        [value]="suburbPostalCodeCity"
        [preliminaryPostalCode]="receiverForm.get('postalCode').value"
        [preliminarySuburb]="receiverForm.get('suburb').value"
        [endpoints]="endpoints"
        (search)="setDetails($event)"
      >
      </app-json-search>
    </div>
  </div>
</section>

<section class="sign-up">
  <app-auth-form-welcome-header></app-auth-form-welcome-header>
  <form class="sign-up__form" validate (ngSubmit)="onSubmit()" [formGroup]="form">
    <h2 class="sign-up__title">Sign Up</h2>

    <div class="sign-up__input-container">
      <pargo-input class="sign-up__input" label="Full name" formName="fullName" [form]="form">
        <input class="input" type="text" formControlName="fullName" />
      </pargo-input>
    </div>

    <div class="sign-up__input-container">
      <pargo-input class="sign-up__input" label="Email" formName="email" [form]="form">
        <input class="input" type="email" formControlName="email" />
      </pargo-input>
    </div>

    <div class="sign-up__input-container">
      <pargo-input class="sign-up__input" label="Password" formName="password" [form]="form">
        <input class="input" type="password" formControlName="password" autocomplete="off" />
      </pargo-input>
    </div>

    <div class="sign-up__input-container">
      <pargo-input
        class="sign-up__input"
        label="Confirm password"
        formName="repeatPassword"
        [form]="form"
      >
        <input class="input" type="password" formControlName="repeatPassword" autocomplete="off" />
      </pargo-input>
    </div>

    <div class="sign-up__checkbox">
      <label class="sign-up__checkbox-label sign-up--info-text">
        <input class="sign-up__checkbox" type="checkbox" formControlName="marketingOptIn" />
        <span class="sign-up__checkbox-text">
          Please send me occasional updates on news, events and offers</span
        >
      </label>
    </div>

    <span class="sign-up__requirements-text sign-up--info-text">
      By clicking <b><i>Sign Up</i></b> below, you agree to the
      <a href="https://pargo.co.za/terms-and-conditions/" target="_blank">T's and C's</a> and
      <a href="https://pargo.co.za/privacy-policy/" target="_blank">Privacy Policy</a></span
    >

    <pargo-button
      class="sign-up__button"
      type="submit"
      [loading]="createUserAccountInProgress$ | async"
      [disabled]="createUserAccountInProgress$ | async"
      label="Sign Up"
    >
    </pargo-button>
  </form>
</section>

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class WebConfigService {
  constructor() {}

  public renderRequestHeaders() {
    const token = JSON.parse(localStorage.getItem('auth'));

    const headers = {
      Authorization: token ? `Bearer ${token.access_token}` : '',
      'Content-Type': 'application/json',
    };

    return {
      headers: new HttpHeaders(headers),
      withCredentials: token ? true : false,
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/common/web-config.service';

const { apiUrl } = env;

@Injectable()
export class CreateService {
  constructor(protected http: HttpClient, private webConfig: WebConfigService) {}

  create(order: any, type: string) {
    return this.http.post(
      `${apiUrl}/orders/${type}`,
      { ...order, preventConfirm: true },
      this.webConfig.renderRequestHeaders(),
    );
  }

  return(order: any) {
    return this.http.post(
      `${apiUrl}/orders/p2w`,
      { ...order, preventConfirm: true },
      this.webConfig.renderRequestHeaders(),
    );
  }

  edit(order: any, type: string) {
    const { id, ...rest } = order;
    return this.http.put(
      `${apiUrl}/orders/${type}/${id}`,
      { ...rest, preventConfirm: true },
      this.webConfig.renderRequestHeaders(),
    );
  }

  confirmOrder(id: string) {
    return this.http.post(
      `${apiUrl}/orders/confirm`,
      { orderIds: [id] },
      this.webConfig.renderRequestHeaders(),
    );
  }

  printLabel(id: string) {
    return this.http.post(
      `${apiUrl}/orders/get-labels`,
      { orderIds: [id] },
      { ...this.webConfig.renderRequestHeaders() },
    );
  }

  getRegion(address: object): Observable<any> {
    return this.http.post(
      `${apiUrl}/payments/delivery_type`,
      { ...address },
      this.webConfig.renderRequestHeaders(),
    );
  }

  getProfileDetails(): Observable<any> {
    return this.http.get(`${apiUrl}/profile/me`, this.webConfig.renderRequestHeaders());
  }
}

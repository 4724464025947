import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Shadowbox } from './components/shadowbox/shadowbox';

@NgModule({
    declarations: [Shadowbox],
    imports: [CommonModule],
    providers: [],
    exports: [Shadowbox]
})
export class ShadowboxModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerModule } from '../../layout/container/container.module';
import { DashboardBoxModule } from '../../layout/dashboard-box/dashboard-box.module';
import { ContainerHeadingModule } from '../../typography/container-heading/container-heading.module';

import { ButtonModule } from '../../atoms/button/button.module';
import { CtaModule } from '../../atoms/cta/cta.module';
import { DropdownModule } from '../../atoms/dropdown/dropdown.module';

import { AmountSummaryModule } from '../../molecules/amount-summary/amount-summary.module';

import { InvoicesSummary } from './components/invoices-summary/invoices-summary';

@NgModule({
    declarations: [InvoicesSummary],
    imports: [
        CommonModule,
        MatIconModule,
        ContainerModule,
        DropdownModule,
        DashboardBoxModule,
        ContainerHeadingModule,
        ButtonModule,
        CtaModule,
        AmountSummaryModule,
    ],
    providers: [],
    exports: [InvoicesSummary]
})
export class InvoicesSummaryModule {}

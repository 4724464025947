<section class="upload-files">
  <form
    class="upload-files__form"
    enctype="multipart/form-data"
    [formGroup]="uploadForm"
    (ngSubmit)="handleForm()"
  >
    <div class="upload-files__dropzone" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
      <mat-icon class="upload-files__icon" svgIcon="upload-pargo"></mat-icon>

      <h2 class="upload-files__heading">Drag and drop files</h2>
      <div class="upload-files__upload">
        <p class="upload-files__text">
          Or you can upload files by
          <span class="upload-files__text--link" (click)="openUpload()">clicking here</span>
        </p>

        <input
          #uploadInput
          class="upload-files__input"
          type="file"
          name="file"
          (change)="handleFileInput($event)"
          formControlName="file"
        />
        <p class="upload-files__extentions">
          {{allowedExtentions}} files of up to 250 rows are allowed
        </p>

        <div class="upload-files__generate" *ngIf="generate && generateOptions">
          <p class="upload-files__generate-text">Download the formats here:</p>

          <ul class="upload-files__generate-list">
            <li
              class="upload-files__generate-item"
              *ngFor="let option of generateOptions"
              (click)="generate(option.value)"
            >
              <mat-icon class="upload-files__generate-icon">file_download</mat-icon>
              {{ option.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <button class="upload-files__button" [disabled]="files === undefined ? true : false">
      Upload file
    </button>
  </form>
</section>

<section class="return-order-form">
  <form
    [class]="submitted ? 'form submitted return-order-form__form' : 'form return-order-form__form'"
    validate
    [formGroup]="createOrderForm"
    (ngSubmit)="handleForm()"
  >
    <div class="return-order-form__header">
      <h2 class="return-order-form__heading">Create a return order</h2>
    </div>
    <div class="return-order-form__errors" *ngIf="errors.length > 0">
      <h4 class="return-order-form__errors-heading">Please correct the following sections:</h4>
      <ul class="return-order-form__errors-list">
        <li class="return-order-form__errors-item" *ngFor="let error of errors">
          {{error.message}}
        </li>
      </ul>
    </div>

    <div class="return-order-form__body">
      <app-order-parcel-details
        class="return-order-form__parcelDetails"
        formControlName="parcelDetails"
      >
      </app-order-parcel-details>

      <app-order-customer
        class="return-order-form__receiver"
        formControlName="receiver"
        heading="Returning customer"
      >
      </app-order-customer>

      <app-return-order-method
        formControlName="returnMethod"
        [returnMethod]="process"
        (updateRegion)="handleUpdateRegion($event)"
        (updateReturnMethod)="updateReturnMethod($event)"
        [editing]="this.type === 'edit'"
      >
      </app-return-order-method>

      <app-order-warehouse
        formControlName="warehouse"
        [warehouses]="(warehouses$ | async)"
        (updateRegion)="handleUpdateRegion($event)"
        [selectedWarehouseReference]="order?.from?.reference"
        heading="Return to"
      >
      </app-order-warehouse>
    </div>
    <div class="return-order-form__footer">
      <pargo-button
        class="return-order-form__button return-order-form__button--save"
        secondary="true"
        type="submit"
        [loading]="(submitting$ | async) === true && submitType === 'draft'"
        [disabled]="(submitting$ | async) === true"
        label="Save draft"
        (click)="setSubmitType('draft')"
      >
      </pargo-button>

      <pargo-button
        class="return-order-form__button return-order-form__button--confirm"
        type="submit"
        [loading]="(submitting$ | async) === true && submitType === 'confirm'"
        [disabled]="(submitting$ | async) === true"
        label="Confirm"
        (click)="setSubmitType('confirm')"
      >
      </pargo-button>
    </div>
  </form>
  <app-return-form-summary class="return-order-form__summary" [formValues]="formValues" [amount]="amount">
  </app-return-form-summary>
</section>

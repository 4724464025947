import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ModalButtonModule } from '../../../atoms/modal-button/modal-button.module';

import { CardInformation } from './components/card-information/card-information';

@NgModule({
    declarations: [CardInformation],
    imports: [CommonModule, MatIconModule, ModalButtonModule],
    providers: [],
    exports: [CardInformation]
})
export class CardInformationModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerModule } from '../../layout/container/container.module';
import { RouterLinkModule } from '../../atoms/router-link/router-link.module';
import { SocialModule } from '../../molecules/social/social.module';

import { FooterComponent } from './components/footer/footer';

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, MatIconModule, ContainerModule, RouterLinkModule, SocialModule],
    providers: [],
    exports: [FooterComponent]
})
export class FooterModule {}

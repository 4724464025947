<button
  [class]="className"
  [disabled]="disabled"
  [type]="type ? type : 'submit'"
  (click)="handleClick()"
>
  <div class="button__clicked" *ngIf="clicked"></div>
  <mat-icon class="button__icon" [svgIcon]="iconType ? icon : undefined" *ngIf="icon || loading">
    {{!iconType ? icon : undefined}}
  </mat-icon>
  {{label}}
</button>

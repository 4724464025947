import {
  Component,
  Input,
  ContentChildren,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  QueryList,
} from '@angular/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.html',
  styleUrls: ['./information.scss'],
})
export class InformationComponent implements AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() icon: string;
  @Input() description: string;
  @Input() modal?: object;

  @ContentChildren('inputRef', { descendants: true }) inputs: QueryList<HTMLInputElement>;
  @ViewChild('container') container: ElementRef;

  show: boolean = false;
  focus: any = [];
  close: any = undefined;

  constructor() {}

  ngAfterViewInit() {
    this.inputs.forEach((input: any, index) => {
      if (input) {
        const element: any = input.nativeElement;
        this.focus[index] = element.addEventListener('focus', () => this.openInformation());
      }
    });

    this.addWindowListener();
  }

  ngOnDestroy() {
    if (this.inputs && this.inputs.length) {
      this.inputs.forEach((input: any, index) => {
        if (input) {
          const element: any = input.nativeElement;
          if (this.focus[index]) {
            element.removeEventListener('focus', this.focus[index]);
            this.focus[index] = undefined;
          }
        }
      });
    }

    if (this.close) {
      window.removeEventListener('click', this.close);
      this.close = undefined;
    }
  }

  openInformation() {
    this.show = true;
  }

  closeInformation() {
    this.show = false;
  }

  addWindowListener() {
    const container: any = this.container.nativeElement;

    if (!this.close) {
      this.close = window.addEventListener('click', (e: any) => {
        e.stopPropagation();

        this.inputs.forEach((input: any) => {
          if (input) {
            const element: any = input.nativeElement;

            if (!element.contains(e.target) && !container.contains(e.target)) {
              this.closeInformation();
            }
          }
        });
      });
    }
  }

  removeWindowListener() {
    if (this.close) {
      window.removeEventListener('click', this.close, true);
      this.close = undefined;
    }
  }
}

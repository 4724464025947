import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import lottie from 'lottie-web';

import { LOADING_ICONS } from '../../../../../../../icons';
import { CustomIcon } from '../../../../../../../core/models/icons';

import { loadingAnimation } from '../../../../../animations/loading';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.html',
  styleUrls: ['./loading.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() title: string;
  animation: any = undefined;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    LOADING_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  ngOnInit() {
    if (!this.animation) {
      const box = document.getElementById('loading');

      this.animation = lottie.loadAnimation({
        container: box,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
      });
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'pargo-dashboard-box',
  templateUrl: './dashboard-box.html',
  styleUrls: ['./dashboard-box.scss'],
})
export class DashboardBoxComponent {
  @Input() heading?: string;
}

<div [class]="containerClass" #containerElement>
  <label [class]="labelClass" (click)="handleLabelFocus()">{{label}}</label>
  <ng-content></ng-content>
  <div
    class="input_error-container"
    *ngIf="form && formName && form.get(formName).invalid &&
        form.get(formName).errors &&
        (form.get(formName).dirty || form.get(formName).touched)"
  >
    <small class="input__error" *ngIf="form.get(formName).hasError('required')">
      This field is required.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('minlength')">
      The minimum length for this field is {{form.get(formName).errors.minlength.requiredLength}}
      characters.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('maxlength')">
      The maximum length for this field is {{form.get(formName).errors.maxlength.requiredLength}}
      characters.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('matchPassword')">
      The passwords do not match each other.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('invalidEmail')">
      The email format is not valid.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('nonAlphabeticCharacters')">
      The field can contain alphabetic characters only.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('invalidPhoneNumber')">
      The phone number format is not valid.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('invalidURL')">
      The url format is not valid.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('nonAlphaNumericCharacters')">
      The field can contain alphabetic and numeric characters only.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('invalidVatNumber')">
      The VAT number is invalid.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('invalidPostalCode')">
      The postal code is invalid.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('isWhiteSpace')">
      The field cannot consist of whitespace characters only.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('isInvalidShopifyUrl')">
      Please enter a valid shopify store url.
    </small>
    <small class="input__error" *ngIf="form.get(formName).hasError('invalidUserNameCharacters')">
      The full name contains unsupported characters.
    </small>
  </div>
</div>

import { createAction, props } from '@ngrx/store';

export const REMOVE_NOTIFICATION = 'NOTIFICATIONS/REMOVE_NOTIFICATION';
export const ADD_NOTIFICATION = 'NOTIFICATIONS/ADD_NOTIFICATION';

export const removeNotification = createAction(REMOVE_NOTIFICATION, props<{ id }>());

export const addNotification = createAction(ADD_NOTIFICATION, props<{ notification }>());

export const actions = {
  removeNotification,
  addNotification,
};

import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewContainerRef,
  ViewChild,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { InitialState as InitialCoreState } from '@core/reducers/core';
import { selectCore } from '@core/selectors/core';

import {
  FormGroup,
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormBuilder,
  ValidationErrors,
} from '@angular/forms';

import { selectProcesses, selectUser } from '../../../selectors/create';

import { InitialState } from '../../../reducers/create';
import { User, Address } from '../../../models/create';
import { ProcessType } from '../../../enums/create';

@Component({
  selector: 'app-return-order-method',
  templateUrl: './return-order-method.html',
  styleUrls: ['./return-order-method.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReturnMethodComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReturnMethodComponent),
      multi: true,
    },
  ],
})
export class ReturnMethodComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChild('componentContainer', { read: ViewContainerRef, static: true })
  componentContainer: ViewContainerRef;

  @Input() editing: boolean;

  @Input() returnMethod: string;

  @Output() updateRegion = new EventEmitter();

  @Output() updateReturnMethod: EventEmitter<string> = new EventEmitter<string>();

  core$: Observable<InitialCoreState>;

  current: number = 0;

  processes$: Observable<any> = this.store.select(selectProcesses);

  processes;

  process: string;

  subscriptions: Subscription[] = [];

  user$: Observable<object>;

  defaultMapReturnToken: string;

  returnMethodForm: FormGroup = this.formBuilder.group({
    p2w_return: this.formBuilder.control({}),
    d2w: this.formBuilder.control({}),
    returnMethod: [],
  });

  processTabs = [
    {
      process: 'p2w_return',
      heading: 'From Pargo point',
    },
    {
      process: 'd2w',
      heading: 'From home',
    },
  ];

  constructor(
    public store: Store<InitialState>,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.returnMethod = params.process;
      this.setReturnMethod();
    });
  }

  ngOnInit(): void {
    this.core$ = this.store.select(selectCore);
    this.user$ = this.store.select(selectUser);
    const userSubscription = this.user$.subscribe((user: User) => {
      this.defaultMapReturnToken = user.defaultMapReturnToken;
    });
    this.subscriptions.push(userSubscription);

    const processesSubscription = this.processes$.subscribe((processes) => {
      if (processes) {
        this.processes = processes;
        this.processTabs = this.processTabs.filter(
          (item) => this.processes.includes(item.process) && !this.editing,
        );
      }
    });
    this.subscriptions.push(processesSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      returnMethod: { currentValue },
    } = changes;
    this.returnMethod = currentValue;
  }

  handleUpdateRegion(address: Address): void {
    this.updateRegion.emit(address);
  }

  changeSlideByProcess(processType: ProcessType): void {
    if (processType === ProcessType.P2W_RETURN) {
      this.goToSlide(0);
    }

    if (processType === ProcessType.D2W) {
      this.goToSlide(1);
    }
  }

  sliderClass(index: number): string {
    return index === this.current
      ? 'stock-slider__header-item stock-slider__header-item--active'
      : 'stock-slider__header-item';
  }

  goToSlide(slideNumber: number): void {
    this.current = slideNumber;

    this.setReturnMethod();
  }

  setReturnMethod(): void {
    this.returnMethodForm.removeControl(this.returnMethod);

    this.returnMethod = this.processTabs?.[this.current]
      ? this.processTabs[this.current].process
      : undefined;

    const name = this.returnMethod;

    this.updateReturnMethod.emit(name);

    if (!!this.returnMethodForm.get(name)) {
      this.returnMethodForm.setControl(name, new FormControl(null));
    } else {
      this.returnMethodForm.addControl(name, new FormControl(null));
    }

    this.returnMethodForm.patchValue({
      returnMethod: this.returnMethod,
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val && Object.keys(val).length) {
      if (val?.returnMethod) {
        this.changeSlideByProcess(val.returnMethod);
      }
      this.returnMethodForm.patchValue({ ...val });
    }
  }

  registerOnChange(fn: () => void): void {
    this.returnMethodForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.returnMethodForm.disable();
    } else {
      this.returnMethodForm.enable();
    }
  }

  validate(): ValidationErrors | null {
    return this.returnMethodForm.valid
      ? null
      : {
          valid: false,
          message: 'The return order method you picked is not filled in correctly',
        };
  }
}

import {
  Component,
  OnInit,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment as env } from '../../../../../../environments/environment';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormBuilder,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

import { SHIPMENT_DETAIL_ICONS } from '../../../../../icons';
import { CustomIcon } from '../../../../../core/models/icons';

import { Store } from '@ngrx/store';
import { InitialState } from '../../../../profile/reducers/profile';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pickup-point-selection',
  templateUrl: './pickup-point-selection.html',
  styleUrls: ['./pickup-point-selection.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PickupPointSelectionComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PickupPointSelectionComponent),
      multi: true,
    },
  ],
})
export class PickupPointSelectionComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input() heading?: string;
  @Input() mapToken?: string;
  @Output() updateRegion = new EventEmitter();

  subscriptions: Subscription[] = [];

  environment: string = env.production ? '' : 'staging.';

  pickupPointSelectionForm = this.formBuilder.group({
    pickupPointCode: ['', [Validators.required]],
    pickupPointName: [''],
  });

  overlay: boolean = false;

  pickupPoint: any = undefined;

  listener: any;

  mapUrl: SafeResourceUrl;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private store: Store<InitialState>,
  ) {
    SHIPMENT_DETAIL_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  ngOnInit() {
    this.mapUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `https://map.${this.environment}pargo.co.za/?token=${this.mapToken}`,
    );

    if (window.addEventListener) {
      this.listener = window.addEventListener('message', this.selectPargoPoint.bind(this), false);
    }
  }

  ngOnDestroy() {
    if (this.listener) {
      window.removeEventListener('message', this.listener);
    }

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val && Object.keys(val).length) {
      const { reference, ...pickupPoint } = val;
      this.pickupPointSelectionForm.patchValue({
        pickupPointCode: reference,
        pickupPointName: pickupPoint.name,
      });

      this.pickupPoint = {
        ...pickupPoint,
        storeName: pickupPoint.name,
      };
      this.overlay = true;
    }

    if (val === null) {
      this.overlay = false;
      this.pickupPoint = undefined;
      this.pickupPointSelectionForm.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.pickupPointSelectionForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.pickupPointSelectionForm.disable();
    } else {
      this.pickupPointSelectionForm.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.pickupPointSelectionForm.valid
      ? null
      : {
          valid: false,
          message: 'Please select a Pargo Pick-up Point',
        };
  }

  dismissOverlay() {
    this.overlay = false;
  }

  selectPargoPoint(e) {
    const { data } = e;
    const isSelected = Object.keys(data).includes('address1');

    if (isSelected && this.overlay === false) {
      this.pickupPoint = data;
      this.overlay = true;
    }

    if (isSelected && data && data.pargoPointCode) {
      const address = {
        addressOne: data.address1,
        addressTwo: data.address2,
        city: data.city,
        postalCode: data.postalcode,
        suburb: data.suburb,
      };
      this.updateRegion.emit(address);

      this.pickupPointSelectionForm.patchValue({
        pickupPointCode: data.pargoPointCode,
        pickupPointName: data.storeName,
      });
    }
  }
}

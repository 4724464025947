import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';

import { InitialState } from '../reducers/walkthrough';

@Injectable()
export class WalkthroughEffects {
  constructor(private actions: Actions<any>, private store: Store<InitialState>) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ConfirmationMessage } from './components/confirmation-message/confirmation-message';

import { ShadowboxModule } from '../../../layout/shadow-box/shadowbox.module';

import { ButtonModule } from '../../../atoms/button/button.module';

@NgModule({
    declarations: [ConfirmationMessage],
    imports: [CommonModule, MatIconModule, ShadowboxModule, ButtonModule],
    providers: [],
    exports: [ConfirmationMessage]
})
export class ConfirmationMessageModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { authenticationReducerKey, InitialState } from '../reducers/authentication';

export const authenticationState = createFeatureSelector(authenticationReducerKey);

export const authenticated = createSelector(
  authenticationState,
  (state: InitialState) => state.authenticated,
);

export const loginSubmitted = createSelector(
  authenticationState,
  (state: InitialState) => state.settings.loginSubmitted,
);

export const createUserAccountInProgress = createSelector(
  authenticationState,
  (state: InitialState) => state.createUserAccount.createUserAccountInProgress,
);

export const createUserAccountEmailAlreadyExists = createSelector(
  authenticationState,
  (state: InitialState) => state.createUserAccount.emailAlreadyExists,
);

export const createUserAccountSuccessful = createSelector(
  authenticationState,
  (state: InitialState) => state.createUserAccount.createUserAccountSuccessful,
);

export const businessProfile = createSelector(
  authenticationState,
  (state: InitialState) => state.createBusinessProfile.profile,
);

export const createBusinessProfileInProgress = createSelector(
  authenticationState,
  (state: InitialState) => state.createBusinessProfile.createBusinessProfileInProgress,
);

export const createWarehouseInProgress = createSelector(
  authenticationState,
  (state: InitialState) => state.createWarehouse.createWarehouseInProgress,
);

export const warehouse = createSelector(
  authenticationState,
  (state: InitialState) => state.createWarehouse.warehouse,
);

export const userName = createSelector(
  authenticationState,
  (state: InitialState) => state.createUserAccount.userName,
);

export const userEmail = createSelector(
  authenticationState,
  (state: InitialState) => state.createUserAccount.email,
);

export const accountConfirmationStatus = createSelector(
  authenticationState,
  (state: InitialState) => state.createUserAccount.accountConfirmationStatus,
);

export const signUpInProgress = createSelector(
  authenticationState,
  (state: InitialState) => state.settings.signUpInProgress,
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { DialogComponent } from './components/dialog/dialog';

@NgModule({
    declarations: [DialogComponent],
    imports: [CommonModule, MatDialogModule],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {},
        },
        {
            provide: MAT_DIALOG_DATA,
            useValue: {},
        },
    ],
    exports: [DialogComponent]
})
export class DialogModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { walkthroughReducerKey, InitialState } from '../reducers/walkthrough';

export const walkthroughState = createFeatureSelector(walkthroughReducerKey);

export const selectWalkthroughEnabled = createSelector(
  walkthroughState,
  (state: InitialState) => state.active,
);

export const selectWalkthroughCurrent = createSelector(
  walkthroughState,
  (state: InitialState) => state.current,
);

export const selectWalkthroughSteps = createSelector(walkthroughState, (state: InitialState) => ({
  steps: state.steps,
}));

export const selectWalkthroughNavigation = createSelector(
  walkthroughState,
  (state: InitialState) => ({ navigation: state.navigation }),
);

import { createReducer, on } from '@ngrx/store';
import uuid from 'uuid/v1';

import { actions } from '../actions/notifications';
import { actions as globalActions } from '../actions/globals';

import { Notification } from '../models/notifications';

export interface InitialState {
  notifications: Notification[];
}

export const initialState: any = {
  notifications: [],
};

export const notificationsReducerKey = 'notifications';

const notificationsReducer = createReducer(
  initialState,
  on(globalActions.addNotification, (state: InitialState, { notification }) => {
    return {
      ...state,
      notifications: [...state.notifications, { id: uuid(), ...notification }],
    };
  }),
  on(actions.addNotification, (state: InitialState, { notification }) => {
    return {
      ...state,
      notifications: [...state.notifications, { id: uuid(), ...notification }],
    };
  }),
  on(actions.removeNotification, (state: InitialState, { id }) => {
    return {
      ...state,
      notifications: state.notifications.filter((notification) => notification.id !== id),
    };
  }),
);

export default function reducer(state, action) {
  return notificationsReducer(state, action);
}

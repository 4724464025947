import { ORDER_WEIGHT } from '../../shared/constants/shared-constants';
import { countryCodes } from '../constants/country-codes';

export const formatPhoneNumber = (phoneNumber, country) => {
  const result: {
    dial_code: string;
    name: string;
    code: string;
  } = countryCodes.find((res) => res.code === country);

  if (result && !phoneNumber) {
    return `${result.dial_code}000000000`;
  }

  if (result && phoneNumber) {
    let possibleDialCode;

    const [first, ...rest] = String(phoneNumber).split('');
    const [_, dialCode] = result.dial_code.split('+');

    possibleDialCode = String(phoneNumber).slice(
      0,
      String(phoneNumber).includes('+')
        ? result.dial_code.split('').length
        : dialCode.split('').length,
    );

    if (first === '0') {
      if (rest[0] === '0') {
        rest[0] = '+';
        return rest.join('');
      }
      return `+${dialCode}${rest.join('')}`;
    }

    if (dialCode === possibleDialCode) {
      return `+${phoneNumber}`;
    }
    if (result.dial_code === possibleDialCode) {
      return phoneNumber;
    }

    if (first !== '0' && dialCode !== possibleDialCode) {
      return `+${dialCode}${first}${rest.join('')}`;
    }
  }

  return phoneNumber;
};

export const formatArea = (area: any) => {
  const { suburb, postalCode, city, province } = area;
  return {
    suburb,
    city,
    postalCode,
    province,
  };
};

export const convertW2p = (orders) => {
  return orders.map((item) => {
    const order = Object.values(item);

    const [
      warehouse,
      pickupPointCode,
      weight,
      externalReference,
      packageValue,
      packageDescription,
      firstName,
      lastName,
      email,
      phoneNumbers,
      address1,
      address2,
      suburb,
      postalCode,
      city,
      country,
    ] = order;

    return {
      warehouseAddressCode: warehouse ? String(warehouse) : undefined,
      returnAddressCode: warehouse ? String(warehouse) : undefined,
      trackingCode: null,
      cubicWeight: weight ? Number(weight) : undefined,
      pickupPointCode: pickupPointCode ? String(pickupPointCode) : undefined,
      externalReference: externalReference ? String(externalReference) : undefined,
      packageValue: packageValue ? String(packageValue) : undefined,
      packageDescription: packageDescription ? String(packageDescription) : undefined,
      consignee: {
        firstName: firstName ? String(firstName) : undefined,
        lastName: lastName ? String(lastName) : undefined,
        email: email ? String(email) : undefined,
        phoneNumbers: phoneNumbers
          ? [String(formatPhoneNumber(phoneNumbers, country || 'ZA'))]
          : [],
        address1: address1 ? String(address1) : undefined,
        address2: address2 ? String(address2) : undefined,
        suburb: suburb ? String(suburb) : undefined,
        postalCode: postalCode ? String(postalCode) : undefined,
        city: city ? String(city) : undefined,
        country: country ? String(country) : undefined,
      },
    };
  });
};

export const convertW2d = (orders) => {
  return orders.map((item) => {
    const order = Object.values(item);
    const [
      warehouse,
      weight,
      externalReference,
      packageValue,
      packageDescription,
      firstName,
      lastName,
      email,
      phoneNumbers,
      address1,
      address2,
      country,
      area,
    ] = order;

    const { suburb, postalCode, city } = formatArea(area);

    return {
      warehouseAddressCode: warehouse ? String(warehouse) : undefined,
      returnAddressCode: warehouse ? String(warehouse) : undefined,
      trackingCode: null,
      cubicWeight: weight ? Number(weight) : undefined,
      externalReference: externalReference ? String(externalReference) : undefined,
      packageValue: packageValue ? String(packageValue) : undefined,
      packageDescription: packageDescription ? String(packageDescription) : undefined,
      consignee: {
        firstName: firstName ? String(firstName) : undefined,
        lastName: lastName ? String(lastName) : undefined,
        email: email ? String(email) : undefined,
        phoneNumbers: phoneNumbers
          ? [String(formatPhoneNumber(phoneNumbers, country || 'ZA'))]
          : [],
        address1: address1 ? String(address1) : undefined,
        address2: address2 ? String(address2) : undefined,
        suburb: suburb ? String(suburb) : undefined,
        postalCode: postalCode ? String(postalCode) : undefined,
        city: city ? String(city) : undefined,
        country: country ? String(country) : undefined,
      },
    };
  });
};

export const convertP2w_return = (orders) => {
  return orders.map((item) => {
    const order = Object.values(item);
    const [
      warehouse,
      pickupPointCode,
      weight,
      externalReference,
      packageValue,
      packageDescription,
      firstName,
      lastName,
      email,
      phoneNumbers,
    ] = order;

    return {
      warehouseAddressCode: warehouse ? String(warehouse) : undefined,
      returnAddressCode: warehouse ? String(warehouse) : undefined,
      trackingCode: null,
      cubicWeight: weight ? Number(weight) : undefined,
      pickupPointCode: pickupPointCode ? String(pickupPointCode) : undefined,
      externalReference: externalReference ? String(externalReference) : undefined,
      packageValue: packageValue ? String(packageValue) : undefined,
      packageDescription: packageDescription ? String(packageDescription) : undefined,
      consignee: {
        firstName: firstName ? String(firstName) : undefined,
        lastName: lastName ? String(lastName) : undefined,
        email: email ? String(email) : undefined,
        phoneNumbers: phoneNumbers ? [String(formatPhoneNumber(phoneNumbers, 'ZA'))] : undefined,
      },
    };
  });
};

export const excelDataToOrders = (type, orders) => {
  switch (type) {
    case 'w2p':
      return convertW2p(orders);
    case 'w2d':
      return convertW2d(orders);
    case 'p2w_return':
      return convertP2w_return(orders);
  }
};

export const convertW2pForm = (order, warehouse) => {
  return {
    parcelDetails: {
      weight: order && order.cubicWeight && String(order.cubicWeight),
      externalReference: order && order.externalReference,
      packageValue: order && order.packageValue,
      packageDescription: order && order.packageDescription,
    },
    receiver: {
      firstName: order && order.receiver && order.receiver.firstName,
      lastName: order && order.receiver && order.receiver.lastName,
      email: order && order.receiver && order.receiver.email,
      phone: order && order.receiver && order.receiver.phoneNumber,
    },
    warehouse: {
      warehouse: warehouse,
    },
    deliveryMethod: {
      w2p: {
        ...order.to,
      },
      deliveryMethod: order.orderType,
    },
  };
};

export const convertW2dForm = (order, warehouse) => {
  return {
    parcelDetails: {
      weight: order && order.cubicWeight && String(order.cubicWeight),
      externalReference: order && order.externalReference,
      packageValue: order && order.packageValue,
      packageDescription: order && order.packageDescription,
    },
    receiver: {
      firstName: order && order.receiver && order.receiver.firstName,
      lastName: order && order.receiver && order.receiver.lastName,
      email: order && order.receiver && order.receiver.email,
      phone: order && order.receiver && order.receiver.phoneNumber,
    },
    warehouse: {
      warehouse: warehouse,
    },
    deliveryMethod: {
      w2d: {
        addressOne: order && order.to && order.to.address1,
        addressTwo: order && order.to && order.to.address2,
        ...order.to,
      },
      deliveryMethod: order.orderType,
    },
  };
};

export const convertP2wReturnForm = (order, warehouse) => {
  if (order.orderType === 'w2d') {
    return {
      parcelDetails: {
        weight: order && order.cubicWeight && String(order.cubicWeight),
        externalReference: order && order.externalReference,
        packageValue: order && order.packageValue,
        packageDescription: order && order.packageDescription,
      },
      receiver: {
        firstName: order && order.receiver && order.receiver.firstName,
        lastName: order && order.receiver && order.receiver.lastName,
        email: order && order.receiver && order.receiver.email,
        phone: order && order.receiver && order.receiver.phoneNumber,
      },
      warehouse: {
        warehouse: warehouse,
      },
    };
  }

  if (order.orderType === 'w2p') {
    return {
      parcelDetails: {
        weight: order && order.cubicWeight && String(order.cubicWeight),
        externalReference: order && order.externalReference,
        packageValue: order && order.packageValue,
        packageDescription: order && order.packageDescription,
      },
      receiver: {
        firstName: order && order.receiver && order.receiver.firstName,
        lastName: order && order.receiver && order.receiver.lastName,
        email: order && order.receiver && order.receiver.email,
        phone: order && order.receiver && order.receiver.phoneNumber,
      },
      warehouse: {
        warehouse: warehouse,
      },
      pickupPoint: {
        ...order.to,
      },
    };
  }
};

export const updateOrderWeight = (order: any) => {
  if (Number(order?.weight) > ORDER_WEIGHT.Medium) {
    order.weight = ORDER_WEIGHT.Large.toString();
  } else if (Number(order?.weight) > ORDER_WEIGHT.Small) {
    order.weight = ORDER_WEIGHT.Medium.toString();
  } else if (Number(order?.weight) > 0) {
    order.weight = ORDER_WEIGHT.Small.toString();
  }
};

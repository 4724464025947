<section [class]="open ? 'delivery-summary delivery-summary--open' : 'delivery-summary'">
  <header class="delivery-summary__header" (click)="toggle()">
    <h4 class="delivery-summary__heading">Summary</h4>
    <mat-icon class="delivery-summary__toggle" [svgIcon]="open ? 'chevron-down-pargo' : 'chevron-up-pargo'">
    </mat-icon>
  </header>

  <body class="delivery-summary__body" *ngIf="open">
    <div class="delivery-summary__parcel-details">
      <h5>Parcel details</h5>
    </div>
    <ul class="delivery-summary__list">
      <li class="delivery-summary__list-item">
        <span>Weight:</span>
        {{formValues.parcelDetails ? formValues.parcelDetails.weight : ''}} kg
      </li>
      <li class="delivery-summary__list-item">
        <span>Reference:</span>
        {{formValues.parcelDetails ? formValues.parcelDetails.externalReference : ''}}
      </li>
      <li class="delivery-summary__list-item">
        <span>Parcel value:</span>
        {{formValues.parcelDetails ? formValues.parcelDetails.packageValue : ''}}
      </li>
      <li class="delivery-summary__list-item">
        <span>Description:</span>
        {{formValues.parcelDetails ? formValues.parcelDetails.packageDescription : ''}}
      </li>
    </ul>
    <div class="delivery-summary__parcel-recepient">
      <h5>Recipient</h5>
      <ul class="delivery-summary__list">
        <li class="delivery-summary__list-item">
          {{formValues.receiver ? formValues.receiver.firstName : ''}} {{formValues.receiver ?
          formValues.receiver.lastName : ''}}
        </li>

        <li class="delivery-summary__list-item">
          <mat-icon class="delivery-summary__list-icon" svgIcon="email-pargo"></mat-icon>
          {{formValues.receiver ? formValues.receiver.email : ''}}
        </li>
        <li class="delivery-summary__list-item">
          <mat-icon class="delivery-summary__list-icon" svgIcon="phone-pargo"></mat-icon>
          {{formValues.receiver ? formValues.receiver.phone : ''}}
        </li>
      </ul>
    </div>
    <div class="delivery-summary__parcel-from">
      <h5>From</h5>
      <ul class="delivery-summary__list">
        <li class="delivery-summary__list-item">
          {{formValues.warehouse ? formValues.warehouse.warehouseName : ''}}
        </li>
      </ul>
    </div>

    <div class="delivery-summary__parcel-to">
      <h5>To</h5>
      <ul class="delivery-summary__list">
        <li class="delivery-summary__list-item">{{to}}</li>
      </ul>
    </div>
  </body>
  <footer class="delivery-summary__footer">
    <h5 class="delivery-summary__amount">Total: {{ amount }} credits</h5>
  </footer>
</section>

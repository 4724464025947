import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormValidationService } from '@shared/services/form-validation.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-shopify-dialog',
  templateUrl: './shopify-dialog.component.html',
  styleUrls: ['./shopify-dialog.component.scss'],
})
export class ShopifyDialogComponent {
  form: UntypedFormGroup = this.formBuilder.group({
    storeUrl: ['', [Validators.required, this.isInvalidShopifyUrl, this.formValidationService.url]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ShopifyDialogComponent>,
    private formValidationService: FormValidationService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((field) => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }

    window.open(this.createShopifyPluginUrl(), '_blank');
    this.close();
  }

  createShopifyPluginUrl(): string {
    let storeUrl = this.form.value.storeUrl;
    if (storeUrl.includes('https://')) {
      storeUrl = storeUrl.replace('https://', '');
    }
    storeUrl = storeUrl.split('/')[0];
    return `${environment.shopifyPluginUrl}authenticate?shop=${storeUrl}`;
  }

  isInvalidShopifyUrl(control: AbstractControl): { isInvalidShopifyUrl: boolean } | null {
    if (control.value) {
      return control.value.includes('.myshopify.com') ? null : { isInvalidShopifyUrl: true };
    }
    return null;
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { SharedRoutingModule } from './shared-routing.module';

// Pages
import { NotFoundPageComponent } from './pages/not-found/not-found';
import { HomeDashboardPageComponent } from './pages/home-dashboard/home-dashboard';
import { FaqPageComponent } from './pages/faq-page/faq-page';

import { TooltipComponent } from './components/tooltip/tooltip';
import { ExplanationComponent } from './components/explanation/explanation';
import { InformationComponent } from './components/information/information';

import { UploadFilesComponent } from './components/upload-files/upload-files';

// To be deprecated
import { HeadingLinedComponent } from './components/_deprecated/heading-lined/heading-lined';
import { DashboardContainerComponent } from './components/_deprecated/dashboard-container/dashboard-container';

//Layout
import { ContainerModule } from './components/layout/container/container.module';

//Typography
import { ContainerHeadingModule } from './components/typography/container-heading/container-heading.module';

//Atoms
import { ButtonModule } from './components/atoms/button/button.module';
import { RouterLinkModule } from './components/atoms/router-link/router-link.module';
import { CtaModule } from './components/atoms/cta/cta.module';
import { ModalButtonModule } from './components/atoms/modal-button/modal-button.module';

//Molecules
import { CardCreditsPackagesModule } from './components/molecules/cards/card-credits-packages/card-credits-packages.module';
import { CreditsAmountModule } from './components/molecules/credits-amount/credits-amount.module';
import { CardInformationModule } from './components/molecules/cards/card-information/card-information.module';
import { NavigationFlyoutModule } from './components/molecules/navigation-flyout/navigation-flyout.module';
import { SocialModule } from './components/molecules/social/social.module';

import { DirectActionsComponent } from './components/molecules/direct-actions/direct-actions';

import { InformationUpdateComponent } from './components/molecules/information-update/information-update';

// Organisms
import { CreditsPackagesModule } from './components/organisms/credits-packages/credits-packages.module';
import { CreditsPricelistModule } from './components/organisms/credits-pricelist/credits-pricelist.module';
import { CreditsPaymentModule } from './components/organisms/credits-payment/credits-payment.module';
import { InvoicesOverviewModule } from './components/organisms/invoices-overview/invoices-overview.module';
import { InvoicesSummaryModule } from './components/organisms/invoices-summary/invoices-summary.module';
import { CustomsPaymentModule } from './components/organisms/customs-payment/customs-payment.module';
import { FaqModule } from './components/organisms/faq/faq.module.ts';
import { NavigationModule } from './components/organisms/navigation/navigation.module';
import { FooterModule } from './components/organisms/footer/footer.module';

import { DialogModule } from './components/dialog/dialog.module';
import { DataTableModule } from './components/data-table/data-table.module';
import { InputModule } from './components/atoms/input/input.module';
import { DropdownModule } from './components/atoms/dropdown/dropdown.module';

import { JsonSearchModule } from './components/molecules/json-search/json-search.module';
import { EmailSignature } from './components/molecules/email-signature/email-signature';
import { LoadingModule } from './components/molecules/loading/loading.module';

import { WalkthroughModule } from '../walkthrough';

import { FeatureFlagDirective } from '../../core/directives/feature-flag.directive';
import { FormValidationService } from './services/form-validation.service';

import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { WarehouseDetailsModule } from './components/warehouse-details/warehouse-details.module';

@NgModule({
    declarations: [
        FeatureFlagDirective,
        //Pages
        NotFoundPageComponent,
        HomeDashboardPageComponent,
        FaqPageComponent,
        TooltipComponent,
        ExplanationComponent,
        InformationComponent,
        UploadFilesComponent,
        HeadingLinedComponent,
        DashboardContainerComponent,
        DirectActionsComponent,
        InformationUpdateComponent,
        InfoDialogComponent,
        //hack
        EmailSignature,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        SharedRoutingModule,
        WalkthroughModule,
        ContainerModule,
        ContainerHeadingModule,
        //Atoms
        ButtonModule,
        CtaModule,
        RouterLinkModule,
        ModalButtonModule,
        InputModule,
        DropdownModule,
        //Molecules
        CardCreditsPackagesModule,
        CardInformationModule,
        CreditsAmountModule,
        NavigationFlyoutModule,
        SocialModule,
        //Organisms
        CreditsPackagesModule,
        CreditsPricelistModule,
        CreditsPaymentModule,
        InvoicesOverviewModule,
        InvoicesSummaryModule,
        FooterModule,
        NavigationModule,
        FaqModule,
        CustomsPaymentModule,
        DialogModule,
        DataTableModule,
        JsonSearchModule,
        LoadingModule,
        WarehouseDetailsModule,
    ],
    providers: [FormValidationService],
    exports: [
        FeatureFlagDirective,
        //Pages
        NotFoundPageComponent,
        HomeDashboardPageComponent,
        FaqPageComponent,
        TooltipComponent,
        ExplanationComponent,
        InformationComponent,
        UploadFilesComponent,
        InfoDialogComponent,
        HeadingLinedComponent,
        DashboardContainerComponent,
        DirectActionsComponent,
        InformationUpdateComponent,
        ContainerModule,
        ContainerHeadingModule,
        // Atoms
        ButtonModule,
        CtaModule,
        RouterLinkModule,
        ModalButtonModule,
        InputModule,
        DropdownModule,
        // Molecules
        CardCreditsPackagesModule,
        CardInformationModule,
        CreditsAmountModule,
        NavigationFlyoutModule,
        SocialModule,
        //Organsims
        CreditsPackagesModule,
        CreditsPricelistModule,
        CreditsPaymentModule,
        InvoicesOverviewModule,
        InvoicesSummaryModule,
        FooterModule,
        NavigationModule,
        CustomsPaymentModule,
        DataTableModule,
        JsonSearchModule,
        LoadingModule,
        WarehouseDetailsModule,
        //hack
        EmailSignature,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}

<section class="pickup-point-selection__map" [formGroup]="pickupPointSelectionForm">
  <h4 class="rpickup-point-selection__heading heading heading__lined" *ngIf="heading">
    <span>{{ heading }}</span>
  </h4>
  <input type="hidden" name="pickupPoint" formControlName="pickupPointCode" />

  <div class="pickup-point-selection__map-container">
    <iframe
      allow="geolocation"
      [class]="overlay ? 'pickup-point-selection__map-placeholder pickup-point-selection__map-placeholder--overlay' : 'pickup-point-selection__map-placeholder'"
      [src]="mapUrl"
    ></iframe>
    <div class="pickup-point-selection__overlay" *ngIf="overlay === true">
      <div class="overlay__container" *ngIf="overlay === true">
        <mat-icon class="overlay__icon" svgIcon="pup-pargo"></mat-icon>
        <div class="overlay__information">
          <h2 class="overlay__title">{{pickupPoint.storeName}}</h2>
          <ul class="overlay__address">
            <li class="overlay__address-item">{{pickupPoint.address1}}</li>
            <li class="overlay__address-item">
              {{pickupPoint.postalcode}} {{pickupPoint.suburb}} {{ pickupPoint.city}}
            </li>
          </ul>
          <button class="button button--secondary" (click)="dismissOverlay()">
            Select another Pickup point
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

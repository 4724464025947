import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { addNotification } from '../../modules/notifications/actions/globals';

import * as Sentry from '@sentry/browser';
import { environment as env } from '../../../environments/environment';
import { logout } from '../actions/globals';
import { createErrorNotificationConfig } from 'src/app/modules/shared/helpers/functions';
import { authenticated } from 'src/app/modules/authentication/selectors/authentication';
import { take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

const { sentryDSN } = env;

if (env.production || env.staging) {
  Sentry.init({
    dsn: sentryDSN,
    environment: env.production ? 'production' : 'staging',
    release: env.version,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.5,

    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private ngZone: NgZone, private matDialog: MatDialog) {}

  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      this.handleHttpErrors(error);
    } else if (error?.rejection instanceof HttpErrorResponse) {
      this.ngZone.run(() => {
        this.handleHttpErrors(error.rejection);
      });
    } else {
      if (env.production || env.staging) {
        Sentry.captureException(error);
      } else {
        console.log(error);
      }
      return error;
    }
  }

  handleHttpErrors(error: HttpErrorResponse) {
    const store = this.injector.get(Store);
    if (error.status === 401) {
      store
        .select(authenticated)
        .pipe(take(1))
        .subscribe((userIsLoggedIn) => {
          if (userIsLoggedIn) {
            const notification = {
              message: 'Your session has expired. Please log in again to continue using myPargo.',
              type: 'fade',
              class: 'info',
            };
            store.dispatch(addNotification({ notification }));
          }
          // close any open dialogs
          this.matDialog.closeAll();
          store.dispatch(logout());
        });
    } else {
      const notification = createErrorNotificationConfig(error);
      store.dispatch(addNotification({ notification }));
    }
  }
}

<div class="auth-banner">
  <div class="auth-banner__header-info">
    <img class="auth-banner__header-info-image" src="assets/images/auth/computer.svg" />
    <div class="auth-banner__header-info-content">
      <h2 class="auth-banner__header-info-content-heading">Manage Less, Grow More</h2>
      <p class="auth-banner__header-info-content-text">
        All your delivery needs, managed for you on a <br />
        single platform.
      </p>
    </div>
  </div>
  <div class="auth-banner__features-info">
    <div class="auth-banner__features-info-content">
      <img
        class="auth-banner__features-info-content-image"
        src="assets/images/auth/search-box.svg"
      />
      <h3 class="auth-banner__features-info-content-heading">Manage and Action Parcels</h3>
      <p class="auth-banner__features-info-content-text">
        Print waybills and manifests. Request collection, track every parcel and get exportable
        insights.
      </p>
    </div>
    <div class="auth-banner__features-info-content">
      <img class="auth-banner__features-info-content-image" src="assets/images/auth/box.svg" />
      <h3 class="auth-banner__features-info-content-heading">Create Orders Easily</h3>
      <p class="auth-banner__features-info-content-text">
        Create orders manually or via a Pargo e-commerce plugin. Home delivery, Pickup Point
        delivery and Returns included.
      </p>
    </div>
    <div class="auth-banner__features-info-content">
      <img class="auth-banner__features-info-content-image" src="assets/images/auth/coins.svg" />
      <h3 class="auth-banner__features-info-content-heading">Transparent Billing</h3>
      <p class="auth-banner__features-info-content-text">
        View your rates, get quotations, know your credit balance and top up your credit bundles.
      </p>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsEvent } from '@core/models/analyticsEvent';
import { AnalyticsService } from '@core/services/common/analytics.service';
import { Store } from '@ngrx/store';
import { showNavBar } from 'src/app/core/actions/core';
import { InitialState } from 'src/app/core/reducers/core';
import { resetSignUpData } from '../../actions/authentication';

@Component({
  selector: 'app-sign-up-completion',
  templateUrl: './sign-up-completion.component.html',
  styleUrls: ['./sign-up-completion.component.scss'],
})
export class SignUpCompletionPageComponent implements OnInit {
  loading: boolean = false;
  constructor(
    private router: Router,
    private store: Store<InitialState>,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    const event = new AnalyticsEvent('signup', 'view', 'completion_page_view', 'end', 6, 14);
    this.analyticsService.logEvent(event);
  }

  redirectToDashboard(): void {
    this.loading = true;
    this.router.navigate(['/dashboard/home']);
    this.store.dispatch(showNavBar());
    this.store.dispatch(resetSignUpData());
    const event = new AnalyticsEvent('signup', 'view', 'completion_page_continue', 'end', 6, 15);
    this.analyticsService.logEvent(event);
  }
}

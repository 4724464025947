import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { formatDate, formatTime } from '../../../../../../../../helpers/date';

import { ORDER_ICONS } from '../../../../../../../../icons';
import { CustomIcon } from '../../../../../../../../core/models/icons';

@Component({
  selector: 'app-card-information',
  templateUrl: './card-information.html',
  styleUrls: ['./card-information.scss'],
})
export class CardInformation {
  @Input() status: 'info' | 'success' | 'warning' | 'error';
  @Input() title: string;
  @Input() icon: string;
  @Input() description?: string;
  @Input() date?: Date;
  @Input() modal?: object;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    ORDER_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  get className() {
    return `card-information card-information--${this.status}`;
  }

  formatDate(date) {
    return formatDate(new Date(date));
  }

  formatTime(date) {
    return formatTime(new Date(date));
  }
}

import { createAction, props } from '@ngrx/store';
import {
  BusinessProfile,
  UserAccount,
  UserAccountStatus,
  Warehouse,
} from '../models/authentication.models';

export const LOGIN = 'AUTHENTICATION/LOGIN';
export const LOGOUT = 'AUTHENTICATION/LOGOUT';
export const LOGOUT_SUCCESS = 'AUTHENTICATION/LOGOUT_SUCCESS';
export const LOGIN_SUCCESS = 'AUTHENTICATION/LOGIN_SUCCESS';
export const REFRESH_SUCCESS = 'AUTHENTICATION/REFRESH_SUCCESS';
export const REFRESH_LOGIN = 'AUTHENTICATION/REFRESH_LOGIN';
export const REMEMBER_LOGIN = 'AUTHENTICATION/REMEMBER_LOGIN';
export const REFRESH_TOKEN = 'AUTHENTICATION/REFRESH_TOKEN';
export const REFRESH_LOGIN_SUCCESS = 'AUTHENTICATION/REFRESH_LOGIN_SUCCESS';
export const REFRESH_TOKEN_SUCCESS = 'AUTHENTICATION/REFRESH_TOKEN_SUCCESS';
export const RESET_PASSWORD = 'AUTHENTICATION/RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST = 'AUTHENTICATION/RESET_PASSWORD_REQUEST';
export const CONFIRM_ACCOUNT = 'AUTHENTICATION/CONFIRM_ACCOUNT';
export const RESET_LOGIN = 'AUTHENTICATION/RESET_LOGIN';
export const AUTHENTICATION_EFFECT_NAVIGATION = 'AUTHENTICATION/AUTHENTICATION_EFFECT_NAVIGATION';
export const CREATE_USER_ACCOUNT = 'AUTHENTICATION/CREATE_USER_ACCOUNT';
export const CREATE_USER_ACCOUNT_EMAIL_EXISTS = 'AUTHENTICATION/CREATE_USER_ACCOUNT_EMAIL_EXISTS';
export const CREATE_USER_ACCOUNT_ERROR = 'AUTHENTICATION/CREATE_USER_ACCOUNT_ERROR';
export const CREATE_USER_ACCOUNT_SUCCESSFUL = 'AUTHENTICATION/CREATE_USER_ACCOUNT_SUCCESSFUL';
export const CREATE_USER_ACCOUNT_RESET_EMAIL_EXISTS =
  'AUTHENTICATION/CREATE_USER_ACCOUNT_RESET_EMAIL_EXISTS';
export const CREATE_USER_ACCOUNT_CONFIRMATION = 'AUTHENTICATION/CREATE_USER_ACCOUNT_CONFIRMATION';
export const CREATE_USER_ACCOUNT_STORE_DATA = 'AUTHENTICATION/CREATE_USER_ACCOUNT_STORE_DATA';
export const RESET_SIGN_UP_DATA = 'AUTHENTICATION/RESET_SIGN_UP_DATA';

export const CREATE_BUSINESS_PROFILE = 'AUTHENTICATION/CREATE_BUSINESS_PROFILE';
export const STORE_BUSINESS_PROFILE = 'AUTHENTICATION/STORE_BUSINESS_PROFILE';
export const CREATE_WAREHOUSE = 'AUTHENTICATION/CREATE_WAREHOUSE';
export const STORE_WAREHOUSE = 'AUTHENTICATION/STORE_WAREHOUSE';
export const RESEND_CONFIRMATION_EMAIL = 'AUTHENTICATION/RESEND_CONFIRMATION_EMAIL';

export const CREATE_USER_ACCOUNT_AUTH = 'AUTHENTICATION/CREATE_USER_ACCOUNT_AUTH';
export const SIGN_UP_IN_PROGRESS = 'AUTHENTICATION/SIGN_UP_IN_PROGRESS';

export const login = createAction(LOGIN, props<{ login }>());

export const resetPassword = createAction(RESET_PASSWORD, props<{ reset }>());

export const confirmAccount = createAction(CONFIRM_ACCOUNT, props<{ confirm }>());

export const resetPasswordRequest = createAction(RESET_PASSWORD_REQUEST, props<{ email }>());

export const loginSuccess = createAction(LOGIN_SUCCESS);

export const resetLogin = createAction(RESET_LOGIN);

export const refreshSuccess = createAction(REFRESH_SUCCESS);

export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const refreshLogin = createAction(REFRESH_LOGIN);

export const refreshLoginSuccess = createAction(REFRESH_LOGIN_SUCCESS);

export const logout = createAction(LOGOUT);

export const rememberLogin = createAction(REMEMBER_LOGIN);

export const refreshToken = createAction(REFRESH_TOKEN);

export const createUserAccount = createAction(
  CREATE_USER_ACCOUNT,
  props<{ userAccountData: UserAccount }>(),
);

export const createUserAccountStoreData = createAction(
  CREATE_USER_ACCOUNT_STORE_DATA,
  props<{ name: string; email: string }>(),
);

export const createUserAccountEmailExists = createAction(CREATE_USER_ACCOUNT_EMAIL_EXISTS);

export const createUserAccountError = createAction(CREATE_USER_ACCOUNT_ERROR);

export const createUserAccountSuccessful = createAction(CREATE_USER_ACCOUNT_SUCCESSFUL);

export const createUserAccountResetEmailExists = createAction(
  CREATE_USER_ACCOUNT_RESET_EMAIL_EXISTS,
);

export const createUserAccountConfirmation = createAction(
  CREATE_USER_ACCOUNT_CONFIRMATION,
  props<{ accountConfirmationStatus: UserAccountStatus }>(),
);

export const createBusinessProfile = createAction(
  CREATE_BUSINESS_PROFILE,
  props<{ profileData: BusinessProfile }>(),
);

export const storeBusinessProfile = createAction(
  STORE_BUSINESS_PROFILE,
  props<{ profileData: BusinessProfile }>(),
);

export const createWarehouse = createAction(
  CREATE_WAREHOUSE,
  props<{ warehouseData: Warehouse }>(),
);

export const storeWarehouse = createAction(STORE_WAREHOUSE, props<{ warehouseData: Warehouse }>());

export const resendConfirmationEmail = createAction(
  RESEND_CONFIRMATION_EMAIL,
  props<{ email: string }>(),
);

export const createUserAccountAuth = createAction(CREATE_USER_ACCOUNT_AUTH);
export const resetSignUpData = createAction(RESET_SIGN_UP_DATA);
export const signUpInProgress = createAction(SIGN_UP_IN_PROGRESS);

export const actions = {
  login,
  resetPassword,
  loginSuccess,
  logoutSuccess,
  refreshSuccess,
  refreshLogin,
  rememberLogin,
  logout,
  refreshToken,
  refreshLoginSuccess,
  confirmAccount,
  resetLogin,
  createUserAccount,
  createUserAccountStoreData,
  createUserAccountEmailExists,
  createUserAccountError,
  createUserAccountSuccessful,
  createUserAccountResetEmailExists,
  createUserAccountConfirmation,
  createBusinessProfile,
  storeBusinessProfile,
  createWarehouse,
  storeWarehouse,
  resendConfirmationEmail,
  createUserAccountAuth,
  resetSignUpData,
  signUpInProgress,
};

<div class="business-info">
  <h1 class="business-info__heading">Primary warehouse</h1>
  <div class="business-info__description">
    This is where we'll send our couriers. If you need to add more than one location, get in touch
    with
    <a class="business-info__link" href="mailto:clientsupport@pargo.co.za"
      >clientsupport@pargo.co.za</a
    >
  </div>

  <form validate (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="business-info__form-container">
      <div class="business-info__img-container">
        <img class="business-info__img" src="assets/images/auth/warehouse-location.svg" />
      </div>
      <div class="business-info__business-info">
        <h2 class="business-info__title">warehouse Contact</h2>

        <div class="business-info__input-container">
          <pargo-input
            label="Warehouse contact person"
            formName="wareHouseContactPerson"
            [form]="form"
          >
            <input class="input" type="text" formControlName="wareHouseContactPerson" />
          </pargo-input>
        </div>

        <div class="business-info__input-container">
          <pargo-input
            label="Warehouse email address"
            formName="warehouseEmailAddress"
            [form]="form"
          >
            <input class="input" type="email" formControlName="warehouseEmailAddress" />
          </pargo-input>
        </div>

        <div class="business-info__input-container">
          <pargo-input label="Warehouse phone number" formName="warehousePhoneNumber" [form]="form">
            <input class="input" type="text" formControlName="warehousePhoneNumber" />
          </pargo-input>
          <span class="business-info__input-description"
            >*All the above is shared with courier partner to get hold of you</span
          >
        </div>
      </div>
      <div class="business-info__business-info">
        <h2 class="business-info__title">warehouse Address</h2>

        <div class="business-info__input-container">
          <pargo-input label="Unit" formName="unit" [form]="form">
            <input class="input" type="text" formControlName="unit" />
          </pargo-input>
        </div>
        <div class="business-info__input-container">
          <pargo-input label="Street number and name" formName="street" [form]="form">
            <input class="input" type="text" formControlName="street" />
          </pargo-input>
        </div>
        <div class="business-info__input-container">
          <app-json-search
            label="Search by city and suburb"
            processType="w2p"
            [preliminarySuburb]="suburb"
            [returnUserTypedSuburb]="true"
            [suburbRequiredError]="submitAttempted && !suburb"
            [showPostalCodeInput]="false"
            (search)="setAddress($event)"
          >
          </app-json-search>
        </div>
        <div class="business-info__input-container business-info__input-container--less-margin">
          <pargo-input label="Postal code" formName="postalCode" [form]="form">
            <input class="input" type="text" formControlName="postalCode" #postalCode />
          </pargo-input>
        </div>
        <div class="business-info__input-container">
          <pargo-input label="City / Town" formName="cityOrTown" [form]="form">
            <input class="input" type="text" formControlName="cityOrTown" #cityOrTown />
          </pargo-input>
        </div>
        <div class="business-info__dropdown">
          <pargo-dropdown
            class=""
            label="Province"
            [value]="selectedProvince"
            small="true"
            [items]="provinces"
            (change)="setDropdownValue($event)"
          ></pargo-dropdown>
          <small class="input__error" *ngIf="submitAttempted && !selectedProvince">
            This field is required.
          </small>
        </div>
        <div class="business-info__input-description">
          This will be set as your primary warehouse for dispatch and returning parcels.
        </div>
      </div>
    </div>
    <div class="business-info__buttons-container">
      <pargo-button
        class="business-info__buttons business-info__buttons--web-only"
        (click)="goBack()"
        secondary="true"
        label="Back"
      >
      </pargo-button>
      <pargo-button
        [loading]="createWarehouseInProgress$ | async"
        [disabled]="createWarehouseInProgress$ | async"
        class="business-info__buttons"
        type="submit"
        label="Continue"
      >
      </pargo-button>
    </div>
  </form>
</div>

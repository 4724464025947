import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import {
  ExcelService,
  excel,
  validation,
  prepaidSupplierWeightValidation,
  monthlySupplierWeightValidation,
} from '../../services/excel.service';
import { excelDataToOrders, updateOrderWeight } from '../../helpers/orders';
import { InitialState } from '../../reducers/create';

import { uploadOrders } from '../../actions/create';
import { addNotification } from '../../actions/globals';

import {
  selectUpload,
  selectProcesses,
  selectWarehouses,
  selectHasCredits,
} from '../../selectors/create';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.html',
  styleUrls: ['./upload.scss'],
})
export class UploadPageComponent implements OnInit, OnDestroy {
  tableData: any;

  upload$: Observable<any> = this.store.select(selectUpload);

  subscriptions: Subscription[] = [];

  processes$: Observable<any> = this.store.select(selectProcesses);

  processes;

  warehouses$: Observable<any> = this.store.select(selectWarehouses);

  warehouses;

  extentions: string[] = ['xls', 'xlsx'];

  generateOptions = [
    { label: 'Pickup point (.xlsx)', value: 'w2p' },
    { label: 'Home delivery (.xlsx)', value: 'w2d' },
    { label: 'Return (.xlsx)', value: 'p2w_return' },
  ];

  validation: any;

  validations: any = validation;

  loading: boolean = false;

  currentProcess: string = undefined;

  errors: boolean;

  errorAmount: number = 0;

  maxUploadAmount = 250;

  hasCredits$: Observable<boolean> = this.store.select(selectHasCredits);
  hasCredits: boolean;

  constructor(
    private excelService: ExcelService,
    private store: Store<InitialState>,
    private router: Router,
  ) {}

  ngOnInit() {
    const hasCreditsSub = this.hasCredits$.pipe(take(1)).subscribe((hasCredits) => {
      this.hasCredits = hasCredits;
      this.validations.weight = hasCredits
        ? prepaidSupplierWeightValidation
        : monthlySupplierWeightValidation;
    });
    this.subscriptions.push(hasCreditsSub);

    const processesSubscription = this.processes$.subscribe((processes) => {
      if (processes) {
        const options = this.generateOptions.filter((item) => {
          return processes.includes(item.value);
        });
        this.generateOptions = options;
      }
    });
    this.subscriptions.push(processesSubscription);

    const warehousesSubscription = this.warehouses$.subscribe((warehouses) => {
      this.warehouses = warehouses;
      if (warehouses) {
        this.validations['warehouse code'] = {
          ...this.validations['warehouse code'],
          options: warehouses.map(
            (warehouse: { city: string; suburb: string; reference: string }) => {
              return {
                label: `${warehouse.reference} | ${warehouse.suburb} ${warehouse.city}`,
                value: warehouse.reference,
              };
            },
          ),
        };
      }
    });
    this.subscriptions.push(warehousesSubscription);

    const uploadsSubscription = this.upload$.subscribe((upload) => {
      if (this.loading && upload.loading === false) {
        const error = upload.error;
        const success = upload.success;

        if (this.tableData && this.tableData.length) {
          this.tableData = this.tableData.filter((_, index) => {
            return error.includes(index);
          });
        }

        if (success.length) {
          const notification = {
            message: `Uploaded ${success.length} orders`,
            type: 'fade',
            class: 'success',
          };

          this.store.dispatch(addNotification({ notification }));
        }

        if (error.length) {
          const notification = {
            message: `${error.length} order${
              error.length > 1 ? 's' : ''
            } failed to upload. Please verify the fields`,
            type: 'fade',
            class: 'error',
          };

          this.store.dispatch(addNotification({ notification }));
        }

        if (!this.tableData.length) {
          this.tableData = undefined;
          this.store.dispatch({ type: 'ORDERS/GET_ORDERS' });
          this.router.navigate(['/orders']);
        }
      }

      this.loading = upload.loading;
    });
    this.subscriptions.push(uploadsSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  handleFiles = async (files) => {
    const [file] = files;
    const data: any = await this.excelService.parse(file);

    const [_, ...orderData] = data;

    if (orderData.length > this.maxUploadAmount) {
      const notification = {
        message: `You are trying to upload too many orders. The maximum is set at ${this.maxUploadAmount} orders at a time`,
        type: 'click',
        class: 'error',
      };

      this.store.dispatch(addNotification({ notification }));
    } else {
      this.currentProcess = this.validateContent(orderData);

      if (!this.currentProcess) {
        return;
      }

      if (this.hasCredits) {
        orderData.forEach((order: any) => {
          updateOrderWeight(order);
        });
      }

      const tableData = this.processIsW2D(this.currentProcess)
        ? this.mapW2DOrdersToTableData(orderData)
        : orderData;
      this.tableData = [...tableData];

      if (this.currentProcess === 'w2d') {
        this.validation = [...excel[this.currentProcess].fields, { name: 'area' }]
          .map((field) => {
            if (field.name && this.validations[field.name]) {
              return {
                ...field,
                ...this.validations[field.name],
              };
            }

            return field;
          })
          .filter(
            (field) =>
              field.name !== 'suburb' && field.name !== 'city' && field.name !== 'postalcode',
          );
      } else {
        this.validation = [...excel[this.currentProcess].fields].map((field) => {
          if (field.name && this.validations[field.name]) {
            return {
              ...field,
              ...this.validations[field.name],
            };
          }

          return field;
        });
      }
    }
  };

  validateContent(orders) {
    const [first] = orders;
    let processType = undefined;

    if (first) {
      const fieldNames = Object.keys(first)
        .map((field) => field.split(' ').join(''))
        .join('');

      Object.keys(excel).forEach((type) => {
        const fields = excel[type].fields.map((field) => field.name.split(' ').join('')).join('');
        if (fields === fieldNames) {
          processType = type;
          return;
        }
      });
    }

    if (!processType) {
      const notification = {
        message: 'Unknown Excel template. Please use one of the provided templates',
        type: 'fade',
        class: 'error',
      };

      this.store.dispatch(addNotification({ notification }));
    }
    return processType;
  }

  uploadOrders = (orders) => {
    if (orders) {
      if (this.currentProcess) {
        const mappedOrders = excelDataToOrders(this.currentProcess, orders);
        if (mappedOrders) {
          this.store.dispatch(
            uploadOrders({
              orders: mappedOrders,
              processType: this.currentProcess,
            }),
          );
        }
      }
    }
  };

  handleContainsErrors(value) {
    const { errors, amount } = value;
    setTimeout(() => {
      this.errors = errors;
      this.errorAmount = amount;
    }, 10);
  }

  clearOrders() {
    this.tableData = undefined;
    this.handleContainsErrors({ errors: undefined, amount: undefined });
  }

  generateExcel = (type) => {
    this.excelService.generate(type);
  };

  processIsW2D(process) {
    return process === 'w2d';
  }

  orderHasCompleteAddress(order) {
    return (
      (order.hasOwnProperty('postalcode') || order.hasOwnProperty('postal code')) &&
      order.hasOwnProperty('suburb') &&
      order.hasOwnProperty('city')
    );
  }

  fixOrderData(order) {
    if (order.hasOwnProperty('postal code')) {
      order.postalCode = order['postal code'];
      delete order['postal code'];
    }
    if (order.hasOwnProperty('postalcode')) {
      order.postalCode = order.postalcode;
      delete order.postalcode;
    }

    return order;
  }

  mapW2DOrdersToTableData(orders) {
    return orders.filter(this.orderHasCompleteAddress).map((order) => {
      const { postalCode, suburb, city, ...rest } = this.fixOrderData(order);

      return {
        ...rest,
        area: {
          suburb,
          city,
          postalCode,
        },
      };
    });
  }
}

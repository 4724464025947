<div #container class="information__container">
  <ng-content></ng-content>
  <div class="information" *ngIf="show">
    <app-card-information
      class="information__message"
      [title]="title"
      [description]="description"
      [modal]="modal"
      icon="priority_high"
      status="info"
    >
    </app-card-information>
  </div>
</div>

export const navigationData = [
  {
    title: 'Dashboard',
    url: '/dashboard/home',
  },
  {
    title: 'Orders',
    url: '/orders',
    submenu: {
      items: [
        {
          title: 'Manage Orders',
          url: '/orders',
          description: undefined,
        },
        {
          title: 'Create Pickup Point Order',
          url: '/create/order/pick-up-point',
          description: undefined,
          state: { process: 'w2p' },
          guard: 'w2p',
        },
        {
          title: 'Create Home Delivery Order',
          url: '/create/order/home-delivery',
          description: undefined,
          state: { process: 'w2d' },
          guard: 'w2d',
        },
        {
          title: 'Create Return',
          url: '/create/order/return',
          description: undefined,
          guard: 'p2w_return',
        },
        {
          title: 'Create Bulk Orders',
          url: '/create/upload',
          description: undefined,
        },
      ],
      icon: 'business-v2-pargo',
    },
  },
  {
    title: 'Credits',
    url: '/credits/dashboard',
    guard: 'payments',
    submenu: {
      items: [
        {
          title: 'View Invoices',
          url: '/credits/dashboard',
          description: undefined,
        },
        {
          title: 'Buy Credits',
          url: '/credits/buy',
          description: undefined,
        },
        {
          title: 'View Rates',
          url: '/credits/buy',
          state: { section: 'priceList' },
          description: undefined,
        },
      ],
      icon: 'business-pargo',
    },
  },
  {
    title: 'Connect Store',
    url: '/connect-store',
  },
];

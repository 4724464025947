import { createAction, props } from '@ngrx/store';

export const INIT_CREATE = 'CREATE/INIT_CREATE';
export const SAVE_ORDER = 'CREATE/SAVE_ORDER';
export const SAVE_PRINT_ORDER = 'CREATE/SAVE_PRINT_ORDER';
export const EDIT_ORDER = 'CREATE/EDIT_ORDER';
export const EDIT_PRINT_ORDER = 'CREATE/EDIT_PRINT_ORDER';
export const CONFIRM_PRINT_LABEL = 'CREATE/CONFIRM_PRINT_LABEL';
export const PRINT_LABEL = 'CREATE/PRINT_LABEL';
export const ADD_WAREHOUSES = 'CREATE/ADD_WAREHOUSES';
export const ADD_CREDITS = 'CREATE/ADD_CREDITS';
export const SUBMITTING_FORM = 'CREATE/SUBMITTING_FORM';
export const RESET_FORM = 'CREATE/RESET_FORM';
export const SUBMITTED_FORM = 'CREATE/SUBMITTED_FORM';
export const ADD_PAYMENTS = 'CREATE/ADD_PAYMENTS';
export const ADD_ACTIVE_PROCESSES = 'CREATE/ADD_ACTIVE_PROCESSES';
export const REFRESH_PROFILE = 'CREATE/REFRESH_PROFILE';
export const CREATE_EFFECT_NAVIGATION = 'CREATE/CREATE_EFFECT_NAVIGATION';
export const SAVE_RETURN_ORDER = 'CREATE/SAVE_RETURN_ORDER';
export const SAVE_PRINT_RETURN_ORDER = 'CREATE/SAVE_PRINT_RETURN_ORDER';

export const UPDATE_REGION = 'CREATE/UPDATE_REGION';
export const GET_REGION = 'CREATE/GET_REGION';

export const UPLOAD_ORDERS = 'CREATE/UPLOAD_ORDERS';
export const UPLOAD_ORDER_SUCCESS = 'CREATE/UPLOAD_ORDER_SUCCESS';
export const UPLOAD_ORDER_ERROR = 'CREATE/UPLOAD_ORDER_ERROR';
export const UPLOAD_ORDERS_DONE = 'CREATE/UPLOAD_ORDERS_DONE';

export const initCreate = createAction(INIT_CREATE);

export const saveOrder = createAction(SAVE_ORDER, props<{ order; deliveryMethod; redirect }>());

export const savePrintOrder = createAction(
  SAVE_PRINT_ORDER,
  props<{ order; deliveryMethod; redirect }>(),
);

export const saveReturnOrder = createAction(
  SAVE_RETURN_ORDER,
  props<{ order; returnMethod; redirect }>(),
);

export const savePrintReturnOrder = createAction(
  SAVE_PRINT_RETURN_ORDER,
  props<{ order; returnMethod; redirect }>(),
);

export const editOrder = createAction(EDIT_ORDER, props<{ order; deliveryMethod; redirect }>());

export const editPrintOrder = createAction(
  EDIT_PRINT_ORDER,
  props<{ order; deliveryMethod; redirect }>(),
);

export const addWarehouses = createAction(ADD_WAREHOUSES, props<{ warehouses }>());

export const addCredits = createAction(ADD_CREDITS, props<{ credits }>());

export const updateRegion = createAction(UPDATE_REGION, props<{ region: string }>());

export const getRegion = createAction(
  GET_REGION,
  props<{ from: object; to: object; processType: string }>(),
);

export const addPayments = createAction(ADD_PAYMENTS, props<{ payments }>());

export const addActiveProcesses = createAction(ADD_ACTIVE_PROCESSES, props<{ processes }>());

export const submittingForm = createAction(SUBMITTING_FORM, props<{ submitting }>());

export const uploadOrders = createAction(UPLOAD_ORDERS, props<{ orders; processType }>());

export const uploadOrderSuccess = createAction(UPLOAD_ORDER_SUCCESS, props<{ row }>());

export const uploadOrderError = createAction(UPLOAD_ORDER_ERROR, props<{ row }>());

export const uploadOrdersDone = createAction(UPLOAD_ORDERS_DONE);

export const submittedForm = createAction(SUBMITTED_FORM);
export const resetForm = createAction(RESET_FORM);
export const refreshProfile = createAction(REFRESH_PROFILE);

export const actions = {
  initCreate,
  saveOrder,
  savePrintOrder,
  saveReturnOrder,
  savePrintReturnOrder,
  addWarehouses,
  addCredits,
  addPayments,
  addActiveProcesses,
  submittingForm,
  submittedForm,
  resetForm,
  updateRegion,
  getRegion,
  uploadOrders,
  uploadOrderSuccess,
  uploadOrderError,
  uploadOrdersDone,
};

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsEvent } from '@core/models/analyticsEvent';
import { AnalyticsService } from '@core/services/common/analytics.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import SwiperCore, { Pagination, Swiper, SwiperOptions } from 'swiper';
import { InitialState } from '../../reducers/authentication';
import { userName } from '../../selectors/authentication';
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  displayBtnOnMobile = false;
  infoCards: { heading: string; img: string; text: string }[];
  userName$: Observable<string> = this.store.select(userName);
  lastSlideIndex: number = 2;
  currentSlideIndex: number = 0;
  swiper: Swiper;
  config: SwiperOptions = {
    direction: 'horizontal',
    pagination: true,
  };

  constructor(
    private router: Router,
    private store: Store<InitialState>,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.infoCards = [
      {
        heading: 'Create My Profile',
        img: 'assets/images/auth/forms.svg',
        text: 'Create your profile by adding your company’s details and warehouse address',
      },
      {
        heading: 'Create An Order',
        img: 'assets/images/auth/delivery-truck.svg',
        text: 'Create your first order and fill in all the details',
      },
      {
        heading: 'Buy Credits',
        img: 'assets/images/auth/coins.svg',
        text: 'Buy a prepaid credit bundle and get your parcels on the go',
      },
    ];

    const event = new AnalyticsEvent('signup', 'view', 'getting_started_view', 'in_progress', 3, 6);
    this.analyticsService.logEvent(event);
  }

  continue() {
    this.router.navigate(['/create-profile']);
    const event = new AnalyticsEvent(
      'signup',
      'click',
      'getting_started_continue',
      'in_progress',
      3,
      7,
    );
    this.analyticsService.logEvent(event);
  }

  onSlideChange() {
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  onSwiperInit(swiper: Swiper): void {
    this.swiper = swiper;
    this.displayBtnOnMobile = true;
  }

  swiperBtnAction(): void {
    if (this.currentSlideIndex === this.lastSlideIndex) {
      this.continue();
    } else {
      this.swiper.slideNext();
    }
  }
}

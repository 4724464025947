import {
  Component,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

import { SearchService } from '../../services/search.service';

import { debounce } from '../../../../../../../helpers/debounce';

import { QueryInput, AddressResult } from '../../models/json-search';

@Component({
  selector: 'app-json-search',
  templateUrl: './json-search.html',
  styleUrls: ['./json-search.scss'],
})
export class JsonSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() open?: boolean = false;
  @Input() label?: string = 'Search';
  @Input() value?: object;
  @Input() resultLimit?: number = 10;
  @Input() processType?: string;
  @Input() showCityInput?: boolean = false;
  @Input() showPostalCodeInput?: boolean = true;
  @Input() suburbRequiredError?: boolean = false;
  @Input() returnUserTypedSuburb?: boolean = true;
  @Input() preliminarySuburb?: string;
  @Input() preliminaryPostalCode?: string;
  @Input() preliminaryCity?: string;
  @Input() noMatchesForPreliminaryAddress?: boolean;

  @Output() search: EventEmitter<any> = new EventEmitter();

  @ViewChild('suburb', { read: ElementRef }) suburb: ElementRef;
  @ViewChild('postalCode', { read: ElementRef }) postalCode: ElementRef;
  @ViewChild('city', { read: ElementRef }) city: ElementRef;

  results?: AddressResult[];
  selectedResult?: any;
  searching: boolean = false;
  postalCodeInputDisabled: boolean = true;
  cityInputDisabled: boolean = true;
  whiteSpaceError: boolean = false;

  debouncedHandleInputChange = debounce(1000, this.handleInputChange.bind(this))();

  query: QueryInput = {
    suburb: undefined,
    postalCode: undefined,
    city: undefined,
  };

  noMatches: boolean = false;

  constructor(private elementRef: ElementRef, private searchService: SearchService) {}

  ngOnInit() {
    this.onDocumentClick = this.onDocumentClick.bind(this);
    document.addEventListener('click', this.onDocumentClick);
  }

  ngAfterViewInit() {
    if (this.preliminarySuburb) {
      this.suburb.nativeElement.value = this.preliminarySuburb;
    }
    if (this.preliminaryPostalCode) {
      this.postalCode.nativeElement.value = this.preliminaryPostalCode;
    }
    if (this.preliminaryCity) {
      this.city.nativeElement.value = this.preliminaryCity;
    }
  }

  handleSearchSuburb(value: string) {
    this.whiteSpaceError = false;
    if (value.length === 0) {
      this.clearInputFields();
    } else if (value.trim().length === 0) {
      this.whiteSpaceError = true;
    } else {
      this.debouncedHandleInputChange(value, 'suburb');
    }
  }

  clearInputFields() {
    this.suburb.nativeElement.value = '';
    if (this.showPostalCodeInput) {
      this.postalCode.nativeElement.value = '';
    }
    if (this.showCityInput) {
      this.city.nativeElement.value = '';
    }

    this.open = false;
  }

  async handleInputChange(value: string, type: string) {
    this.searching = true;
    this.query[type] = value;
    await this.searchService.search(this.query, this.processType);
    const result = this.searchService.results;

    this.searching = this.searchService.loading;

    if (result && result.data && result.data.matches) {
      this.results = result.data.matches;
      this.open = true;
      this.noMatches = false;
    } else {
      this.noMatches = true;
    }
  }

  selectResultValue(result: AddressResult) {
    this.search.emit(result);

    this.selectedResult = result;
    this.open = false;
    this.results = undefined;
    this.searchService.clearResults();

    this.suburb.nativeElement.value = result.suburb;
    this.suburb.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));

    if (this.showPostalCodeInput) {
      this.postalCode.nativeElement.value = result.postalCode;
      this.postalCode.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }

    if (this.showCityInput) {
      this.city.nativeElement.value = result.city;
      this.city.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }
  }

  toggleDropdown() {
    this.open = !this.open;
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  protected onDocumentClick(event: MouseEvent) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      this.open = true;
    } else {
      this.searchService.clearResults();
      if (this.returnUserTypedSuburb && this.open) {
        this.search.emit({
          suburb: this.suburb.nativeElement.value.trim(),
          postalCode: this.postalCode ? this.postalCode.nativeElement.value.trim() : '',
          province: '',
          city: '',
        });
      }
      this.open = false;
    }
  }
}

import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChange,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SearchService } from '../../../molecules/json-search/services/search.service';

import * as R from 'ramda';

import { ORDER_ICONS } from '../../../../../../icons';
import { CustomIcon } from '../../../../../../core/models/icons';
import { waybillTableHeader, phoneNumberErr } from './constants/data-table-constants';
import { ORDER_TYPES } from '@orders/constants/order';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.html',
  styleUrls: ['./data-table.scss'],
})
export class DataTableComponent implements OnInit, OnChanges {
  @Input() editable: boolean;
  @Input() data: object[];
  @Input() processType: string;
  @Input() validation?: object[];
  @Input() editableRows?: boolean[];
  @Input() loading?: boolean;
  @Input() delete?: boolean = true;
  @Input() edit?: boolean = true;
  @Input() disableAddressValidation?: boolean = false;

  @Input() search?: boolean;
  @Input() errorHeading?: string;
  @Input() showErrorSummary?: boolean;

  @Input() showActions?: boolean = true;

  @Input() chunkSize: number = 10;
  @Input() headers: string[];

  @Output() handleChangeOrders = new EventEmitter();
  @Output() handleSaveOrders = new EventEmitter();
  @Output() handleClearOrders = new EventEmitter();
  @Output() isEditing = new EventEmitter();
  @Output() containsErrors = new EventEmitter();

  ngOnChangesTriggeredOnInit: boolean = false;
  chunkIndex: number = 0;
  chunks: any = [];
  current: number = 0;

  sort: { direction: string; by: string } = { direction: 'asc', by: '' };

  searchResults: any = undefined;
  searchFn = (val) => R.filter(R.compose(R.any(R.contains(val)), R.values));
  searchValue: string = undefined;

  pagination: {
    current: number;
    steps: number;
    previous: number[];
    next: number[];
  } = {
    previous: [],
    next: [],
    current: 0,
    steps: 3,
  };

  errors: object = {};
  errorAmount: number = 0;
  errorDescription: string = undefined;

  currentPage: object[];

  editing: number;

  chunkOptions = [
    {
      label: '10',
      value: 10,
    },
    {
      label: '25',
      value: 25,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ];

  currentChunkOption = {
    label: '10',
    value: 10,
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private searchService: SearchService,
  ) {
    ORDER_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  ngOnInit(): void {
    this.setHeaders();
    this.chunkData();
  }

  get hasErrors() {
    return this.errors && Object.values(this.errors).length !== 0;
  }

  tableBodyCellClass(error, search, editing) {
    let className = 'data-table__body-cell';

    if (error) {
      className = className.concat(' data-table__body-cell--error');
    }

    if (search) {
      className = className.concat(' data-table__body-cell--search');
    }

    if (editing) {
      className = className.concat(' data-table__body-cell--editing');
    }

    return className;
  }

  toString(value) {
    return String(value);
  }

  showValue(rowNumber: number, cellNumber: number) {
    const rowBeingEdited = this.current * this.chunkSize + this.editing;
    if (rowNumber === rowBeingEdited) {
      return false;
    }

    if (this.errors && this.errors[rowNumber] && this.errors[rowNumber][cellNumber] === false) {
      return false;
    }

    return true;
  }

  isNonEditableCell(cellNumber: number) {
    return (
      this.headers[cellNumber] === waybillTableHeader ||
      (this.headers[cellNumber] === 'pickup point' && this.processType === ORDER_TYPES.P2W_RETURN)
    );
  }

  changeChunkSize(e) {
    this.currentChunkOption = e;
    const { value } = e;
    this.chunkSize = value;
    this.chunkData();
  }

  displayValue(validation, column, value) {
    if (validation && validation[column] && validation[column].options) {
      return this.displayOptionValue(validation[column].options, value);
    }

    if (validation[column].search) {
      return Object.values(value).join(' ');
    }

    return value;
  }

  displayOptionValue(options, value) {
    const option = options.find((option) => option.value == value);
    if (option) {
      return option.label;
    }
  }

  isBlank(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value.length === 0 ||
      (typeof value == 'string' && !!value.match(/^\s*$/)) ||
      (typeof value == 'object' && Object.keys(value).length == 0)
    );
  }

  setErrorDescription() {
    this.errorDescription = `The following rows contain errors: <b>${Object.keys(this.errors)
      .map((_, index) => index + 1)
      .filter((x) => x)
      .join(', ')}</b><br/<br/> Please fix the errors table before you can add the orders.`;
  }

  setHeaders() {
    if (!this.headers) {
      const [first] = this.data;
      this.headers = Object.keys(first);
    }
  }

  setCurrentPage() {
    this.currentPage = this.chunks[this.current];
  }

  async validateSearch(value, row, cell, search) {
    let match = undefined;
    const { postalCode, suburb, province, city } = value;

    const query = {
      postalCode,
      suburb,
      province,
      city,
    };

    await this.searchService.search(query, this.processType);
    const result = this.searchService.results;

    if (result && result.data) {
      const { matches } = result.data;

      if (matches && matches.length === 1) {
        match = matches[0];
      } else if (matches && matches.length > 1) {
        match = [...matches].find((result) => {
          const { postalCode: postalCodeResult, suburb: suburbResult } = result;

          return (
            postalCode === postalCodeResult && suburb.toUpperCase() === suburbResult.toUpperCase()
          );
        });
      }
    }

    if (match) {
      const value = {
        postalCode: match.postalCode,
        suburb: match.suburb,
        city: match.city,
        province: match.province,
      };

      this.updateCellValue(row, cell, value);
      return true;
    } else {
      return false;
    }
  }

  async validateCellValue(value, row, cell) {
    if (this.validation === undefined || this.validation[cell] === undefined) {
      return true;
    } else {
      const { required, regex, options, search, message }: any = this.validation[cell];

      if (required && this.isBlank(value)) {
        return false;
      }

      if (search && !this.isBlank(value) && !this.disableAddressValidation) {
        return this.validateSearch(value, row, cell, search);
      }

      if (options) {
        const result = options.find((option) => String(option.value) === String(value));
        return result ? true : false;
      }

      if (regex && !search && !options) {
        if (message === phoneNumberErr) {
          value = value?.replace(/ /g, '');
        }
        const expression = new RegExp(regex, 'g');
        const result = expression.exec(value);

        if (result === null) {
          return false;
        }
      }

      return true;
    }
  }

  async validateRowValues(values, row) {
    const validationPromises = await values.map(async (value, cell) => {
      return this.validateCellValue(value, row, cell);
    });

    const result = await Promise.all(validationPromises);

    if (result) {
      return result;
    }
  }

  rowHasErrors(rowNumber: number) {
    const values = this.errors[this.current * this.chunkSize + rowNumber];
    if (values?.length) {
      return values.some((value: boolean) => value === false);
    }
    return false;
  }

  checkRowErrors(rowValidatedValues, rowNumber: number) {
    const hasErrors = rowValidatedValues.some((v) => {
      return v === false;
    });

    if (hasErrors === true) {
      this.errors[rowNumber] = rowValidatedValues;
    }

    if (hasErrors === false && this.errors[rowNumber]) {
      delete this.errors[rowNumber];
    }

    this.containsErrors.emit({
      errors: this.hasErrors,
      amount: Object.keys(this.errors).length,
    });
  }

  async validate(rowNumber = null) {
    const data = rowNumber !== null ? [this.data[rowNumber]] : this.data;
    const validated = data.map(async (item, row) => {
      const values = Object.values(item);

      const validatedValues = await this.validateRowValues(values, row);
      this.checkRowErrors(validatedValues, row);
      return true;
    });

    return Promise.all(validated);
  }

  async chunkData(data = this.data, rowNumber = null) {
    this.chunkIndex = 0;
    this.chunks = [];

    if (this.validation) {
      await this.validate(rowNumber);
    }

    if (data && data.length < this.chunkSize) {
      let index = 0;
      this.editing = undefined;

      const itemsValueIndex = data.map((row) => {
        const values = Object.values(row);
        const addedIndex = [index, ...values];
        index++;
        return addedIndex;
      });

      this.chunks[0] = itemsValueIndex;

      while (!this.chunks[this.current]) {
        this.current--;
      }
    } else if (data?.length >= this.chunkSize) {
      let index = 0;
      this.editing = undefined;

      while (this.chunkIndex < data.length) {
        const items = data.slice(this.chunkIndex, this.chunkSize + this.chunkIndex);

        const itemsValueIndex = items.map((row) => {
          const values = Object.values(row);
          const addedIndex = [index, ...values];
          index++;
          return addedIndex;
        });

        this.chunks.push(itemsValueIndex);
        this.chunkIndex += this.chunkSize;
      }
    }

    this.setPagination();
  }

  removeIndex(row) {
    const [_, ...rest] = row;
    return rest;
  }

  async sortOrders(by) {
    const { direction: currentDirection } = this.sort;
    const direction = currentDirection === 'asc' ? 'desc' : 'asc';
    this.sort = { direction, by };

    this.data = await this.sortFn();
    this.chunkData();
  }

  handleSearch(event) {
    const value = event.target.value;
    const result = this.searchFn(value)(this.data);

    this.chunkData(result);

    this.searchValue = value;
  }

  clearSearch() {
    this.searchValue = undefined;
    this.chunkData();
  }

  async sortFn() {
    const { by, direction } = this.sort;

    const order =
      direction === 'asc' ? R.ascend(R.path(by.split('|'))) : R.descend(R.path(by.split('|')));

    return R.sort(order, this.data);
  }

  async setPagination(current = this.current) {
    const { steps } = this.pagination;

    this.pagination = {
      ...this.pagination,
      previous: this.chunks
        .map((_, i: number) => {
          if (current - steps >= 0 && i < current - steps) {
            return i;
          }
        })
        .filter((x) => x),
      next: this.chunks
        .map((_, i: number) => {
          if (i > current && i < this.chunks.length && current + steps >= i) {
            return i;
          }
        })
        .filter((x) => x),
      current: current,
    };

    this.current = current;
    this.setCurrentPage();
  }

  removeItem(index: number) {
    const deleteItemIndex = this.currentChunkOption.value * this.current + index;

    this.data = this.data.filter((item, i) => i !== deleteItemIndex);
    this.errors = this.data.filter((item, i) => i !== deleteItemIndex);

    this.chunkData();
  }

  handleEdit(index) {
    this.isEditing.emit(true);
    this.editing = index;
  }

  async saveEdit() {
    await this.validate();
    this.isEditing.emit(false);
    this.editing = undefined;
  }

  editSearchValue(value, dataIndex, cellNumber, fields) {
    const hasEmptyString = Object.values(value).some((val) => val === '');

    if (value && !hasEmptyString) {
      const val = {
        postalCode: value.postalCode,
        suburb: value.suburb,
        city: value.city,
        province: value.province,
      };

      this.editValue(val, dataIndex, cellNumber, true);
    }
  }

  updateCellValue(rowNumber, cellNumber, value) {
    const row = this.data[rowNumber];

    Object.keys(row).forEach((key, index) => {
      if (index === cellNumber) {
        row[key] = value;
        return;
      }
    });

    this.data[rowNumber] = row;
  }

  async editValue(event, rowNumber, cellNumber, isSearch = false) {
    let value = undefined;
    let isValid = false;

    if (isSearch === false) {
      if (typeof event === 'string') {
        value = event;
      } else {
        value = event.target.value;
      }

      isValid = await this.validateCellValue(value, rowNumber, cellNumber);
    }

    if (isSearch) {
      value = event;

      if (this.errors && this.errors[rowNumber]) {
        this.errors[rowNumber][cellNumber] = true;
        this.checkRowErrors(this.errors[rowNumber], rowNumber);
      }

      this.updateCellValue(rowNumber, cellNumber, value);
      this.chunkData(this.data, rowNumber);

      this.handleChangeOrders.emit({ ...this.data, edited: true });
    }

    if (isValid && isSearch === false) {
      if (this.errors && this.errors[rowNumber]) {
        this.errors[rowNumber][cellNumber] = isValid;
        this.checkRowErrors(this.errors[rowNumber], rowNumber);
      }

      this.updateCellValue(rowNumber, cellNumber, value);

      this.chunkData(this.data, rowNumber);

      this.handleChangeOrders.emit({ ...this.data, edited: true });
    }
  }

  saveOrders() {
    this.handleSaveOrders.emit(this.data);
  }

  clearOrders() {
    this.handleClearOrders.emit();
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (this.ngOnChangesTriggeredOnInit && changes.data && this.data) {
      if (changes.data && this.data) {
        this.chunkData();
      }
    }
    if (!this.ngOnChangesTriggeredOnInit) {
      this.ngOnChangesTriggeredOnInit = true;
    }
  }
}

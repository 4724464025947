import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-modal-button',
  templateUrl: './modal-button.html',
  styleUrls: ['./modal-button.scss'],
})
export class ModalButton {
  @Input() icon: string;
  @Input() amount;
  @Input() label: string;
  @Input() component: any;
  @Input() classEnhance: string;
  @Input() data: object;
  @Input() options: { width?: string; height?: string; maxWidth?: string } = {
    width: '600px',
    maxWidth: '100%',
  };

  @Input() confirm: any;
  @Input() disabled: boolean;
  @Input() type?: string;

  constructor(public dialog: MatDialog) {}

  get className() {
    if (this.disabled) {
      return `${this.type && this.type === 'link' ? 'modal-link' : 'modal-button'} ${
        this.classEnhance
      } modal-button--disabled`;
    } else {
      return `${this.type && this.type === 'link' ? 'modal-link' : 'modal-button'} ${
        this.classEnhance
      }`;
    }
  }

  get isPargoIcon() {
    return this.icon.includes('pargo');
  }

  openModal(): void {
    if (!this.disabled) {
      const ref = this.dialog.open(this.component, {
        data: this.data,
        width: this.options.width,
      });

      ref.afterClosed().subscribe((result) => {
        if (this.confirm) {
          this.confirm(result);
        }
      });
    }
  }
}

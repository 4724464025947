import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';

import { Warehouse } from '../../models/warehouse';

import { WAREHOUSE_ICONS } from '../../../../icons';
import { CustomIcon } from '../../../../core/models/icons';
import { selectDevice } from '@core/selectors/core';
import { take } from 'rxjs/internal/operators/take';
import {
  WarehouseSelectionOptions,
  WarehouseType,
} from '../molecules/table/information-table/models/warehouse-options';

import { isNumeric, isSystemGeneratedWarehouseRef } from '@shared/helpers/functions';

@Component({
  selector: 'app-warehouse-details',
  templateUrl: './warehouse-details.html',
  styleUrls: ['./warehouse-details.scss'],
})
export class WarehouseDetailsComponent implements OnChanges, OnInit {
  @Input() warehouses: Warehouse[];
  @Input() cb?: any;
  @Input() selected?: string;
  @Input() isReturn?: string;
  @Input() userCanEditProfile?: boolean;

  @Output() warehouseToEdit = new EventEmitter<{ action: string; warehouse: Warehouse }>();

  current: number = 0;
  currentSlide: Element;
  isMobile: boolean = false;
  tableData: string[][];
  tableSortableColumns: number[] = [];
  tableHeaders: string[] = [];
  columnWidths: number[] = [];
  warehousesForOrderCreation: boolean = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private store: Store<any>,
    private elementRef: ElementRef,
  ) {
    WAREHOUSE_ICONS.forEach(({ path, name }: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  ngOnInit() {
    this.warehousesForOrderCreation = this.cb ? true : false;
    this.store
      .select(selectDevice)
      .pipe(take(1))
      .subscribe((device) => {
        this.isMobile = device.mobile;
        if (!this.isMobile) {
          this.processWarehouseData();
        }
      });

    this.tableHeaders = [
      'Ref#',
      ...(this.warehousesForOrderCreation ? ['Selected'] : []),
      ...(this.userCanEditProfile ? ['Primary', 'Primary return'] : []),
      ...(this.warehousesForOrderCreation
        ? []
        : ['Contact person', 'Email address', 'Phone number']),
      'Address',
      ...(this.warehousesForOrderCreation || !this.userCanEditProfile ? [] : ['']),
    ];

    this.columnWidths = [
      40,
      ...(this.warehousesForOrderCreation ? [20] : []),
      ...(this.userCanEditProfile ? [60, 60] : []),
      ...(this.warehousesForOrderCreation ? [] : [100, 60, 50]),
      250,
      ...(this.warehousesForOrderCreation ? [] : [50]),
    ];
  }

  ngOnChanges() {
    if (this.isMobile) {
      if (this.selected && this.warehouses) {
        this.warehouses.forEach((warehouse, index) => {
          if (String(warehouse.reference) === String(this.selected)) {
            this.current = index;
          }
        });
      }

      if (this.selected === undefined && this.warehouses) {
        this.warehouses.forEach((warehouse, index) => {
          if (warehouse.primary === true) {
            this.selected = warehouse.reference;
            this.current = index;
          }
        });
      }
    } else {
      if (!this.warehousesForOrderCreation) {
        this.processWarehouseData();
      }
    }
  }

  selectWarehouse(reference: string): void {
    if (this.cb) {
      const warehouse = this.warehouses.find(
        (currentWarehouse) => currentWarehouse.reference === reference,
      );
      this.cb(warehouse);
    }
  }

  warehouseClass(warehouse) {
    return warehouse.primary
      ? 'warehouse-details__warehouse warehouse-details__warehouse--primary'
      : 'warehouse-details__warehouse';
  }

  goToSlide(slideNumber: number) {
    this.current = slideNumber;
    this.scroll();
  }

  scroll = () => {
    this.currentSlide = this.elementRef.nativeElement.querySelector('.slide-' + this.current);
    this.currentSlide.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

  goLeft = () => {
    if (this.current !== 0) {
      this.current = this.current - 1;
    } else {
      this.current = this.warehouses.length - 1;
    }
    this.scroll();
  };

  goRight = () => {
    if (this.current !== this.warehouses.length - 1) {
      this.current = this.current + 1;
    } else {
      this.current = 0;
    }
    this.scroll();
  };

  createUnitAndStreetText(warehouse: Warehouse): string {
    let address = '';
    if (warehouse.address2 && warehouse.address1) {
      let unitStr = isNumeric(warehouse.address1) ? 'Unit ' : '';
      address = `${unitStr}${warehouse.address1}, ${warehouse.address2}, `;
    } else if (warehouse.address1) {
      address = `${warehouse.address1}, `;
    } else {
      address = `${warehouse.address2}, `;
    }

    return address;
  }

  processWarehouseData(): void {
    this.tableData = this.warehouses.map((warehouse: any) => {
      const unitAndStreet = this.createUnitAndStreetText(warehouse);
      const {
        reference,
        primary,
        primary_return,
        contactName,
        email,
        phoneNumber,
        city,
        province,
      } = warehouse;

      return [
        [reference, 'warehouse-reference'],
        ...(this.userCanEditProfile
          ? [
              [this.selected ? reference == this.selected : primary, WarehouseType.PRIMARY],
              [this.selected ? reference == this.selected : primary_return, WarehouseType.RETURN],
            ]
          : []),
        ...(this.warehousesForOrderCreation
          ? [
              this.isReturn
                ? [
                    this.selected ? reference == this.selected : primary_return,
                    WarehouseType.RETURN,
                  ]
                : [this.selected ? reference == this.selected : primary, WarehouseType.PRIMARY],
            ]
          : [contactName, email, phoneNumber]),
        `${unitAndStreet}${city}, ${province}`,
        ...(this.warehousesForOrderCreation || !this.userCanEditProfile
          ? []
          : [['edit-pargo', 'edit-icon']]),
      ];
    });

    this.sortWarehouseData();
  }

  updateSelectedWarehouse(selectionOption: WarehouseSelectionOptions): void {
    if (this.warehousesForOrderCreation) {
      this.tableData.forEach((warehouse: any) => {
        warehouse[1][0] = warehouse[0][0] === selectionOption.reference;
      });
      this.selectWarehouse(selectionOption.reference);
    } else {
      this.selectWarehouseToEditDefaults(selectionOption);
    }
  }

  selectWarehouseToEdit(reference: string): void {
    const warehouse = this.warehouses.find(
      (currentWarehouse) => currentWarehouse.reference === reference,
    );
    this.warehouseToEdit.emit({
      action: 'editFullWarehouse',
      warehouse,
    });
  }

  selectWarehouseToEditDefaults(selectionOption: WarehouseSelectionOptions): void {
    const warehouse = this.warehouses.find(
      (currentWarehouse) => currentWarehouse.reference === selectionOption.reference,
    );

    const warehouseCopy = { ...warehouse };
    if (selectionOption.warehouseType === WarehouseType.PRIMARY) {
      warehouseCopy.primary = true;
      this.warehouseToEdit.emit({
        action: 'editWarehousePrimaryDefaults',
        warehouse: warehouseCopy,
      });
    } else {
      warehouseCopy.primary_return = true;
      this.warehouseToEdit.emit({
        action: 'editWarehousePrimaryReturnsDefaults',
        warehouse: warehouseCopy,
      });
    }
  }

  sortWarehouseData(): void {
    // sort by reference number, but make sure the selected warehouse is always first
    this.tableData.sort((a, b) => {
      if (
        this.warehousesForOrderCreation &&
        ((this.selected && a[0][0] === this.selected) || a[1][0])
      ) {
        return -1;
      } else if (
        this.warehousesForOrderCreation &&
        ((this.selected && b[0][0] === this.selected) || b[1][0])
      ) {
        return 1;
      } else {
        if (isSystemGeneratedWarehouseRef(a[0][0]) && isSystemGeneratedWarehouseRef(b[0][0])) {
          const aNumber = Number(a[0][0].substring(1));
          const bNumber = Number(b[0][0].substring(1));
          if (aNumber < bNumber) {
            return -1;
          } else {
            return 1;
          }
        } else {
          if (a[0][0].toLowerCase() < b[0][0].toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        }
      }
    });
  }
}

import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { showStep, addWalkthroughStep } from '../../actions/walkthrough';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.html',
  styleUrls: ['./walkthrough.scss'],
})
export class WalkthroughComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() text: string;
  @Input() title: string;
  @Input() attachedToElement: string;
  @Input() attachedToPosition?: string;
  @Input() page?: string;

  @Input() enabled: boolean;
  @Input() current: { id: string };

  @ViewChild('pulse', { static: false }) pulse: ElementRef;

  registered: boolean = false;

  step: object;

  constructor(private store: Store<any>) {}

  ngOnInit() {
    if (this.enabled) {
      this.setupStep();
    }
  }

  ngOnChanges() {
    if (this.enabled) {
      this.setupStep();
    }
  }

  displayStep() {
    if (this.id && this.registered) {
      this.store.dispatch(showStep({ id: this.id }));
    }
  }

  setupStep() {
    this.step = {
      id: this.id,
      text: this.text,
      title: this.title,
      attachedTo: {
        element: this.attachedToElement,
        on: this.attachedToPosition,
      },
    };

    setTimeout(() => {
      const elements: NodeListOf<HTMLElement> = document.querySelectorAll(this.attachedToElement);
      const element: HTMLElement = elements[0];

      if (element && this.registered === false) {
        this.positionPulse(element);

        this.registered = true;
        this.store.dispatch(addWalkthroughStep({ step: this.step }));
      }
    }, 10);
  }

  positionPulse(element: HTMLElement) {
    if (this.pulse && this.pulse.nativeElement) {
      const pulse: HTMLElement = this.pulse.nativeElement;

      const bodyRect = document.body.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      const offsetTop = elementRect.top - bodyRect.top;

      const offSetLeft = elementRect.left < 12 ? 24 : elementRect.left;

      pulse.style.left = `${offSetLeft}px`;
      pulse.style.top = `0px`;
      pulse.style.marginTop = `${offsetTop + 12}px`;
    }
  }
}

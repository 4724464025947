<div class="json-search">
  <div class="json-search__fields">
    <pargo-input
      class="json-search__input-container"
      [ngClass]="{ ' full-width' : !showCityInput && !showPostalCodeInput} "
      label="Suburb"
    >
      <input
        #suburb
        class="input json-search__input"
        type="text"
        [disabled]="searching"
        (keyup)="handleSearchSuburb(suburb.value)"
      />
    </pargo-input>

    <pargo-input
      *ngIf="showPostalCodeInput"
      class="json-search__input-container"
      label="Postal code"
    >
      <input
        #postalCode
        class="input json-search__input"
        type="text"
        [readOnly]="postalCodeInputDisabled"
        disabled
      />
    </pargo-input>
    <pargo-input
      *ngIf="showCityInput"
      class="json-search__input-container margin-left"
      label="City"
    >
      <input
        #city
        class="input json-search__input"
        type="text"
        [readOnly]="cityInputDisabled"
        disabled
      />
    </pargo-input>
  </div>

  <small class="json-search__error input__error" *ngIf="noMatches">
    No results have been found
  </small>
  <small class="json-search__error input__error" *ngIf="suburbRequiredError">
    This field is required.
  </small>
  <small class="json-search__error input__error" *ngIf="whiteSpaceError">
    The field cannot consist of whitespace characters only.
  </small>
  <small class="input__error" *ngIf="noMatchesForPreliminaryAddress">
    We can't seem to find this address combination on our system. Please use the list provided to
    find a more suitable match.
  </small>
  <ul class="json-search__options" *ngIf="results && results.length && open">
    <li
      class="json-search__option"
      *ngFor="let result of results"
      (click)="selectResultValue(result)"
    >
      <span> {{result.postalCode}} {{result.suburb}} {{result.city}} {{result.province}} </span>
    </li>
  </ul>
</div>

<div class="welcome">
  <app-sign-up-header
    [heading]="'Hi ' + (userName$ | async) + ', Let\'s Get Started!'"
    subHeading="You're a step closer to offering a world of convenient delivery options to your customers"
  ></app-sign-up-header>
  <div class="welcome__info-container">
    <div *ngFor="let card of infoCards" class="welcome__info-container-card">
      <h2 class="welcome__info-container-card-heading">{{ card.heading }}</h2>
      <img class="welcome__info-container-card-img" [src]="card.img" />
      <p class="welcome__info-container-card-text">
        {{ card.text }}
      </p>
    </div>
  </div>

  <div class="welcome__swiper-container">
    <swiper [config]="config" (slideChange)="onSlideChange()" (swiper)="onSwiperInit($event)">
      <ng-template *ngFor="let card of infoCards" swiperSlide>
        <div class="welcome__info-container-card">
          <h2 class="welcome__info-container-card-heading">{{ card.heading }}</h2>
          <img class="welcome__info-container-card-img" [src]="card.img" />
          <p class="welcome__info-container-card-text">
            {{ card.text }}
          </p>
        </div>
      </ng-template>
    </swiper>
  </div>

  <div class="welcome__button-container--web">
    <pargo-button
      class="welcome__button"
      type="submit"
      label="Continue"
      (click)="continue()"
    ></pargo-button>
  </div>

  <div *ngIf="displayBtnOnMobile" class="welcome__button-container--mobile">
    <pargo-button
      class="welcome__button"
      type="submit"
      [label]="currentSlideIndex === lastSlideIndex ? 'Continue' : 'Next'"
      (click)="swiperBtnAction()"
    ></pargo-button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginPageComponent } from './pages/login/login.component';
import { SignUpPageComponent } from './pages/sign-up/sign-up.component';

import { ResetPasswordPageComponent } from './pages/reset-password/reset-password.component';
import { ResetRequestPageComponent } from './pages/reset-request/reset-request.component';
import { ConfirmPageComponent } from './pages/confirm/confirm.component';
import { WelcomePageComponent } from './pages/welcome/welcome.component';
import { CreateProfilePageComponent } from './pages/business-info/create-profile/create-profile.component';
import { CreateWarehousePageComponent } from './pages/business-info/create-warehouse/create-warehouse.component';
import { SignUpCompletionPageComponent } from './pages/sign-up-completion/sign-up-completion.component';
import { SignUpGuardService } from './services/signup-guard.service';

const authenticationRoutes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'sign-up',
    component: SignUpPageComponent,
    pathMatch: 'full',
  },
  { path: 'confirm', component: ConfirmPageComponent, pathMatch: 'full' },
  { path: 'reset', component: ResetPasswordPageComponent, pathMatch: 'full' },
  { path: 'forgot-password', component: ResetRequestPageComponent, pathMatch: 'full' },
  {
    path: 'welcome',
    component: WelcomePageComponent,
    pathMatch: 'full',
    canActivate: [SignUpGuardService],
  },
  {
    path: 'create-profile',
    component: CreateProfilePageComponent,
    pathMatch: 'full',
    canActivate: [SignUpGuardService],
  },
  {
    path: 'create-warehouse',
    component: CreateWarehousePageComponent,
    pathMatch: 'full',
    canActivate: [SignUpGuardService],
  },
  {
    path: 'sign-up-completion',
    component: SignUpCompletionPageComponent,
    pathMatch: 'full',
    canActivate: [SignUpGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(authenticationRoutes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}

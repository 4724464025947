import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-request',
  templateUrl: './reset-request.component.html',
  styleUrls: ['./reset-request.component.scss'],
})
export class ResetRequestPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

<div class="signature" style="font-family: 'Open Sans', Arial, sans-serif">
  <div class="signature__logo-container">
    <img class="signature__logo" src="assets/images/signature/logo.png" />
  </div>
  <div class="signature__content">
    <div class="signature__header">
      <h2 class="signature__name">{{name}}</h2>
      <p class="signature__title">{{title}}</p>
    </div>
    <div class="signature__body">
      <div class="signature__body-content">
        <div class="signature__phone-container">
          <img class="signature__phone-image" src="assets/images/signature/phone.png" />
          <p class="signature__phone">+27 (0) {{phone}}</p>
        </div>
        <div class="signature__phone-container">
          <img class="signature__phone-image" src="assets/images/signature/mobile.png" />
          <p class="signature__phone">+27 (0) {{mobile}}</p>
        </div>
      </div>
      <div class="signature__email-container">
        <img class="signature__email-image" src="assets/images/signature/mail.png" />
        <p class="signature__email">{{email}}</p>
      </div>

      <p class="signature__address">62 Roeland Street, Gardens, Cape Town</p>
      <p class="signature_campaign-link">
        <a href="https://www.youtube.com/watch?v=m0PTpw0ObLI" target="_blank"
          >Click here for our latest campaign</a
        >
      </p>
    </div>
    <div class="signature__footer">
      <a class="signature__website-link" href="https://www.pargo.co.za" target="_blank">
        <img class="signature__website-image" src="assets/images/signature/arrow.png" />
        <h2 class="signature__website">pargo.co.za</h2>
      </a>
      <ul class="signature__socials">
        <li class="signature__socials-item">
          <a
            href="https://www.linkedin.com/company/pargo"
            target="_blank"
            class="signature__socials-link"
          >
            <img class="signature__social-image" src="assets/images/signature/linkedin.png" />
          </a>
        </li>
        <li class="signature__socials-item">
          <a href="https://twitter.com/pargopoint" target="_blank" class="signature__socials-link">
            <img class="signature__social-image" src="assets/images/signature/twitter.png" />
          </a>
        </li>
        <li class="signature__socials-item">
          <a
            href="https://www.instagram.com/pargopoint/"
            target="_blank"
            class="signature__socials-link"
          >
            <img class="signature__social-image" src="assets/images/signature/instagram.png" />
          </a>
        </li>
        <li class="signature__socials-item">
          <a
            href="https://www.facebook.com/pages/Pargo/164975920356386"
            target="_blank"
            class="signature__socials-link"
          >
            <img class="signature__social-image" src="assets/images/signature/facebook.png" />
          </a>
        </li>
        <li class="signature__socials-item">
          <a
            href="https://www.youtube.com/channel/UCbuaDzotu3KiMHvnNE9AUQQ"
            target="_blank"
            class="signature__socials-link"
          >
            <img class="signature__social-image" src="assets/images/signature/youtube.png" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

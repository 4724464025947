import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { InformationTable } from './components/information-table/information-table';

import { ButtonModule } from '../../../atoms/button/button.module';
import { CtaModule } from '../../../atoms/cta/cta.module';
import { InputModule } from '../../../atoms/input/input.module';
import { DropdownModule } from '../../../atoms/dropdown/dropdown.module';

@NgModule({
    declarations: [InformationTable],
    imports: [CommonModule, MatIconModule, ButtonModule, CtaModule, InputModule, DropdownModule],
    providers: [],
    exports: [InformationTable]
})
export class InformationTableModule {}

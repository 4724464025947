import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConnectStorePageComponent } from './pages/connect-store/connect-store.component';

const connectStoreRoutes: Routes = [
  {
    path: '',
    component: ConnectStorePageComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(connectStoreRoutes)],
  exports: [RouterModule],
})
export class ConnectStoreRoutingModule {}

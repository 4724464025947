import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pargo-container',
  templateUrl: './container.html',
  styleUrls: ['./container.scss'],
})
export class Container {
  @Input() backgroundColor?: string;
}

import { GlobalErrorHandler } from './core/utils/error.handler';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Injector, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { createCustomElement } from '@angular/elements';
import { EffectsModule } from '@ngrx/effects';

import { MatIconModule } from '@angular/material/icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { WebConfigService } from './core/services/common/web-config.service';

import { CoreService } from './core/services/core.service';

import { CoreEffects } from './core/effects/core.effects';

import { AuthenticationModule } from './modules/authentication';
import { SharedModule } from './modules/shared';
import { NotificationsModule } from './modules/notifications';
import { WalkthroughModule } from './modules/walkthrough';
import { CreateModule } from './modules/create';
import { ConnectStoreModule } from './modules/connect-store';

import { Store, DevtoolsModule, RouterStoreModule } from './core/store';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    AuthenticationModule,
    NotificationsModule,
    SharedModule,
    WalkthroughModule,
    CreateModule,
    ConnectStoreModule,
    HammerModule,
    Store,
    RouterStoreModule,
    DevtoolsModule,
    EffectsModule.forRoot([CoreEffects]),
  ],
  exports: [],
  providers: [
    WebConfigService,
    CoreService,
    Title,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {}
  ngDoBootstrap() {
    const elements: any[] = [];

    for (const [component, name] of elements) {
      const el: any = createCustomElement(component, {
        injector: this.injector,
      });
      customElements.define(name, el);
    }
  }
}

<section class="add-order-form">
  <form
    [class]="submitted ? 'form submitted add-order-form__form' : 'form add-order-form__form'"
    validate
    [formGroup]="createOrderForm"
    (ngSubmit)="handleForm()"
  >
    <div class="add-order-form__header">
      <h2 class="add-order-form__heading">{{formTitle}}</h2>
    </div>
    <div class="add-order-form__errors" *ngIf="errors.length > 0">
      <h4 class="add-order-form__errors-heading">Please correct the following sections:</h4>
      <ul class="add-order-form__errors-list">
        <li
          class="summary__list-item"
          class="add-order-form__errors-item"
          *ngFor="let error of errors"
        >
          {{error.message}}
        </li>
      </ul>
    </div>

    <div class="add-order-form__body">
      <app-order-parcel-details
        class="add-order-form__parcelDetails"
        formControlName="parcelDetails"
      >
      </app-order-parcel-details>
      <app-order-receiver class="add-order-form__receiver" formControlName="receiver">
      </app-order-receiver>
      <app-order-warehouse
        formControlName="warehouse"
        [warehouses]="(warehouses$ | async)"
        (updateRegion)="handleUpdateRegion($event)"
        [selectedWarehouseReference]="order?.from?.reference"
      >
      </app-order-warehouse>
      <app-order-delivery-method
        formControlName="deliveryMethod"
        [deliveryMethod]="process"
        (updateRegion)="handleUpdateRegion($event)"
        [editing]="this.type === 'edit'"
      >
      </app-order-delivery-method>
    </div>
    <div class="add-order-form__footer">
      <!-- <div class="add-order-form__another" *ngIf="type !== 'edit'">
        <label class="another__label">
          <input class="another__input" type="checkbox" formControlName="another" name="another" />
          Create another order
        </label>
      </div> -->

      <pargo-button
        class="add-order-form__button add-order-form__button--save"
        secondary="true"
        type="submit"
        [loading]="(submitting$ | async) === true && submitType === 'draft'"
        [disabled]="(submitting$ | async) === true"
        label="Save draft"
        (click)="setSubmitType('draft')"
      >
      </pargo-button>

      <pargo-button
        class="add-order-form__button add-order-form__button--confirm"
        type="submit"
        [loading]="(submitting$ | async) === true && submitType === 'confirm'"
        [disabled]="(submitting$ | async) === true"
        label="Confirm and print"
        (click)="setSubmitType('confirm')"
      >
      </pargo-button>
    </div>
  </form>
  <app-delivery-form-summary class="add-order-form__summary" [formValues]="formValues" [amount]="amount">
  </app-delivery-form-summary>
</section>

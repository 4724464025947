import { Component } from '@angular/core';

const faq = [
  {
    heading: 'Credits',
    items: [
      {
        heading: 'How do I purchase credits',
        information: `
                <ol>
                    <li>Select Credits > Buy Credits from the menu.</li>
                    <li>Select your preferred package or enter your custom amount and click ‘Buy Now’.</li>
                    <li>Enter your card details and click ‘Pay’, your payment will process. Do not refresh your page.</li>
                    <li>On payment confirmation, you will notice the credits at the top of the page flash in green. This indicates that your account has been updated.</li>
                </ol>
            `,
      },
      {
        heading: 'What is the minimum limit on the custom credits package?',
        information:
          'The custom credits package is set at a minimum purchase value to 1000 credits.',
      },
      {
        heading: 'What is the relationship between credits and rands?',
        information: '1 credit is equal to R 1.',
      },
      {
        heading: 'Do I pay any other fees?',
        information: 'No. There are no additional fees to purchase credits',
      },
      {
        heading: 'My credits aren’t showing on my profile after successful purchase',
        information:
          'This should be immediate. However, if it does not show up. Please refresh your browser. The credits should update.',
      },
      {
        heading: 'Where can I see my credit purchase history',
        information: 'You can view credit related information in your credit dashboard.',
      },
      {
        heading: 'I do not want to purchase via card, is there another way?',
        information: `We suggest purchasing credits with a card as it is the quickest way. If you would like to purchase by EFT, you can email us on support@pargo.co.za and request the amount of credits you’d like to add to your profile. We will then invoice you and only once we’ve received payment, we will then update your myPargo credits. Please note that this could take up to 2 days to reflect on your profile, whereas our online process would be instant on payment confirmation. `,
      },
    ],
  },
];

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.html',
  styleUrls: ['./faq-page.scss'],
})
export class FaqPageComponent {
  constructor() {}

  faq: any = faq;
}

<section class="volumetric-weight-dialog">
  <form [formGroup]="volumetricWeightForm">
    <header class="volumetric-weight-dialog__header">
      <h3 class="volumetric-weight-dialog__heading">Calculate</h3>
      <mat-icon class="volumetric-weight-dialog__close" (click)="handleClose()"> close </mat-icon>
    </header>

    <div class="volumetric-weight-dialog__body">
      <div class="volumetric-weight-dialog__actual">
        <p class="volumetric-weight-dialog__message">Enter the actual parcel weight.</p>
        <pargo-input
          class="volumetric-weight-dialog__input volumetric-weight-dialog__input--actual"
          label="Actual weight (kg)"
        >
          <input class="input" type="number" formControlName="actual" required min="0" />
        </pargo-input>
      </div>

      <p class="volumetric-weight-dialog__message">
        Enter the parcel dimensions in centimeters to calculate the volumetric weight.
      </p>
      <div class="volumetric-weight-dialog__inputs">
        <div class="volumetric-weight-dialog__width">
          <pargo-input class="volumetric-weight-dialog__input" label="Width (cm)">
            <input class="input" type="number" formControlName="width" required min="0" />
          </pargo-input>
        </div>
        <div class="volumetric-weight-dialog__height">
          <pargo-input class="volumetric-weight-dialog__input" label="Height (cm)">
            <input class="input" type="number" formControlName="height" required min="0" />
          </pargo-input>
        </div>
        <div class="volumetric-weight-dialog__length">
          <pargo-input class="volumetric-weight-dialog__input" label="Length (cm)">
            <input class="input" type="number" formControlName="length" required min="0" />
          </pargo-input>
        </div>
      </div>

      <app-card-information
        class="volumetric-weight-dialog__information"
        *ngIf="result"
        [title]="result.title"
        [description]="result.description"
        [status]="result.status"
        [icon]="result.icon"
      >
      </app-card-information>
    </div>

    <footer class="volumetric-weight-dialog__footer">
      <pargo-button
        secondary="true"
        class="volumetric-weight-dialog__confirm-button"
        label="Clear"
        (click)="handleClear()"
      ></pargo-button>

      <pargo-button
        class="volumetric-weight-dialog__confirm-print-button"
        label="Set weight"
        [disabled]="isDisabled"
        (click)="handleConfirm()"
      ></pargo-button>
    </footer>
  </form>
</section>

export const PROVINCES_DROPDOWN_DATA = [
  {
    label: 'Eastern Cape',
    value: 'Eastern Cape',
  },
  {
    label: 'Free State',
    value: 'Free State',
  },
  {
    label: 'Gauteng',
    value: 'Gauteng',
  },
  {
    label: 'KwaZulu-Natal',
    value: 'KwaZulu-Natal',
  },
  {
    label: 'Limpopo',
    value: 'Limpopo',
  },
  {
    label: 'Mpumalanga',
    value: 'Mpumalanga',
  },
  {
    label: 'Northern Cape',
    value: 'Northern Cape',
  },
  {
    label: 'North West',
    value: 'North West',
  },
  {
    label: 'Western Cape',
    value: 'Western Cape',
  },
];

export const INDUSTRIES_DROPDOWN_DATA = [
  {
    label: 'Banking',
    value: 'Banking',
  },
  {
    label: 'Charity',
    value: 'Charity',
  },
  {
    label: 'Direct Selling',
    value: 'Direct Selling',
  },
  {
    label: 'eCommerce',
    value: 'eCommerce',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Electronics and Gadgets',
    value: 'Electronics and Gadgets',
  },
  {
    label: 'Events',
    value: 'Events',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },

  {
    label: 'Healthcare',
    value: 'Healthcare',
  },
  {
    label: 'Insurance',
    value: 'Insurance',
  },
  {
    label: 'Pharmaceuticals',
    value: 'Pharmaceuticals',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Telecommunications',
    value: 'Telecommunications',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

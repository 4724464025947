import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { INFORMATION_PILL } from '../../constants/icons';
import { CustomIcon } from '../../models/icon';
@Component({
  selector: 'app-information-pill',
  templateUrl: './information-pill.html',
  styleUrls: ['./information-pill.scss'],
})
export class InformationPillComponent {
  @Input() heading?: string;
  @Input() information?: string;
  @Input() text?: string;
  @Input() icon?: string;
  @Input() id?: string;
  @Input() open?: boolean;
  @Output() toggle: EventEmitter<any> = new EventEmitter();

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    INFORMATION_PILL.forEach(({ path, name }: CustomIcon): void => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }

  handleClick() {
    this.open = !this.open;
    this.toggle.emit(this.id || null);
  }
}

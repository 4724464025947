<section class="receiver-details" [formGroup]="receiverForm">
  <h4 class="receiver-details__heading heading heading__lined">
    <span>{{ heading ? heading : 'Recipient information'}}</span>
  </h4>
  <div class="receiver-details__information">
    <div class="receiver-details__name">
      <div class="receiver-details__firstname">
        <pargo-input class="parcel-information__input" label="First name">
          <input class="input" type="text" formControlName="firstName" />
        </pargo-input>
      </div>
      <div class="receiver-details__lastname">
        <pargo-input class="parcel-information__input" label="Last name">
          <input class="input" type="text" formControlName="lastName" />
        </pargo-input>
      </div>
    </div>
    <div class="receiver-details__contact">
      <div class="receiver-details__email">
        <pargo-input class="parcel-information__input" label="Email">
          <input class="input" type="email" formControlName="email" />
        </pargo-input>
      </div>
      <div class="receiver-details__phone">
        <pargo-input class="parcel-information__input" label="Phone">
          <input class="input" type="text" formControlName="phone" />
        </pargo-input>
      </div>
    </div>
  </div>
</section>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import * as walkthroughs from '../../constants/walkthrough-steps.data';

import {
  enableWalkthrough,
  disableWalkthrough,
  resetStepsPageNavigation,
} from '../../actions/walkthrough';

import { selectWalkthroughEnabled } from '../../selectors/walkthrough';
import { firstLogin } from 'src/app/core/selectors/core';
import { resetFirstLogin } from 'src/app/core/actions/core';

@Component({
  selector: 'app-walkthrough-toggle',
  templateUrl: './walkthrough-toggle.html',
  styleUrls: ['./walkthrough-toggle.scss'],
})
export class WalkthroughToggleComponent implements OnInit, OnDestroy {
  @Input() hasCredits: boolean;
  @Input() processes: string[];

  enabled$: Observable<boolean>;
  firstLogin$: Observable<boolean>;
  enabled: boolean;
  subscriptions: Subscription[] = [];

  pages: object[] = [];

  constructor(private store: Store<any>, private router: Router) {
    const routerSub = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd && this.enabled) {
        this.store.dispatch(resetStepsPageNavigation({ url: event.url }));
      }
    });

    this.subscriptions.push(routerSub);

    this.enabled$ = this.store.select(selectWalkthroughEnabled);
  }

  ngOnInit() {
    const enabledSub = this.enabled$.subscribe((enabled) => {
      this.enabled = enabled;
    });
    this.subscriptions.push(enabledSub);

    this.firstLogin$ = this.store.select(firstLogin);
    const firstLoginSub = this.firstLogin$.pipe(take(1)).subscribe((isFirstLogin: Boolean) => {
      if (isFirstLogin) {
        this.store.dispatch(resetFirstLogin());
        this.toggleWalkthrough();
      }
    });
    this.subscriptions.push(firstLoginSub);
  }

  clearedPaymentGuard(paymentGuard) {
    if (paymentGuard === undefined) {
      return true;
    }

    if (paymentGuard === true && this.hasCredits === true) {
      return true;
    }
  }

  clearedProcessGuard(processGuard) {
    if (processGuard === undefined) {
      return true;
    }

    if (processGuard && this.processes && this.processes.includes(processGuard)) {
      return true;
    }
  }

  setupWalkthrough() {
    Object.keys(walkthroughs).forEach((key) => {
      const { paymentGuard, processGuard, page, steps } = walkthroughs[key];

      if (this.clearedPaymentGuard(paymentGuard) && this.clearedProcessGuard(processGuard)) {
        const filteredSteps = steps.reduce((acc, step) => {
          if (
            this.clearedPaymentGuard(step.paymentGuard) &&
            this.clearedProcessGuard(step.processGuard)
          ) {
            acc = [...acc, step];
          }

          return acc;
        }, []);

        this.pages = [
          ...this.pages,
          {
            url: page,
            steps: filteredSteps,
          },
        ];
      }
    });
  }

  toggleWalkthrough() {
    if (this.enabled === false) {
      const url = this.router.url;

      this.setupWalkthrough();

      this.store.dispatch(enableWalkthrough({ url: url, pages: this.pages }));
    } else {
      this.store.dispatch(disableWalkthrough());
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}

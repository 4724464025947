import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-signature',
  templateUrl: './email-signature.html',
  styleUrls: ['./email-signature.scss'],
})
export class EmailSignature implements AfterViewInit {
  email: string;
  name: string;
  title: string;
  phone: string;
  mobile: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const { email, name, title, phone, mobile } = params;

      setTimeout(() => {
        this.email = email;
        this.name = name;
        this.title = title;
        this.phone = phone;
        this.mobile = mobile;
      }, 10);
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerModule } from '../../layout/container/container.module';

import { CustomsIntrosModule } from '../../molecules/customs-intro/customs-intro.module';
import { CustomsShipmentDetailsModule } from '../../molecules/customs-shipment-detail/customs-shipment-detail.module';
import { CustomFeeOverviewModule } from '../../molecules/customs-fee-overview/customs-fee-overview.module';
import { PaymentFormModule } from '../../molecules/international-payment-form/payment-form.module';
import { ConfirmationMessageModule } from '../../molecules/international-feedback/confirmation-message/confirmation-message.module';

import { CustomsPayment } from './components/customs-payment/customs-payment';

@NgModule({
    declarations: [CustomsPayment],
    imports: [
        CommonModule,
        MatIconModule,
        ContainerModule,
        CustomsIntrosModule,
        CustomsShipmentDetailsModule,
        CustomFeeOverviewModule,
        PaymentFormModule,
        ConfirmationMessageModule,
    ],
    providers: [],
    exports: [CustomsPayment]
})
export class CustomsPaymentModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ContainerModule } from '../../layout/container/container.module';
import { DashboardBoxModule } from '../../layout/dashboard-box/dashboard-box.module';

import { InformationTableModule } from '../../molecules/table/information-table/information-table.module';

import { InvoicesOverview } from './components/invoices-overview/invoices-overview';

@NgModule({
    declarations: [InvoicesOverview],
    imports: [
        CommonModule,
        MatIconModule,
        ContainerModule,
        DashboardBoxModule,
        InformationTableModule,
    ],
    providers: [],
    exports: [InvoicesOverview]
})
export class InvoicesOverviewModule {}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.html',
  styleUrls: ['./notification.scss'],
})
export class NotificationComponent {
  @Input() id: string;
  @Input() message: string;
  @Input() icon?: string;
  @Input() type: string;
  @Input() typeClass: string;
  @Input() removeFn: any;

  get className() {
    return `notification notification--${this.typeClass}`;
  }

  handleClick() {
    this.removeFn(this.id);
  }
}

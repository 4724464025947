import { Component, OnInit, forwardRef, Input } from '@angular/core';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormBuilder,
  Validator,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

import { FormValidationService } from 'src/app/modules/shared/services/form-validation.service';

export function oneOfControlRequired(...controls: AbstractControl[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    for (const aControl of controls) {
      if (!Validators.required(aControl)) {
        return null;
      }
    }
    return { oneOfRequired: true };
  };
}
@Component({
  selector: 'app-order-receiver',
  templateUrl: './order-receiver.html',
  styleUrls: ['./order-receiver.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderReceiverComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrderReceiverComponent),
      multi: true,
    },
  ],
})
export class OrderReceiverComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() heading?: string;

  receiverForm = this.formBuilder.group({
    firstName: [
      '',
      [
        Validators.required,
        Validators.maxLength(255),
        this.formValidationService.userNameCharacters,
        this.formValidationService.isWhiteSpace,
      ],
    ],
    lastName: [
      '',
      [
        Validators.required,
        Validators.maxLength(255),
        this.formValidationService.userNameCharacters,
        this.formValidationService.isWhiteSpace,
      ],
    ],
    email: [
      '',
      [oneOfControlRequired, this.formValidationService.email, Validators.maxLength(255)],
    ],
    phone: ['', [oneOfControlRequired, this.formValidationService.phoneNumber]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formValidationService: FormValidationService,
  ) {}

  ngOnInit() {
    this.receiverForm.setValidators([
      oneOfControlRequired(this.receiverForm.get('email'), this.receiverForm.get('phone')),
    ]);
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val && Object.keys(val).length) {
      this.receiverForm.patchValue({ ...val });
    }

    if (val === null) {
      this.receiverForm.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.receiverForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.receiverForm.disable();
    } else {
      this.receiverForm.enable();
    }
  }

  validate(): ValidationErrors | null {
    return this.receiverForm.valid
      ? null
      : {
          valid: false,
          message: `Receiver details section is invalid`,
        };
  }
}
